import React, { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import Button from "antd/lib/button";

type ComponentProps = {
    searchText: string | null;
    isSerchPanelEnabled?: boolean;
    currentFolderName?: string;
    onSearch?: (value: string) => void;
};

export const ItemsSearch: React.FC<ComponentProps> = React.memo(({
    searchText,
    isSerchPanelEnabled,
    currentFolderName,
    onSearch,
}) => {

    const [searchInputValue, setSearchInputValue] = useState("");

    useEffect(() => setSearchInputValue(searchText || ""), [searchText]);// Clear search input if params changed

    const searchButton = isSerchPanelEnabled && (<Button icon={<SearchOutlined />} type="primary">Поиск</Button>);

    const searchPanelElement = isSerchPanelEnabled ? (<Search
        className="lg:w-full xl:w-[350px]"
        placeholder={currentFolderName ? `Искать в: ${currentFolderName}` : "Поиск"}
        allowClear
        enterButton={searchButton}
        size="middle"
        defaultValue={searchText || ""}
        value={searchInputValue}
        onChange={(e) => setSearchInputValue(e?.target?.value || "")}
        onSearch={onSearch}
    />) : null;

    return searchPanelElement;
});
