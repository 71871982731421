import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined'
import Button from 'antd/lib/button'
import Checkbox from 'antd/lib/checkbox'
import message from 'antd/lib/message'
import Tabs, { TabsProps } from 'antd/lib/tabs'
import Link from 'antd/lib/typography/Link'
import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { IPublishedLink } from './PublishWizardForm'

export const PublishedLinkExamples: React.FC<{
    publishedLink: IPublishedLink
}> = React.memo(({
    publishedLink
}) => {
    //const { data: colibriEnv } = useGetColibriEnvironmentQuery()
    const publishedLinkUrl = `${window.location.origin}/publication/${publishedLink?.id}`

    const [encodeUrl, setEncodeUrl] = useState(false)

    const hasParameters = Object.keys(publishedLink.parameterStates || {}).length

    const queryData = Object.entries(publishedLink.parameterStates || {})
        .filter(([key, value]) => value)
        .flatMap(([key, value]) => prepareQueryStringParameter(key, publishedLink.parameters?.[key]));
    const query = `?${encodeUrl
        ? new URLSearchParams(queryData).toString()
        : queryData.map(([key, value]) => `${key}=${value}`).join('&')}`

    const linkExamples: { description: string; link: string }[] = [hasParameters && {
        description: 'С параметрами',
        link: `${publishedLinkUrl}${query}`
    }, {
        description: 'Без параметров',
        link: `${publishedLinkUrl}`,
    }].filter(Boolean) as any

    return <Tabs
        type='card'
        tabBarExtraContent={{ right: hasParameters && <Checkbox checked={encodeUrl} onChange={e => setEncodeUrl(e.target.checked)}>Кодировать ссылки</Checkbox> }}
        items={[{
            key: 'Link',
            label: 'Ссылка',
            children: <>
                {linkExamples.map(({ description, link }) =>
                    <PublicationLinkExample key={link} link={link} description={description} />
                )}
            </>
        }, publishedLink?.outputType === 'Embedding' && {
            key: 'Embedding',
            label: 'Код для встраивания iframe',
            children: <>
                {linkExamples.map(({ description, link }) =>
                    <PublicationLinkExample key={link} link={link} description={description} iframe />
                )}
            </>
        }].filter(Boolean) as TabsProps['items']}>
    </Tabs>
})

const prepareQueryStringParameter: (key: string, value: any) => [string, string][] = (key: string, value: any) => {
    if (value instanceof Array)
        return value.map(v => [key, v])
    return [[key, value || '']]    
}

const PublicationLinkExample: React.FC<{
    description: string;
    link: string;
    iframe?: boolean;
}> = React.memo(({
    description,
    link,
    iframe
}) => {
    const iframeExample = `<iframe src="${link}" width="800" height="600" frameborder="0"></iframe>`
    const textToCopy = iframe ? iframeExample : link

    return <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {description && <>{description}: </>}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ flex: 1, width: '100%' }}>
                {!iframe
                    ? <Link key={link} href={link} target='_blank' style={{ display: 'block' }}>{link}</Link>
                    : <SyntaxHighlighter
                        key={link}
                        language="html"
                        wrapLines
                        wrapLongLines
                        customStyle={{ margin: 0, wordBreak: 'break-all' }}>
                        {iframeExample}
                    </SyntaxHighlighter>}
            </div>
            <CopyToClipboard
                text={textToCopy}
                onCopy={() => message.info(`Скопировано в буфер обмена: ${textToCopy.substring(0, 20)}...`)}
            >
                <Button type='text' icon={<CopyOutlined />} title='Скопировать' />
            </CopyToClipboard>
        </div>
    </div>
})