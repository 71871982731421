import React from "react";
import { Background } from "components/Background";
import colibriLogo from "../images/logo.svg";

export const LoadingScreen: React.FC = React.memo(() => {

    return (<Background>
        <div className="flex items-center justify-center h-full">
            <div className="text-center">
                <img alt="logo" width={220} src={colibriLogo} />
            </div>
        </div>
    </Background>)
});