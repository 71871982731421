import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSearchParams, createSearchParams, useNavigate } from "react-router-dom";
import { useAddFolderMutation, useDeleteFolderMutation, useDeleteTemplateMutation, useExportTemplateMutation, useGetFoldersQuery, useGetTemplatesQuery, useUpdateFolderMutation, useUpdateTemplateMutation } from "../app/store/colibri/reporting.api";
import { ItemList } from "../components/controls/ItemList";
import { ItemListHeader } from "../components/controls/ItemListHeader";
import { ICatalogedTreeItem, IFolder } from "../models/treeModels";
import { useReportingCatalogTree } from "../app/hooks/useReportingCatalogTree";
import { findItemsInTreeByName, findNodeById } from "../helpers/treeHelpers";
import { DeleteOutlined, EyeOutlined, FileAddOutlined, FileZipOutlined, FolderTwoTone, GlobalOutlined, HistoryOutlined, SafetyOutlined, SnippetsTwoTone, ToolOutlined } from "@ant-design/icons";
import { useActions } from "../app/hooks/hooks";
import { useOpenColibriTab } from "app/hooks/useOpenColibriTab";
import { useGetColibriEnvironmentQuery } from "../app/store/colibri/environment.api";
import { IListAction } from "../models/appModels";
import { ReportingTemplatesVersionsDrawerComponent } from "../components/ReportingTemplatesVersionsDrawerComponent";
import { ITemplate, ITemplateVersion } from "../models/reportingModels";
import { showError } from "../helpers/messageHelpers";
import { NotFoundContent } from "components/NotFoundContent";
import { useFolderIdFromPath } from "app/hooks/useFolderIdFromPath";
import Tooltip from "antd/lib/tooltip";
import Badge from "antd/lib/badge";
import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Input from "antd/lib/input/Input";
import { FormInstance } from "antd/lib/form/Form";

export const ReportingPage: React.FC = React.memo(() => {
    const tree = useReportingCatalogTree();
    const folderIdFromPath = useFolderIdFromPath();

    const currentFolder = useMemo(() => {
        if (!folderIdFromPath) {
            return tree;
        }
        return findNodeById(tree, folderIdFromPath);
    }, [tree, folderIdFromPath]);

    const folderId = currentFolder?.isRoot ? null : currentFolder?.id || null;

    const navigate = useNavigate();

    //--- API queries  
    const { isFetching: isCurrentPageFetching } = useLoadCurrentPageContent(folderId);
    const [addFolder, { isLoading: isFolderAdding }] = useAddFolderMutation();
    const [updateTemplate, { isLoading: isTemplateUpdating }] = useUpdateTemplateMutation();
    const [updateFolder, { isLoading: isFolderUpdating }] = useUpdateFolderMutation();
    const [deleteFolder, { isLoading: isFolderDeleting }] = useDeleteFolderMutation();
    const [deleteTemplate, { isLoading: isTemplateDeleting }] = useDeleteTemplateMutation();
    const [exportTemplate, { isLoading: isTemplateExporting }] = useExportTemplateMutation();

    const { data: colibriEnv } = useGetColibriEnvironmentQuery();
    //---
    const isLoading = isCurrentPageFetching || isTemplateUpdating || isFolderUpdating ||
        isFolderAdding || isFolderDeleting || isTemplateDeleting || isTemplateExporting;

    const [currentItems, setCurrentItems] = useState<ICatalogedTreeItem[]>(() => currentFolder?.items || []);

    //--- Search
    const [searchParams, setSearchParams] = useSearchParams();
    const searchText = searchParams.get("s");
    //---

    //--- Template versions
    const [isVersionsOpen, setVersionsOpen] = useState<boolean>(false);
    const [selectedTemplate, setSelectedTemplate] = useState<ICatalogedTreeItem | null>(null);
    //---

    //--- New folder
    const [isAddFolderModalOpen, setIsAddFolderModalOpen] = useState(false)
    const addFolderFormRef = useRef<FormInstance>()
    //---

    const openColibriTab = useOpenColibriTab();

    useEffect(() => {
        if (searchText && currentFolder) {
            setCurrentItems(findItemsInTreeByName(currentFolder, searchText));
        } else {
            setCurrentItems(currentFolder?.items || []);
        }
    }, [currentFolder, searchText]);

    const onSearch = (value: string): void => {
        if (value) {
            setSearchParams(createSearchParams({ s: value }));
        } else {
            setSearchParams();
        }
    }

    const openItemPreview = (item: ICatalogedTreeItem): void => {
        if (item.sourceType === "reporting.template") {
            openTemplateTab("preview", item.id);
        }
    };

    const openItemEdit = (item: ICatalogedTreeItem): void => {
        if (item.sourceType === "reporting.template") {
            openTemplateTab("editor", item.id);
        }
    };

    const onItemClicked = (item: ICatalogedTreeItem): void => {
        if (item?.isCatalog) {
            navigate(item.id, {
                replace: true,
            });
        } else if (item.sourceType === "reporting.template") {
            openItemPreview(item);
        }
    };

    const openTemplateTab = (mode: "preview" | "editor", templateId: string, version?: number): void => {
        if (colibriEnv?.colibriHost) {
            const modePath = mode === "editor" ? "/designer/reportdesigner" : "/report";
            const url = new URL(colibriEnv.colibriHost + modePath);
            url.searchParams.append("templateId", templateId);
            if (version) {
                url.searchParams.append("version", version.toString());
            }
            window.open(url, "_blank", 'noopener');
        } else {
            showError();
            console.error("Адрес сервера приложения Колибри не определен!");
        }
    };
    //--- CRUD methods
    const onItemChanged = (item: ICatalogedTreeItem, changes: Partial<ICatalogedTreeItem>): void => {
        const params = { id: item.id, ...changes };
        if (item.sourceType === "reporting.template") {
            updateTemplate(params);
        } else if (item.isCatalog) {
            updateFolder(params);
        }
    };

    const onFolderAdd = (): void => {
        setIsAddFolderModalOpen(true)                
    };

    const onTemplateAdd = (): void => {
        openColibriTab(`/designer/reportdesigner${folderId ? `?folderId=${folderId}` : ""}`)
    };

    const onItemDelete = (item: ICatalogedTreeItem): void => {
        if (item.isCatalog) {
            deleteFolder(item.id);
        } else if (item.sourceType === "reporting.template") {
            deleteTemplate(item.id);
        }
    }
    // ---

    const onItemRenamed = (newName: string, item: ICatalogedTreeItem) => {
        if (item.name !== newName) {
            onItemChanged(item, {
                name: newName,
            });
        }
    };

    const showDeleteConfirm = (item: ICatalogedTreeItem) => {
        Modal.confirm({
            title: "Необходимо подтверждение",
            icon: <DeleteOutlined />,
            content: getDeleteConfirmMessage(item),
            okText: "Подтвердить",
            onOk: () => onItemDelete(item),
            cancelText: 'Отмена',
        });
    };

    //--- Template versions
    const showTemplateVersions = (item: ICatalogedTreeItem) => {
        setVersionsOpen(true);
        setSelectedTemplate(item);
    };

    const closeTemplateVersions = () => {
        setVersionsOpen(false);
        setSelectedTemplate(null);
    };

    const onTemplateVersionClick = (version: ITemplateVersion) => {
        if (version.templateId && version.version) {
            openTemplateTab("editor", version.templateId, version.version);
        }
    };

    const { showPublisher, showPermissionsWindow, showImportResourceWindow } = useActions();

    const listActions: IListAction[] = [{
        key: "open",
        tooltipText: "Предпросмотр",
        icon: <EyeOutlined />,
        onClick: openItemPreview,
        specificSourceType: ["reporting.template"],
    }, {
        key: "edit",
        tooltipText: "Открыть в конструкторе",
        icon: <ToolOutlined />,
        onClick: openItemEdit,
        specificSourceType: ["reporting.template"],
    }, {
        key: "publish",
        tooltipText: "Настройка публикации",
        icon: <GlobalOutlined />,
        onClick: (template) => showPublisher({ objectId: template.id, objectType: 'reporting.template' }),
        specificSourceType: ["reporting.template"],
    }, {
        key: "history",
        tooltipText: "История версий",
        icon: <HistoryOutlined />,
        onClick: showTemplateVersions,
        specificSourceType: ["reporting.template"],
    }, {
        key: "permissions",
        tooltipText: "Настройка разрешений",
        icon: <SafetyOutlined />,
        accessRoles: ["security-admin"],
        onClick: (item) => showPermissionsWindow(item),
        specificSourceType: ["reporting.template", "reporting.folder"],
    }, {
        key: "export",
        tooltipText: "Выгрузить в файл",
        icon: <FileZipOutlined />,
        onClick: (item) => exportTemplate({ id: item.id, name: item.name }),
        specificSourceType: ["reporting.template"],
    }, {
        key: "delete",
        tooltipText: "Удалить",
        icon: <DeleteOutlined />,
        onClick: showDeleteConfirm,
        danger: true,
        specificSourceType: ["reporting.template", "reporting.folder"],
    }];

    const isPublished = (item: ICatalogedTreeItem): boolean => {
        if (item.sourceType !== "reporting.template") {
            return false;
        }
        const sourceItem = item.sourceItem as ITemplate;
        return !!sourceItem.publishedLinkId;
    };

    return (
        <>
            <div className="bg-white h-full w-full shadow p-8 rounded-lg">
                {currentFolder || isLoading ? <>
                    <ItemListHeader
                        currentFolder={currentFolder}
                        isSerchPanelEnabled={true}
                        onSearch={onSearch}
                        buttons={currentFolder?.isRoot 
                            ? [{
                                key: "add-folder",
                                buttonText: "Создать папку",
                                clickEvent: () => onFolderAdd(),
                                disabled: isLoading,
                                accessRoles: ['report-writer']
                            }] 
                            : [{
                                key: "add-template",
                                buttonText: "Создать шаблон",
                                clickEvent: () => onTemplateAdd(),
                                disabled: isLoading,
                                accessRoles: ['report-writer'],
                                dropdownButtons: [{
                                    key: "add-folder",
                                    buttonText: "Создать папку",
                                    clickEvent: () => onFolderAdd(),
                                }, {
                                    key: "load-file",
                                    buttonText: "Загрузить из файла",
                                    icon: <FileAddOutlined />,
                                    clickEvent: () => showImportResourceWindow({
                                        resourceType: "reporting.template",
                                        folderId: folderId,
                                    }),
                                }],
                            },
                        ]}
                    />
                    <ItemList
                        isLoading={isLoading}
                        listItems={currentItems || []}
                        itemClickEvent={onItemClicked}
                        itemRenamedEvent={onItemRenamed}
                        itemActions={listActions}
                        isSearchMode={!!searchText}
                        iconRender={(item: ICatalogedTreeItem) => {
                            if (item.sourceType === "reporting.folder") {
                                return <FolderTwoTone className="text-2xl" />
                            } else if (item.sourceType === "reporting.template" && isPublished(item)) {
                                return (<Tooltip title="Отчёт опубликован" placement="right">
                                    <Badge offset={[-3, 3]} count={<div className="w-[10px] h-[10px] bg-green-500 rounded-full shadow-[0_0_0_2px_rgba(255,255,255,1)]" />}>
                                        <SnippetsTwoTone className="text-2xl cursor-pointer" onClick={() => showPublisher({ objectId: item.id, objectType: 'reporting.template' })}/>
                                    </Badge>
                                </Tooltip>)
                            } else {
                                return <SnippetsTwoTone className="text-2xl" />
                            }
                        }}
                    />
                </> : <NotFoundContent
                    title="Папка не найдена"
                    text={`Пожалуйста, вернитесь в ${tree.name} или обратитесь к системному администратору, если считаете, что папка должна быть здесь...`}
                    buttonText={tree.name}
                    onButtonClick={() => navigate(`/${tree.id}`)}
                />}
            </div>
            {selectedTemplate && <ReportingTemplatesVersionsDrawerComponent
                isOpen={isVersionsOpen}
                item={selectedTemplate}
                onEditClick={onTemplateVersionClick}
                onClose={closeTemplateVersions}
            />}
            <Modal
                title="Новый каталог"
                width={350}
                open={isAddFolderModalOpen}
                onOk={() => addFolderFormRef.current?.submit()}
                confirmLoading={isFolderAdding}
                onCancel={() => setIsAddFolderModalOpen(false)}
                destroyOnClose
                afterClose={() => setIsAddFolderModalOpen(false)}
            >
                <Form<IFolder>                    
                    ref={addFolderFormRef as any}
                    name="basic"
                    layout="vertical"                       
                    onFinish={async folder => {
                        const result = await addFolder({
                            ...folder,
                            pid: folderId,
                        })                         
                        if (!Object.keys(result).includes("error"))
                            setIsAddFolderModalOpen(false)                        
                    }}
                >
                    <Form.Item
                        label="Наименование"
                        name="name"                                 
                        rules={[{ required: true, message: 'Пожалуйста, укажите Наименование' }]}
                    >
                        <Input autoFocus ref={input=> input?.focus() } />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
});

const getDeleteConfirmMessage = (item: ICatalogedTreeItem): React.ReactNode => {
    if (item.isCatalog) {
        return <span>Вы уверены, что хотите удалить папку <b>{item.name}</b>? Все шаблоны данной папки будут перенесены в корневую папку.</span>;
    } else {
        return <span>Вы уверены, что хотите удалить <b>{item.name}</b>?</span>;
    }
};

const useLoadCurrentPageContent = (folderId: string | null | undefined) => {
    const { fulfilledTimeStamp: allFoldersLoadedTimestamp, isFetching: isAllFoldersFetching } = useGetFoldersQuery(undefined, { refetchOnFocus: !folderId })
    const { fulfilledTimeStamp: allTemplatesLoadedTimestamp, isFetching: isAllTemplatesFetching } = useGetTemplatesQuery(undefined, { refetchOnFocus: !folderId })
    const now = Date.now()
    const threshold = 1000 // должна пройти минимум 1 секунда

    // пропускаются запросы при ожидании завершения загрузки всей структуры папок и шаблонов,
    // а также если полная загрузка выполнялась только что
    const { isFetching: isFoldersFetching } = useGetFoldersQuery(folderId, {
        skip: isAllFoldersFetching || !allFoldersLoadedTimestamp || (now - allFoldersLoadedTimestamp < threshold),
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        refetchOnFocus: true,
    });
    const { isFetching: isTemplatesFetching } = useGetTemplatesQuery(folderId, {
        skip: isAllTemplatesFetching || !allTemplatesLoadedTimestamp || (now - allTemplatesLoadedTimestamp < threshold),
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        refetchOnFocus: true,
    });

    const isFetching = isAllFoldersFetching || isAllTemplatesFetching || isFoldersFetching || isTemplatesFetching

    return { isFetching }
}