import { useMemo } from "react";
import { RootState } from "app/store";
import { selectCurrentPage } from "app/store/pages/interfaceSlice";
import { useLocation, useMatch } from "react-router-dom";
import { useAppSelector } from "./hooks";

export const useFolderIdFromPath = (): string | null => {
    const { pathname } = useLocation();
    const currentPage = useAppSelector((state: RootState) => selectCurrentPage(state, pathname));

    const match = useMatch(currentPage!.path);
    const folderId = useMemo(() => match?.params['*'] || null, [match]);
    return folderId;
};