import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react'
import { menuItemsList } from 'app/menuItemsList';
import { pagesList } from 'app/pagesList';
import { baseQueryWithReauth } from 'app/store/baseQueryWithReauth';
import { interfaceSlice } from 'app/store/pages/interfaceSlice';
import { IUser, UserTeam } from 'models/appModels'
import { isUserHasAccessToSecuredElement } from './security/accessChecker';

export const userApi = createApi({
    baseQuery: baseQueryWithReauth({
        baseUrl: '/api/v1',
    }),
    reducerPath: 'Api',
    tagTypes: ['User'],
    endpoints: (build) => ({
        getUserInfo: build.query<IUser, void>({
            query: () => `user`,            
            providesTags: ['User'],
            async onQueryStarted(_, { queryFulfilled, dispatch, getState }) {
                const cachedUser = selectCurrentUserInfo(getState());
                let result: { meta: any; data: IUser; } | null = null;
                try {
                    result = await queryFulfilled;
                } catch (e: any) {}
                if (result && result.meta?.response?.status === 200) {
                    dispatch(userApi.util.updateQueryData('getUserInfo', undefined, (draft) => {
                        const currentUser = draft || null;
                        if (!currentUser || (cachedUser && currentUser && currentUser.userName !== cachedUser.userName)) {
                            return window.location.reload();
                        } else {
                            const roleNames = currentUser.roles.map((role) => {
                                const roleNameToCompare = role.toLocaleLowerCase().trim();
                                if (roleNameToCompare === "securityadministrator") {
                                    return "Администратор безопасности (SECURITYADMINISTRATOR)";
                                } else if (roleNameToCompare === "dataadministrator") {
                                    return "Администратор данных (DATAADMINISTRATOR)";
                                } else if (roleNameToCompare === "administrator") {
                                    return "Администратор";
                                } else {
                                    return role;
                                }
                            });
                            return {
                                ...currentUser,
                                roleNames,
                            };
                        }
                    }))
                }

                const userInfo = result?.data
                if (userInfo) {
                    const allowedPages = pagesList.filter((page) => isUserHasAccessToSecuredElement(page, userInfo.roles));
                    const allowedMenuItems = menuItemsList.filter((item) => isUserHasAccessToSecuredElement(item, userInfo.roles));
                    dispatch(interfaceSlice.actions.setPages(allowedPages))                    
                    dispatch(interfaceSlice.actions.setMenuItems(allowedMenuItems))
                    dispatch(userApi.util.updateQueryData('getIsAuthenticated', undefined, () => true))
                }
                else {
                    dispatch(interfaceSlice.actions.setPages([]))
                    dispatch(interfaceSlice.actions.setMenuItems([]))
                    dispatch(userApi.util.updateQueryData('getIsAuthenticated', undefined, () => false))
                }
            },
        }),
        getIsAuthenticated: build.query<boolean, void>({
            query: () => `user/authenticated`,
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled                                        
                } catch (e: any) {}
                dispatch(userApi.util.invalidateTags(['User']))
            }
        }),
        getUserTeams: build.query<UserTeam[], void>({
            query: () => `user/teams`
        })
    }),
})

export const { useGetUserInfoQuery, useGetIsAuthenticatedQuery, useGetUserTeamsQuery } = userApi

const selectUserInfoResult = userApi.endpoints.getUserInfo.select(undefined);
export const selectCurrentUserInfo = createSelector(
    selectUserInfoResult,
    result => result?.data ?? null,
);