import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { IColibriEnvironment } from "../../../models/appModels";
import { baseQueryWithReauth } from "../baseQueryWithReauth";

export const environmentApi = createApi({
  reducerPath: 'colibri/api/environment',
  baseQuery: baseQueryWithReauth({
    baseUrl: '/api/v1',
  }),
  endpoints: (build) => ({
    getColibriEnvironment: build.query<IColibriEnvironment, void>({
      query: () => 'environment',
    }),
  }),
});

export const {
  useGetColibriEnvironmentQuery,
} = environmentApi;