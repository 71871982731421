export interface IResourceImportResult {
    errcode: string;
    error: string;
    success: boolean;
}

export interface IDashboardImportResult extends IResourceImportResult {
    dashboard: ImportConflictResource,
    widgets: ImportConflictResource[],
}
export function isDashboardImportResult(obj: any): obj is IDashboardImportResult {
    return obj && obj.hasOwnProperty("dashboard");
}

interface ImportConflictResource {
    allowed: boolean;
    coid: string;
    coname: string;
    conflict: string;
    id: string;
    name: string;
    saved: boolean;
    type?: string;
}