import React from 'react'
import { useGetCsrfTokenQuery } from './antiforgery.api'

export const AntiforgeryForm: React.FC<React.PropsWithChildren<React.FormHTMLAttributes<HTMLFormElement>>> = React.memo(({ children, ...formProps }) => {
    const { data: tokenInfo, isFetching: isFetchingToken } = useGetCsrfTokenQuery()
    
    return <form {...formProps}>
        <fieldset disabled={isFetchingToken}>
            <input name="__RequestVerificationToken" type="hidden" value={tokenInfo?.token}></input>
            {children}
        </fieldset>
    </form>
})