import Form from 'antd/lib/form';
import { FormInstance } from 'antd/lib/form/Form';
import React, { memo, useEffect, useState } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { StepDefinition, usePublishWizard } from './PublishWizardProvider';

export const PublishWizardForm = memo(() => {
    const [formEl, setFormEl] = useState<FormInstance | null>(null)

    const wizard = usePublishWizard()

    const onSubmit: SubmitHandler<IPublishedLink> = (data) => {
        wizard.goNext?.();
    }

    const onError = (errors: any) => {
        console.warn(errors)
    }

    useEffect(() => {
        wizard.setFormEl(formEl)
    }, [formEl, wizard])

    return <Form
        ref={setFormEl}
        layout="vertical"
        size='large'
        onFinish={wizard.form.handleSubmit(onSubmit, onError)}
        style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        {wizard.stepDefinitions.map(d => <React.Fragment key={d.key}>
            {wizard.activeStep === d.key && d.element}
        </React.Fragment>)}
    </Form >
})

export type PublishWizardFormInstance = {
    stepDefinitions: StepDefinition[];
    allowBack: boolean;
    goBack: () => void;
    goNext?: () => void;
    form: UseFormReturn<IPublishedLink, any>;
    steps: string[];
    activeStep: string;
    setFormEl: (formEl: FormInstance | null) => void;
    formEl: FormInstance | null;
    controlsTarget: HTMLElement | null;
}

export interface IPublishedLink {
    id: string;
    objectType: 'reporting.template' | 'analysis.dashboard';
    objectId: string;
    accessType: keyof (typeof PublishedLinkAccessTypes);
    allowAnonymous: boolean | undefined;
    anonymousRole: string | null;
    parameters: Record<string, any>;
    parameterStates: Record<string, boolean>;
    outputType: keyof (typeof PublishedLinkOutputTypes);
    fileType: keyof (typeof ReportExportFileTypes) | null,
    snapshotId: null,
    scheduleOptions: IScheduleOptions;
}

export interface IScheduleOptions {
    type: 'Recurring';
    // Только для Повторяющихся задач        
    mode: keyof typeof RepeatingModes;
    year: number;
    month: number;
    day: number;
    hour: number;
    min: number;
    // "Повторять каждые N дней" для ежедневного триггера.        
    repeatDays?: number | null;
    // "Повторять каждые N минут" для ежедневного триггера.        
    repeatMinutes?: number | null;
    // Список месяцев для ежемесячного триггера. Январь = 1, Декабрь = 12.        
    months?: number[];
    user?: string;
    created?: string;
}

export const RepeatingModes = {
    Hourly: 'Ежечасно',
    Daily: 'Каждые N дней',
    Monthly: 'В определенные месяцы'
}

export const PublishedLinkAccessTypes = {
    NoCache: 'Онлайн',
    Scheduled: 'По расписанию',
    //Cached: 'Без обновления'
}

export const PublishedLinkOutputTypes = {
    Link: 'Прямая ссылка',
    Embedding: 'Встраивание'
}

export const ReportExportFileTypes = {
    Pdf: 'Pdf',
    Xlsx: 'Xlsx',
    Docx: 'Docx',
    Csv: 'Csv',
    Ods: 'Ods',
    Odt: 'Odt'
}