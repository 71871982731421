import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { RcFile } from "antd/lib/upload/interface";
import { useImportTemplateMutation } from "app/store/colibri/reporting.api";
import { showError } from "helpers/messageHelpers";


export const useImportTemplate = () => {
    const [importTemplate, { isLoading }] = useImportTemplateMutation();

    const func = async (file: RcFile, folderId: string | null): Promise<boolean> => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("force", "true");
        formData.append("folderId", folderId || "");

        const result = await importTemplate(formData);
        if (result && Object.hasOwn(result, "error")) { 
            const typedImportError = (result as any).error as FetchBaseQueryError;
            const msg = typeof typedImportError.data === "string" ? typedImportError.data : undefined;
            const isServerErrorStatus = typedImportError.status === 400 || ("originalStatus" in typedImportError && typedImportError.originalStatus === 400);
            showError(isServerErrorStatus ? msg : undefined);
            return false;
        } else {
            return true;
        }
    }
    return {
        isLoading,
        importTemplate: func
    };
};