import Layout from 'antd/lib/layout'
import Modal from 'antd/lib/modal'
import Steps from 'antd/lib/steps'
import React, { useEffect, useMemo, useState } from 'react'
import { useActions } from '../../app/hooks/hooks'
import { PublicationPreview } from './PublicationPreview'
import { IPublishedLink, PublishWizardForm } from './PublishWizardForm'
import { StepDefinition } from './PublishWizardProvider'
import { usePublishingObject } from './usePublishingObject'
import Button from 'antd/lib/button'
import Space from 'antd/lib/space'
import { ReportTemplatePublishWizardProvider } from './ReportTemplatePublishWizardProvider'
import { DashboardPublishWizardProvider } from './DashboardPublishWizardProvider'
import Alert from 'antd/lib/alert'
import { useCheckCanWriteResourceQuery } from 'app/store/colibri/permissions.api'

export const PublishControl: React.FC = React.memo(() => {
    const publishingObject = usePublishingObject()
    const [step, setStep] = useState<string | null>(null)
    const [allSteps, setAllSteps] = useState<StepDefinition[]>([])
    const stepIndex = allSteps.map(s => s.key).indexOf(step!)
    const { hidePublisher } = useActions()

    const [showPreview, setShowPreview] = useState(!!publishingObject?.data.publishedLinkId)
    const [wizardPublishedLink, setWizardPublishedLink] = useState<IPublishedLink>()
    useEffect(() => {
        setShowPreview(!!publishingObject?.data.publishedLinkId)
        setWizardPublishedLink(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publishingObject?.objectId])

    const [controlsTarget, setControlsTarget] = useState<HTMLDivElement | null>(null)

    const publishWizardContent = useMemo(() => !showPreview && <Layout style={{ background: 'transparent' }}>
        <Layout.Sider style={{ background: 'transparent', height: 'auto' }}>
            <Steps
                current={stepIndex}
                direction='vertical'
                style={{ padding: 10 }}>
                {allSteps.map(item => (
                    <Steps.Step key={item.key} title={item.displayName} />
                ))}
            </Steps>
        </Layout.Sider>
        <Layout.Content style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
            <PublishWizardForm />
        </Layout.Content>
    </Layout>, [showPreview, allSteps, stepIndex])

    const PublishWizardProvider = {
        'reporting.template': ReportTemplatePublishWizardProvider,
        'analysis.dashboard': DashboardPublishWizardProvider
    }[publishingObject?.objectType!]

    const { data: isEditable, isFetching: isCanWriteFetching } = useCheckCanWriteResourceQuery({ resourceType: publishingObject?.objectType!, resourceId: publishingObject?.objectId! }, { skip: !publishingObject?.objectId, refetchOnMountOrArgChange: true })

    return <Modal
        open={!!publishingObject}
        width={900}
        title={`Публикация ${publishingObject?.objectType === "analysis.dashboard" ? "дэшборда" : "отчёта"}`}
        footer={[
            <Space key='controls'>
                <div style={{ display: 'inline-block' }} ref={setControlsTarget}></div>
                {(showPreview || stepIndex < allSteps.length - 1) && <Button onClick={hidePublisher} type='default'>Отмена</Button>}
            </Space>
        ]}
        closable
        destroyOnClose
        onCancel={hidePublisher}
        bodyStyle={{ minHeight: 400, display: 'flex', flexDirection: 'column' }}>
        {showPreview && <PublicationPreview
            controlsTarget={controlsTarget}
            onEdit={publishedLink => {
                setShowPreview(false)
                setWizardPublishedLink(publishedLink)
            }} />}
        {!showPreview && PublishWizardProvider && publishingObject && !isCanWriteFetching &&
            <>{isEditable
                && <PublishWizardProvider
                    publishedLink={wizardPublishedLink || {
                        objectId: publishingObject.objectId,
                        objectType: publishingObject.objectType
                    }}
                    onComplete={hidePublisher}
                    onStepChange={setStep}
                    onStepsChange={setAllSteps}
                    controlsTarget={controlsTarget}>
                    {publishWizardContent}
                </PublishWizardProvider>
                || <Alert type='warning' message='Объект не опубликован' description='Для создания публикации необходимо право на изменение публикуемого объекта.'/>
            }</>}
    </Modal>
})