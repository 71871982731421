export interface IDataSourceSchema {
    name: string;
    title: string;
    isDataSourceSchema: boolean;
}
export function isDataSourceSchema(obj: any): obj is IDataSourceSchema {
    return obj && obj.isDataSourceSchema === true;
}

export interface IDataSourceTable {
    id: string;
    name: string;
    title: string;
    schema: string;
    columns: IDataSourceTableColumn[];
    isDataSourceTable: boolean;
}

export function isDataSourceTable(obj: any): obj is IDataSourceTable {
    return obj && obj.isDataSourceTable === true;
}

export interface IDataSourceTableColumn {
    title: string;
    name: string;
    enum: boolean;
    formula: string;
    type: string;
}