import { ITag } from "./tagModels";

export interface IWidget {
  id: string;
  name: string;
  tag: ITag[];
  type: string;
  editor: string;
  modified: string;
  read: boolean;
  write: boolean;
  isWidget: boolean;
};
export function isWidget(obj: any): obj is IWidget {
  return obj && obj.isWidget === true;
}

export interface IDashboard {
  id: string;
  name: string;
  tag: ITag[];
  editor: string;
  modified: string;
  read: boolean;
  write: boolean;
  isDashboard: boolean;
  publishedLinkId: string | null;
}

export function isDashboard(obj: any): obj is IDashboard {
  return obj && obj.isDashboard === true;
}