import { ICatalogedTreeItem } from '../../models/treeModels';
import { useMemo, useEffect } from 'react';
import { useAppSelector } from './hooks';
import { buildTree } from '../../helpers/treeHelpers';
import { selectAllWorkflows, useGetWorkflowsQuery } from '../store/colibri/workflows.api';
import { showError } from 'helpers/messageHelpers';

export const useWorkflowsCatalogTree = (): ICatalogedTreeItem => {
    const { isError } = useGetWorkflowsQuery(undefined, { refetchOnFocus: true });

    useEffect(() => {
        if (isError) {
            showError("Не удалось обновить список процессов", 5);
        }
    }, [isError]);

    const workflows = useAppSelector(selectAllWorkflows);

    const result = useMemo<ICatalogedTreeItem>(() => buildTree("workflows", "Процессы", workflows), [workflows]);
    return result;
};