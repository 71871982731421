import React, { useEffect, useState } from "react";
import { useSearchParams, createSearchParams } from "react-router-dom";
import { ItemList } from "../components/controls/ItemList";
import { ItemListHeader } from "../components/controls/ItemListHeader";
import { ICatalogedTreeItem } from "../models/treeModels";
import { findItemsInTreeByName } from "../helpers/treeHelpers";
import { DeleteOutlined, FileAddOutlined, FileZipOutlined, PlusOutlined, SafetyOutlined, ToolOutlined, QuestionCircleTwoTone, PieChartTwoTone, DatabaseTwoTone, BarChartOutlined, AlignLeftOutlined, StockOutlined, DotChartOutlined, TableOutlined, EnvironmentOutlined, FunctionOutlined, FundProjectionScreenOutlined } from "@ant-design/icons";
import { useWidgetsCatalogTree } from "../app/hooks/useWidgetsCatalogTree";
import { useDeleteWidgetMutation, useExportWidgetMutation, useGetWidgetsQuery, useUpdateWidgetMutation } from "../app/store/colibri/analysis.api";
import { useOpenColibriTab } from "app/hooks/useOpenColibriTab";
import { IListAction } from "models/appModels";
import { useActions } from "app/hooks/hooks";
import Modal from "antd/lib/modal";
import { IWidget } from "models/analysisModels";
import Tooltip from "antd/lib/tooltip";

interface IWidgetMeta {
    typeName: string;
    icon: React.ReactNode;
}

export const WidgetsPage: React.FC = React.memo(() => {
    const tree = useWidgetsCatalogTree();
    
    const { isLoading: isWidgetsLoading, isFetching: isWidgetsFetching } = useGetWidgetsQuery();
    const [updateWidget, { isLoading: isWidgetsUpdating }] = useUpdateWidgetMutation();
    const [deleteWidget, { isLoading: isWidgetDeleting }] = useDeleteWidgetMutation();
    const [exportWidget, { isLoading: isWidgetExporting }] = useExportWidgetMutation();

    const isLoading = isWidgetsLoading || isWidgetsFetching || isWidgetsUpdating || isWidgetDeleting || isWidgetExporting;

    const [currentItems, setCurrentItems] = useState<ICatalogedTreeItem[]>();

    const [searchParams, setSearchParams] = useSearchParams();
    const searchText = searchParams.get("s");

    const openColibriTab = useOpenColibriTab();

    const { showPermissionsWindow, showImportResourceWindow } = useActions();

    useEffect(() => {
        if (searchText && tree) {
            setCurrentItems(findItemsInTreeByName(tree, searchText));
        } else {
            setCurrentItems(tree?.items);
        }
    }, [tree, searchText]);

    const onSearch = (value: string): void => {
        if (value) {
            setSearchParams(createSearchParams({ s: value }));
        } else {
            setSearchParams();
        }
    };

    const onItemClicked = (item: ICatalogedTreeItem): void => {};

    const onItemChanged = (item: ICatalogedTreeItem, changes: Partial<ICatalogedTreeItem>): void => {
        const params = { id: item.id, ...changes };
        updateWidget(params);
    };

    const onItemRenamed = (newName: string, item: ICatalogedTreeItem) => {
        if (item.name !== newName) {
            onItemChanged(item, {
                name: newName,
            });
        }
    };

    const showDeleteConfirm = (item: ICatalogedTreeItem) => {
        Modal.confirm({
            title: "Необходимо подтверждение",
            icon: <DeleteOutlined />,
            content: <span>Вы уверены, что хотите удалить <b>{item.name}</b>?</span>,
            okText: "Подтвердить",
            onOk: () => deleteWidget(item.id),
            cancelText: 'Отмена',
        });
    };

    const listActions: IListAction[] = [
        {
            key: "editor",
            tooltipText: "Открыть в конструкторе",
            icon: <ToolOutlined />,
            onClick: (item) => openColibriTab(`/widget/edit/${item.id}`),
            specificSourceType: ["analysis.widget"],
        }, {
            key: "permissions",
            tooltipText: "Настройка разрешений",
            icon: <SafetyOutlined />,
            accessRoles: ["security-admin"],
            onClick: (item) => showPermissionsWindow(item),
            specificSourceType: ["analysis.widget"],
        }, {
            key: "export",
            tooltipText: "Выгрузить в файл",
            icon: <FileZipOutlined />,
            onClick: (item) => exportWidget({ id: item.id, name: item.name }),
            specificSourceType: ["analysis.widget"],
        }, {
            key: "delete",
            tooltipText: "Удалить",
            icon: <DeleteOutlined />,
            onClick: showDeleteConfirm,
            danger: true,
            specificSourceType: ["analysis.widget"],
        },
    ];

    return (
        <div className="bg-white h-full w-full shadow p-8 rounded-lg">
            <ItemListHeader
                currentFolder={tree}
                isSerchPanelEnabled={true}
                onSearch={onSearch}
                buttons={[{
                    key: "open-editor",
                    icon: <PlusOutlined />,
                    buttonText: "Создать",
                    clickEvent: () => openColibriTab("/widget/new"),
                    disabled: isLoading,
                    accessRoles: ['analysis-writer'],
                    dropdownButtons: [{
                        key: "load-file",
                        buttonText: "Загрузить из файла",
                        icon: <FileAddOutlined />,
                        clickEvent: () => showImportResourceWindow({resourceType: "analysis.widget"}),
                    }],
                }]}
            />
            <ItemList
                isLoading={isLoading}
                listItems={currentItems || []}
                itemActions={listActions}
                itemClickEvent={onItemClicked}
                itemRenamedEvent={onItemRenamed}
                iconRender={(item: ICatalogedTreeItem) => {
                    const sourceWidget = item.sourceItem as IWidget;
                    const widgetMeta = getWidgetMetaByType(sourceWidget.type);
                    return <Tooltip title={`Тип: ${widgetMeta.typeName}`} placement="right" >
                        <div className="text-2xl" style={{ color: "#8d6ed6" }}>{widgetMeta.icon}</div>
                    </Tooltip>;
                }}
            />
        </div>
    )
});

const getWidgetMetaByType = (type: string): IWidgetMeta => {
    const result: IWidgetMeta = {
        typeName: "Неизвестно",
        icon: <QuestionCircleTwoTone />,
    };
    if (type === "chart_bar") {
        result.typeName = "Линейчатая";
        result.icon = <AlignLeftOutlined />;
    } else if (type === "chart_column") {
        result.typeName = "Гистограмма";
        result.icon = <BarChartOutlined />;
    } else if (type === "chart_line") {
        result.typeName = "График";
        result.icon = <StockOutlined />;
    } else if (type === "chart_point") {
        result.typeName = "Точечная";
        result.icon = <DotChartOutlined />;
    } else if (type === "chart_pie") {
        result.typeName = "Круговая";
        result.icon = <PieChartTwoTone />;
    } else if (type === "chart_combined") {
        result.typeName = "Комбинированная диаграмма";
        result.icon = <FundProjectionScreenOutlined />;
    } else if (type === "grid") {
        result.typeName = "Таблица";
        result.icon = <TableOutlined />;
    } else if (type === "map") {
        result.typeName = "Карта";
        result.icon = <EnvironmentOutlined />;
    } else if (type === "value") {
        result.typeName = "Значение";
        result.icon = <FunctionOutlined />;
    } else if (type === "report") {
        result.typeName = "Выборка данных";
        result.icon = <DatabaseTwoTone />;
    }
    return result;
};