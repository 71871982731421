import {  ICatalogedTreeItem } from '../../models/treeModels';
import { useMemo, useEffect } from 'react';
import { useAppSelector } from './hooks';
import { buildTree } from '../../helpers/treeHelpers';
import { selectAllDataSourcesTables, selectAllDataSourcesSchemas, useGetDataSourcesTablesQuery, useGetDataSourcesSchemasQuery } from '../store/colibri/dataSources.api';
import { showError } from 'helpers/messageHelpers';

export const useDataSourcesCatalogTree = (): ICatalogedTreeItem => {
    const { isError: isSchemasError } = useGetDataSourcesSchemasQuery(undefined, { refetchOnFocus: true });
    const { isError: isTablesError } = useGetDataSourcesTablesQuery(undefined, { refetchOnFocus: true });

    useEffect(() => {
        if (isSchemasError) {
            showError("Не удалось обновить список каталогов", 5);
        }
        if (isTablesError) {
            showError("Не удалось обновить список таблиц", 5);
        }
    }, [isSchemasError, isTablesError]);

    const schemas = useAppSelector(selectAllDataSourcesSchemas);
    const tables = useAppSelector(selectAllDataSourcesTables);
    const result = useMemo<ICatalogedTreeItem>(() => buildTree("datasources", "Источники данных", schemas, tables), [schemas, tables]);
    return result;
};
