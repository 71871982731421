import { ColibriSourceItem, ColibriSourceItemsSet, ColibriSourceItemType, ICatalogedTreeItem, IFolder, isReportingFolder } from "../models/treeModels";
import { isTemplate } from "../models/reportingModels";
import { isWorkflow } from "../models/workflowModels";
import { isDashboard, isWidget } from "../models/analysisModels";
import { IDataSourceSchema, IDataSourceTable, isDataSourceSchema, isDataSourceTable } from "../models/dataSourcesModels";
import { parseAndFormatDateFromString } from "./dateHelpers";

const getItemSourceType = (item: ColibriSourceItem): ColibriSourceItemType | null => {
    if (isReportingFolder(item)) {
        return "reporting.folder";
    } else if (isTemplate(item)) {
        return "reporting.template";
    } else if (isWorkflow(item)) {
        return "workflow.process";
    } else if (isWidget(item)) {
        return "analysis.widget"
    } else if (isDashboard(item)) {
        return "analysis.dashboard"
    } else if (isDataSourceSchema(item)) {
        return `cubes.${item.name}` // У схемы источника данных тип должен быть cubes.[имя_схемы]
    } else if (isDataSourceTable(item)) {
        return `cubes.${item.schema}` // У таблицы источника данных тип должен быть cubes.[имя_схемы] (как у схемы)
    } else {
        return null;
    }
}

/**
 * Method for converting a template object or a folder object to a common cataloged item object
 * @param item - template or folder object
 * @returns ICatalogedItem object
 */
export const getCatalogedItem = (item: ColibriSourceItem, pid?: string | null): ICatalogedTreeItem => {
    const isItemCatalog = isReportingFolder(item) || isDataSourceSchema(item);
    const result: ICatalogedTreeItem = {
        id: isDataSourceSchema(item) ? item.name : item.id,
        pid: pid || null,
        parentItem: null,
        name: item.name,
        isCatalog: isItemCatalog,
        sourceType: getItemSourceType(item),
        sourceItem: item,
    };
    return result;
};

export const getParentsChain = (item: ICatalogedTreeItem): ICatalogedTreeItem[] => {
    const parents: ICatalogedTreeItem[] = [];
    let currentParent = item.parentItem;
    while (currentParent) {
        parents.push(currentParent);
        currentParent = currentParent.parentItem;
    }
    parents.reverse();
    return parents;
};

export const isItemDirectParentOfItem = (item: ICatalogedTreeItem, child: ICatalogedTreeItem): boolean => {
    return (typeof child.pid === "string" && child.pid === item.id) ||
        (Array.isArray(child.pid) && child.pid.includes(item.id));
};

export const isItemParentOfItem = (item: ICatalogedTreeItem, child: ICatalogedTreeItem): boolean => {
    if (item.isRoot || isItemDirectParentOfItem(item, child)) {
        return true;
    }
    const parentsChain = getParentsChain(child);
    const result = parentsChain.findIndex((parent) => parent.id === item.id) !== -1;
    return result;
};


/**
 * Build a tree from flatten list
 * @param list - flatten list of cataloged items
 * @returns tree of cataloged items
 */
export const buildTree = (rootId: string, rootTitle: string, ...items: ColibriSourceItemsSet): ICatalogedTreeItem => {
    const sourceList = items?.flat(1); //Все элементы помещены в один плоский массив
    const treeItemsList: ICatalogedTreeItem[] = [];
    sourceList.forEach((item) => { //
        if (isTemplate(item) && item.folders?.length) {
            item.folders.forEach((folder) => treeItemsList.push(getCatalogedItem(item, folder.id)));
        } else if (isReportingFolder(item)) {
            treeItemsList.push(getCatalogedItem(item, item.pid));
        } else if (isDataSourceTable(item) && item.schema) {
            treeItemsList.push(getCatalogedItem(item, item.schema));
        } else {
            treeItemsList.push(getCatalogedItem(item));
        }
    });
    const tree: ICatalogedTreeItem = {// Корневой элемент дерева
        id: rootId,
        isRoot: true,
        pid: null,
        name: rootTitle,
        isCatalog: true,
        items: [],
        parentItem: null,
        sourceItem: null,
        sourceType: null,
    };
    for (let i = 0; i < treeItemsList.length; i++) {
        const currentItem: ICatalogedTreeItem = treeItemsList[i];
        if (currentItem.isCatalog) { //Если элемент текущей итерации каталог (папка или схема)
            //Найдем все элементы, у которых есть pid равный ID текущего элемента и поместим их в items текущего элемента. 
            //Попутно поставим им parentItem равный текущему элементу
            const children = treeItemsList.filter((item) => item.pid?.includes(currentItem.id));
            // Проставим каждому дочернему элементу parentItem, не теряя ссылку на объект из массива treeItemsList
            children.forEach((item) => item.parentItem = currentItem);
            currentItem.items = children;
        }
        //Если у элемента вообще нет pid, то закинем его в корень
        if (!currentItem.pid) {
            currentItem.parentItem = tree;
            tree.items?.push(currentItem);
        }
    }
    return tree;
}

export const findNodeById = (tree: ICatalogedTreeItem, nodeId: string): ICatalogedTreeItem | null => {
    if (!nodeId || tree.id === nodeId) {
        return tree;
    }
    let result = null;
    if (Array.isArray(tree.items) && tree.items?.length > 0) {
        tree.items?.some((item) => { //used some to break the loop
            result = findNodeById(item, nodeId);
            return result;
        });
    }
    return result;
};

export const findItemsInTreeByName = (tree: ICatalogedTreeItem, searchString: string): ICatalogedTreeItem[] => {
    if (!searchString) {
        return tree.items || [];
    }
    return tree.items?.reduce((result: ICatalogedTreeItem[], item) => {
        if (item.name.toLowerCase().includes(searchString.toLowerCase())) {
            result.push(item);
        }
        if (item.isCatalog && item.items) {
            result.push(...findItemsInTreeByName(item, searchString))
        }
        return result;
    }, []) || [];
};

export const getTreeItemPathSegmentsById = (item: ICatalogedTreeItem) => {
    const parents = getParentsChain(item).reverse();
    const pathSegments = parents.map((p) => p.id);
    item.isRoot !== true && pathSegments.push(item.id);
    return pathSegments;
};

export const getTreeItemPathNames = (item: ICatalogedTreeItem) => {
    const parents = getParentsChain(item).reverse();
    const pathNames = parents.map((p) => p.name);
    item.isRoot !== true && pathNames.push(item.name);
    return pathNames;
};

export const getTreeItemMetadataString = (item: ICatalogedTreeItem): string | null => {
    if (item.sourceType === "analysis.dashboard" || item.sourceType === "analysis.widget" || item.sourceType === "reporting.template") {
        const sourceItem = item.sourceItem as Exclude<ColibriSourceItem, IFolder | IDataSourceSchema | IDataSourceTable>;
        return `Автор: ${sourceItem?.editor || "Нет данных"} | Дата: ${parseAndFormatDateFromString(sourceItem?.modified)}`;
    } else {
        return null;
    }
};
