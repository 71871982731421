import { createSelector } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { downloadFileByBlob } from "helpers/filesHelper";
import { RootState } from "..";
import { showError, showSuccess } from "../../../helpers/messageHelpers";
import { IDashboard, IWidget } from "../../../models/analysisModels";
import { baseQueryWithReauth } from "../baseQueryWithReauth";

export const analysisApi = createApi({
    reducerPath: 'colibri/api/analysis',
    baseQuery: baseQueryWithReauth({
        baseUrl: '/api/v1',
    }),
    tagTypes: ["Dashboards", "Widgets"],
    endpoints: (build) => ({
        getWidgets: build.query<IWidget[], void>({
            query: () => ({
                url: 'widgets',
            }),
            providesTags: ["Widgets"],
            transformResponse: (response: IWidget[]) => {
                return response.map((w) => ({ ...w, isWidget: true }))
            },
        }),
        updateWidget: build.mutation<IWidget, Pick<IWidget, "id"> & Partial<IWidget>>({
            query: ({ id, ...patch }) => ({
                url: `widgets/${id}`,
                method: "PATCH",
                body: patch,
            }),
            async onQueryStarted(widget, { dispatch, queryFulfilled }) {
                let result: { meta: any; data: IWidget; } | null = null;
                try {
                    result = await queryFulfilled;
                } catch (e: any) {
                    showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                    console.error(e);
                    return;
                }
                if (result && result.meta?.response?.status === 200) {
                    dispatch(analysisApi.util.updateQueryData('getWidgets', undefined, (draft) => {
                        const updatedWidget = result?.data;
                        if (widget.id === result?.data.id) {
                            const changedIndex = draft.findIndex((t) => t.id === widget.id);
                            if (changedIndex !== -1 && updatedWidget) {
                                draft[changedIndex] = {
                                    ...draft[changedIndex],
                                    ...updatedWidget,
                                };
                                showSuccess("Изменения сохранены!");
                            }
                        } else {
                            showError();
                            console.error("ID изменяемого виджета и ID виджета в ответе не совпадают!");
                            return;
                        }
                    }))
                }
            },
        }),
        deleteWidget: build.mutation<void, string>({
            query: (id) => ({
                url: `widgets/${id}`,
                method: "DELETE",
            }),
            async onQueryStarted(widgetId, { dispatch, queryFulfilled }) {
                let result: { meta: any; data: any; } | null = null;
                try {
                    result = await queryFulfilled;
                } catch (e: any) {
                    return showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                }
                if (result && result.meta?.response?.status === 200) {
                    dispatch(analysisApi.util.updateQueryData('getWidgets', undefined, (draft) => {
                        const removedIndex = draft.findIndex((w) => w.id === widgetId);
                        if (removedIndex !== -1) {
                            const widgetName = draft[removedIndex]?.name;
                            draft.splice(removedIndex, 1);
                            showSuccess(`Виджет "${widgetName}" был удален!`);
                        }
                    }))
                }
            },
        }),
        exportWidget: build.mutation<any, { id: string, name: string }>({
            queryFn: async ({ id, name }, _, __, baseQuery) => {
                const result = await baseQuery({
                    url: `widgets/${id}/export`,
                    responseHandler: ((response) => response.blob()),
                });
                const resultMeta = result.meta as any;
                if (!resultMeta.response.ok) {
                    showError();
                } else {
                    downloadFileByBlob(result.data, name, "colibriwidget");
                }
                return { data: null }
            },
        }),
        importWidget: build.mutation<string, FormData>({
            query: (formData) => ({
                url: `widgets/import`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Widgets"],
        }),
        getDashboards: build.query<IDashboard[], void>({
            query: () => 'dashboards',
            providesTags: ["Dashboards"],
            transformResponse: (response: IDashboard[]) => {
                return response.map((d) => ({ ...d, isDashboard: true }))
            },
        }),
        updateDashboard: build.mutation<IDashboard, Pick<IDashboard, "id"> & Partial<IDashboard>>({
            query: ({ id, ...patch }) => ({
                url: `dashboards/${id}`,
                method: "PATCH",
                body: patch,
            }),
            async onQueryStarted(dashboard, { dispatch, queryFulfilled }) {
                let result: { meta: any; data: IDashboard; } | null = null;
                try {
                    result = await queryFulfilled;
                } catch (e: any) {
                    showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                    console.error(e);
                    return;
                }
                if (result && result.meta?.response?.status === 200) {
                    dispatch(analysisApi.util.updateQueryData('getDashboards', undefined, (draft) => {
                        const updatedDashboard = result?.data;
                        if (dashboard.id === result?.data.id) {
                            const changedIndex = draft.findIndex((d) => d.id === dashboard.id);
                            if (changedIndex !== -1 && updatedDashboard) {
                                draft[changedIndex] = {
                                    ...draft[changedIndex],
                                    ...updatedDashboard,
                                };
                                showSuccess();
                            }
                        } else {
                            showError();
                            console.error("ID изменяемого дэшборда и ID дэшборда в ответе не совпадают!");
                            return;
                        }
                    }))
                }
            },
        }),
        deleteDashboard: build.mutation<void, string>({
            query: (id) => ({
                url: `dashboards/${id}`,
                method: "DELETE",
            }),
            async onQueryStarted(dashboardId, { dispatch, queryFulfilled }) {
                let result: { meta: any; data: any; } | null = null;
                try {
                    result = await queryFulfilled;
                } catch (e: any) {
                    return showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                }
                if (result && result.meta?.response?.status === 200) {
                    dispatch(analysisApi.util.updateQueryData('getDashboards', undefined, (draft) => {
                        const removedIndex = draft.findIndex((d) => d.id === dashboardId);
                        if (removedIndex !== -1) {
                            const dashboardName = draft[removedIndex]?.name;
                            draft.splice(removedIndex, 1);
                            showSuccess(`Дэшборд "${dashboardName}" был удален!`);
                        }
                    }))
                }
            },
        }),
        exportDashboard: build.mutation<any, { id: string, name: string }>({
            queryFn: async ({ id, name }, _, __, baseQuery) => {
                const result = await baseQuery({
                    url: `dashboards/${id}/export`,
                    responseHandler: ((response) => response.blob()),
                });
                const resultMeta = result.meta as any;
                if (!resultMeta.response.ok) {
                    showError();
                } else {
                    downloadFileByBlob(result.data, name, "colibridashboard");
                }
                return { data: null }
            },
        }),
        importDashboard: build.mutation<string, FormData>({
            query: (formData) => ({
                url: `dashboards/import`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Dashboards"],
        }),
    }),
});

export const {
    useGetWidgetsQuery,
    useGetDashboardsQuery,
    useUpdateWidgetMutation,
    useDeleteWidgetMutation,
    useUpdateDashboardMutation,
    useDeleteDashboardMutation,
    useExportDashboardMutation,
    useImportDashboardMutation,
    useImportWidgetMutation,
    useExportWidgetMutation,
} = analysisApi;

const selectWidgetsResult = analysisApi.endpoints.getWidgets.select(undefined);
const selectDashboardsResult = analysisApi.endpoints.getDashboards.select(undefined);

export const selectAllWidgets = createSelector(
    selectWidgetsResult,
    widgetsResult => widgetsResult?.data ?? []
);

export const selectAllDashboards = createSelector(
    selectDashboardsResult,
    dashboardsResult => dashboardsResult?.data ?? []
);

export const selectDashboardById = createSelector(
    selectAllDashboards,
    (state: RootState, dashboardId: string) => dashboardId,
    (dashboards, dashboardId) => dashboards.find(dashboard => dashboard.id === dashboardId)
);