import { createSelector } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { showError, showSuccess } from "helpers/messageHelpers";
import { IDataSourceTable, IDataSourceSchema } from "../../../models/dataSourcesModels";
import { baseQueryWithReauth } from "../baseQueryWithReauth";

export const dataSourcesApi = createApi({
    reducerPath: 'colibri/api/dataSources',
    baseQuery: baseQueryWithReauth({
        baseUrl: '/api/v1',
    }),
    tagTypes: ["Schemas", "Tables"],
    endpoints: (build) => ({
        getDataSourcesSchemas: build.query<IDataSourceSchema[], void>({
            query: () => 'dataSources/schemas',
            providesTags: ["Schemas"],
            transformResponse: (response: IDataSourceSchema[]) => {
                return response.map((schema) => ({
                    ...schema,
                    isDataSourceSchema: true
                }));
            },
        }),
        getDataSourcesTables: build.query<IDataSourceTable[], void>({
            query: () => 'dataSources/cubes',
            providesTags: ["Tables"],
            transformResponse: (response: IDataSourceTable[]) => {
                return response.map((table) => ({
                    ...table,
                    isDataSourceTable: true
                }));
            },
        }),
        addSchema: build.mutation<IDataSourceSchema, Exclude<IDataSourceSchema, "isDataSourceSchema">>({
            query: (body) => ({
                url: `dataSources/schemas`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ["Schemas", "Tables"],
            async onQueryStarted(schema, { queryFulfilled }) {
                let result: { meta: any; data: IDataSourceSchema; } | null = null;
                try {
                    result = await queryFulfilled;
                } catch (e: any) {
                    return showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                }
                if (result && result.meta?.response?.status === 200) {
                    showSuccess(`Добавлен новый каталог "${schema.title}". Не забудьте установить необходимые разрешения!`);
                }
            },
        }),
        deleteSchema: build.mutation<void, string>({
            query: (name) => ({
                url: `dataSources/schemas/${name}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Schemas", "Tables"],
            async onQueryStarted(schemaName, { queryFulfilled}) {
                let result: { meta: any; data: any; } | null = null;
                try {
                    result = await queryFulfilled;
                } catch (e: any) {
                    return showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                }
                if (result && result.meta?.response?.status === 200) {
                    showSuccess(`Каталог "${schemaName}" был удален!`);
                }
            },
        }),
    }),
});

export const {
    useGetDataSourcesSchemasQuery,
    useGetDataSourcesTablesQuery,
    useAddSchemaMutation,
    useDeleteSchemaMutation,
} = dataSourcesApi;

const selectDataSourcesSchemasResult = dataSourcesApi.endpoints.getDataSourcesSchemas.select(undefined);
const selectDataSourcesTablesResult = dataSourcesApi.endpoints.getDataSourcesTables.select(undefined);

export const selectAllDataSourcesSchemas = createSelector(
    selectDataSourcesSchemasResult,
    result => result?.data ?? []
);

export const selectAllDataSourcesTables = createSelector(
    selectDataSourcesTablesResult,
    result => result?.data ?? []
);