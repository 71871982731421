import { IFolder } from "./treeModels";

export interface ITemplate {
  id: string;
  name: string;
  folders: IFolder[];
  filename: string;
  editor: string;
  modified: string;
  publishedLinkId: string | null;
  tmpltype: string;
  version: number;
  isTemplate: boolean;
}

export function isTemplate(obj: any): obj is ITemplate {
  return obj && obj.isTemplate === true;
}

export interface ITemplateVersion {
  id: string;
  templateId: string;
  modified: string;
  editor: string;
  version: number;
};