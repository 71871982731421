import React from "react";
import BgLeft from "../images/bg-left.svg";
import BgRight from "../images/bg-right.svg";

export const Background: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (<div className="h-full bg-colibri-sapphire relative">
        <div className="absolute top-0 left-0 h-full blur-3xl" style={{ width: "100%" }}>
            <img className="h-full" style={{ width: "100%" }} alt="bgleft" src={BgLeft} />
        </div>
        <div className="absolute top-0 right-0 h-full blur-3xl" style={{ width: "100%" }}>
            <img className="h-full" style={{width: "100%"}} alt="bgright" src={BgRight} />
        </div>
        {children}
    </div>)
};