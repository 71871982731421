import React from "react";
import Layout from "antd/lib/layout";
import { Route, Routes, useLocation } from "react-router-dom";
import { MainMenuComponent } from "./MainMenuComponent";
import { HeaderComponent } from "./HeaderComponent";
import { ReportingExplorerTreeComponent } from "./ReportingExplorerTreeComponent";
import { useAppSelector } from "../app/hooks/hooks";
import { selectAllPages, selectCurrentPage } from "../app/store/pages/interfaceSlice";
import { PublishControl } from "../features/publishing/PublishControl";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { NotFoundContent } from "./NotFoundContent";
import { RootState } from "app/store";
import { PermissionsControl } from "features/permissions/PermissionsControl";
import { RelativesViewerControl } from "features/relativesViewer/RelativesViewerControl";
import { CheckSessionIframe } from "features/session/CheckSessionIframe";
import { ImportResourceControl } from "features/importResource/ImportResourceControl";

const { Content } = Layout;

export const LayoutComponent: React.FC = React.memo(() => {
    const { pathname } = useLocation();
    const pages = useAppSelector(selectAllPages);
    const currentPage = useAppSelector((state: RootState) => selectCurrentPage(state, pathname));

    const mainMenuComponent = (currentPage && currentPage.key !== 'home') && <MainMenuComponent />
    const reportingExplorerTreeComponent = (currentPage?.key === 'reporting') && <ReportingExplorerTreeComponent rootPath={currentPage.key} />

    return (
        <>
            <CheckSessionIframe/>
            <DndProvider backend={HTML5Backend}>
                <Layout style={{ height: '100%' }}>
                    <HeaderComponent />
                    <Layout>
                        {mainMenuComponent}
                        <Layout className="p-6">
                            <Content
                                className="site-layout-background"
                                style={{
                                    margin: 0,
                                    minHeight: '100%',
                                }}
                            >
                                <Routes>
                                    <Route path='*' element={<NotFoundContent />} />
                                    {pages.map(page => <Route path={page.path} element={page.component} key={page.key} />)}
                                </Routes>
                            </Content>
                            {reportingExplorerTreeComponent}
                        </Layout>
                    </Layout>
                </Layout>
                <PublishControl />
                <PermissionsControl />
                <RelativesViewerControl />
                <ImportResourceControl />
            </DndProvider>
        </>
    )
});