import { ISecuredElement } from "models/appModels";
import { rolesMap } from "./rolesMap";

export const isUserHasAccessToSecuredElement = (element: ISecuredElement, userRoles: string[]): boolean => {
    if ((element.accessRoles && element.accessRoles.length === 0)) { // if accessRoles is an empty array - deny
        return false;
    } else if (!element.accessRoles) { // if accessRoles is null or undefined - allow
        return true;
    } else { // otherwise, check if any user role contains in accessRoles
        return element.accessRoles.some((role) => {
            const actualRoleName = rolesMap.get(role);
            return actualRoleName && userRoles.indexOf(actualRoleName) !== -1;
        });
    }
}