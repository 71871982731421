import { FolderOpenOutlined, SearchOutlined } from "@ant-design/icons";
import Breadcrumb, { Route } from "antd/lib/breadcrumb/Breadcrumb";
import Divider from "antd/lib/divider";
import { useGetUserInfoQuery } from "features/user.api";
import React, { useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { isUserHasAccessToSecuredElement } from "features/security/accessChecker";
import { getParentsChain } from "../../helpers/treeHelpers";
import { IHeaderButton } from "../../models/interfaceModels";
import { ICatalogedTreeItem } from "../../models/treeModels";
import { HeaderButton } from "./HeaderButton";
import { ItemsSearch } from "./ItemsSearch";

const searchResultsPath = "search-results";

type ComponentProps = {
    currentFolder: ICatalogedTreeItem | null;
    isSerchPanelEnabled?: boolean;
    onSearch?: (value: string) => void;
    buttons?: IHeaderButton[];
};

export const ItemListHeader: React.FC<ComponentProps> = React.memo(({
    currentFolder,
    isSerchPanelEnabled,
    onSearch,
    buttons,
}) => {
    const { data: userInfo } = useGetUserInfoQuery();

    const [searchParams] = useSearchParams();
    const searchText = isSerchPanelEnabled ? searchParams.get("s") : null;

    const breadcrumbs = useMemo(() => getBreadcrumbs(currentFolder, searchText), [currentFolder, searchText]);
    const currentFolderName = useMemo(() => breadcrumbs?.[breadcrumbs.length - 1]?.breadcrumbName || "", [breadcrumbs]);

    const itemRender = (route: Route, params: any, routes: Route[]): React.ReactNode => {
        const isLast = routes.indexOf(route) === routes.length - 1;

        const breadCrumbsElement = (<div className="inline-flex items-center gap-x-1">
            {route.path === searchResultsPath ? <SearchOutlined /> : <FolderOpenOutlined />}
            {isLast
                ? <span>{route.breadcrumbName}</span>
                : <Link to={route.path}>{route.breadcrumbName}</Link>
            }
        </div>)

        return breadCrumbsElement;
    }
    
    const allowedButtons = useMemo(() => { //Получение перечня кнопок, которые доступны согласно ролям
        if (userInfo) {
            return buttons?.filter((button) => isUserHasAccessToSecuredElement(button, userInfo.roles))
        } else {
            return [];
        }
    }, [buttons, userInfo]);

    return (
        <div>
            <div className="breadcrumbs-area">
                <Breadcrumb routes={breadcrumbs} itemRender={itemRender} />
            </div>
            <div className="grid grid-rows-2 xl:grid-rows-none gap-3 xl:gap-0 xl:grid-cols-[auto_max-content] items-center mt-3">
                <div className="toolbar-area">
                    {allowedButtons && allowedButtons.map((button) => <HeaderButton {...button} />)}
                </div>
                <div className="search-area order-first xl:order-none">
                    <ItemsSearch
                        searchText={searchText}
                        isSerchPanelEnabled={isSerchPanelEnabled}
                        onSearch={onSearch}
                        currentFolderName={currentFolderName}
                    />
                </div>
            </div>
            <Divider className="mt-4 mb-1" />
        </div>
    )
});

const getBreadcrumbs = (currentFolder: ICatalogedTreeItem | null, searchText?: string | null): Route[] => {
    const result: Route[] = [];
    if (!currentFolder) {
        return result;
    }
    const parentsChain = getParentsChain(currentFolder); //Получаем всех родителей текущей папки
    [...parentsChain, currentFolder].forEach((folder) => {//Добавляем к родителям текущую папку и формируем крошки
        result.push({
            path: folder.isRoot ? "" : folder.id,
            breadcrumbName: folder.name,
        });
    });
    if (searchText) {//Если что-то есть в поиске - добавим крошку "Результаты поиска"
        result.push({
            path: searchResultsPath,
            breadcrumbName: "Результаты поиска"
        })
    }
    return result;
}