import React, { useRef } from "react";
import { useGetUserInfoQuery } from "../features/user.api";
import { PoweroffOutlined } from "@ant-design/icons";
import Divider from "antd/lib/divider";
import Button from "antd/lib/button";
import Popover from "antd/lib/popover";
import Avatar from "antd/lib/avatar";
import Layout from "antd/lib/layout";
import colibriLogo from "../images/logo.svg";
import { ChooseTeamDropdown } from "features/teams/ChooseTeamDropdown";
import Space from "antd/es/space";
import { AntiforgeryForm } from "features/antiforgery/AntiforgeryForm";

const { Header } = Layout;

export const HeaderComponent: React.FC = React.memo(() => {
    const { data: userInfo } = useGetUserInfoQuery();
    const userInitials = useRef(userInfo ? getUserNameInitials(userInfo.userName) : null);

    const userPopoverContent = <div style={{ width: 350 }}>
        {userInfo?.roleNames?.map((role) => <div key={role}>{role}</div>)}
        <div className="text-right text-gray-400 text-xs mt-2">Всего ролей: {userInfo?.roleNames?.length || 0}</div>
        <Divider className="mb-4" />
        <div className="flex items-center justify-center">
            <AntiforgeryForm action="/auth/logout" method="POST">
                <Button href="/auth/logout" className="flex items-center justify-center py-0" style={{ width: 200 }} danger block icon={<PoweroffOutlined />}>Завершить сеанс</Button>
            </AntiforgeryForm>            
        </div>
    </div>

    return (
        <Header className="header" style={{
            paddingInline: "20px",
            backgroundImage: "linear-gradient(to right, #051937, #051937, #051937, #051937, #051937)"
        }}>
            <div className="flex flex-wrap justify-between items-center mx-auto h-full">
                <a href="/" className="flex items-center">
                    <img src={colibriLogo} className="mr-1" width={40} alt="Колибри" />
                    <span className="self-center text-xl whitespace-nowrap text-white font-gilroy-bold mt-[2px]">КОЛИБРИ</span>
                </a>
                {userInfo && <Space>
                    <ChooseTeamDropdown />
                    <Popover className="flex items-center lg:order-2 cursor-pointer" placement="bottomRight" title={"Информация о пользователе"} content={userPopoverContent} trigger="click">
                        <Avatar className="mr-3" style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{userInitials.current}</Avatar>
                        <span className="self-center text-medium whitespace-nowrap text-white">{userInfo.userName}</span>
                    </Popover>
                </Space>}
            </div>
        </Header>
    )
});

const getUserNameInitials = (name: string) => {
    const nameWords = name.split(" ");
    let result = "--" //default value
    if (nameWords?.length > 0) {
        result = nameWords.map((w) => w[0]).join("").toLocaleUpperCase();
    }
    return result;
}