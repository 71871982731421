import Form from "antd/lib/form"
import Radio from "antd/lib/radio"
import Select from "antd/lib/select"
import { memo, useEffect } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { IPublishedLink, RepeatingModes } from "../PublishWizardForm"
import { StepButtons } from "../controls/StepButtons"
import { useAutoFocus, validationFeedback } from "../utils"
import { StepTitle } from "../controls/StepTitle"
import DatePicker from "antd/lib/date-picker"
import moment from "moment"
import InputNumber from "antd/lib/input-number"
import dayjs from "dayjs"

export const ScheduleStep = memo(() => {
    const { control, setValue, getValues } = useFormContext<IPublishedLink & { scheduleOptions: { _startTime: string | null } }>()
    const autoFocus = useAutoFocus()

    console.debug(getValues().scheduleOptions)

    const mode = useWatch({ name: 'scheduleOptions.mode', control })

    const format = 'DD.MM.YYYY HH:mm'

    useEffect(() => {
        setValue('scheduleOptions.type', 'Recurring')        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const o = getValues().scheduleOptions        
        let startTime = moment([o.year, o.month-1, o.day, o.hour, o.min, 0, 0])
        if(!startTime.isValid())
            startTime = moment()
        setValue('scheduleOptions._startTime', startTime.toString())

        if(o.repeatDays == null)
            setValue('scheduleOptions.repeatDays', 1)

        if(o.repeatMinutes == null)
            setValue('scheduleOptions.repeatMinutes', 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const startTime = moment(useWatch({ control, name: 'scheduleOptions._startTime' }))
    useEffect(() => {        
        if(!startTime.isValid())
            return        
        const [year, month, day, hour, minute] = startTime.toArray()
        setValue('scheduleOptions.year', year)
        setValue('scheduleOptions.month', month+1)
        setValue('scheduleOptions.day', day)
        setValue('scheduleOptions.hour', hour)
        setValue('scheduleOptions.min', minute)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTime?.toString()])

    return <>
        <StepTitle>Расписание</StepTitle>
        <Controller
            control={control}
            name='scheduleOptions.mode'
            rules={{ required: 'Необходимо выбрать значение' }}
            render={({ field, fieldState }) =>
                <Form.Item
                    label='Когда вы хотите запускать задачу?'
                    required
                    {...validationFeedback(fieldState)}>
                    <Radio.Group
                        size='large'
                        buttonStyle="solid" {...field}>
                        {Object.entries(RepeatingModes).map(([key, displayName], i) =>
                            <Radio.Button ref={i === 0 ? autoFocus : undefined} key={key} value={key}>{displayName}</Radio.Button>
                        )}
                    </Radio.Group>
                </Form.Item>
            } />
        <Controller
            control={control}
            name='scheduleOptions._startTime'            
            rules={{ required: 'Необходимо выбрать значение' }}
            render={({ field, fieldState }) => {                
                return <Form.Item
                    label='Начать'
                    required
                    {...validationFeedback(fieldState)}>
                    <DatePicker                        
                        format={format}
                        value={field.value ? dayjs(field.value) : undefined}
                        onChange={t => field.onChange(t?.isValid() ? t?.toString() : null)}
                        picker='date'
                        showTime={{ showSecond: false }} 
                        />
                </Form.Item>
            }
            } />
        {mode === 'Daily' &&
            <Controller
                control={control}
                name='scheduleOptions.repeatDays'
                defaultValue={1}
                rules={{ required: 'Необходимо указать значение' }}
                render={({ field, fieldState }) =>
                    <Form.Item
                        label='Интервал повторения в днях'
                        required
                        {...validationFeedback(fieldState)}>
                        <InputNumber value={field.value} min={1} onChange={field.onChange}  />
                    </Form.Item>
                } />}
        {mode === 'Hourly' &&
            <Controller
                control={control}
                defaultValue={1}
                name='scheduleOptions.repeatMinutes'
                rules={{ required: 'Необходимо указать значение' }}
                render={({ field, fieldState }) =>
                    <Form.Item
                        label='Интервал повторения в минутах'                        
                        required
                        {...validationFeedback(fieldState)}>
                        <InputNumber max={59} min={1} value={field.value} onChange={field.onChange} />
                    </Form.Item>
                } />}
        {mode === 'Monthly' &&
            <Controller
                control={control}
                name='scheduleOptions.months'
                defaultValue={[]}
                rules={{ required: 'Необходимо выбрать хотя бы один месяц' }}
                render={({ field, fieldState }) =>
                    <Form.Item
                        label='Месяцы'
                        required
                        {...validationFeedback(fieldState)}>
                        <Select
                            value={field.value?.map(v=>v.toString()) || []}                                                    
                            onChange={months => {
                                field.onChange(months?.map(v=>v.toString()) || [])
                            }}                                                        
                            mode='tags'
                            style={{ width: '100%' }}>
                            {Monthes.map((value, index) => {
                                return <Select.Option key={index+1}>{value}</Select.Option>
                            })}
                        </Select>
                    </Form.Item>
                } />}
        <StepButtons />
    </>
})

const Monthes = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
]