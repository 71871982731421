import React from "react";
import Table from 'antd/lib/table';
import type { ColumnsType } from 'antd/lib/table';
import { IPermission } from "models/appModels";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { TeamOutlined, UserOutlined } from "@ant-design/icons";
import Tooltip from "antd/lib/tooltip";

type ComponentProps = {
    permissionRows: IPermission[];
    resourceId: string | null;
    isLoading?: boolean;
    permissionChangeEvent: (resource: IPermission, type: "r" | "w", value: boolean) => void;
}

export const PermissionsTable: React.FC<ComponentProps> = React.memo(({ permissionRows, permissionChangeEvent, isLoading, resourceId }) => {
    
    const columns: ColumnsType<IPermission> = [
        {
            title: 'Тип',
            align: "center",
            ellipsis: true,
            width: 64,
            dataIndex: 'type',
            render: (_, record) => record.type === "user"
                ? (<Tooltip title="Пользователь"><UserOutlined /></Tooltip>)
                : (<Tooltip title="Роль"><TeamOutlined /></Tooltip>),
        }, {
            title: 'Наименование',
            dataIndex: 'identity',
        }, {
            title: 'Просмотр',
            align: "center",
            dataIndex: 'r',
            width: 120,
            ellipsis: true,
            render: (_, record) => (<Tooltip title={record.ir ? "Разрешение унаследовано" : undefined}>
                <Checkbox checked={record.ir || record.r} disabled={record.ir} onChange={(e) => permissionChangeEvent(record, "r", e.target.checked)} />
            </Tooltip>),
        }, {
            title: 'Изменение',
            align: "center",
            dataIndex: 'w',
            width: 120,
            ellipsis: true,
            render: (_, record) => (<Tooltip title={record.iw ? "Разрешение унаследовано" : undefined}>
                <Checkbox checked={record.iw || record.w} disabled={record.iw} onChange={(e) => permissionChangeEvent(record, "w", e.target.checked)} />
            </Tooltip>),
        },
    ];

    return <Table 
        columns={columns}
        dataSource={permissionRows}
        size="small"
        loading={isLoading}
        rowKey={(row) => `${row.identity}-${resourceId}`}
        pagination={false}
    />
});