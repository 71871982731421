import Form from "antd/lib/form"
import Radio from "antd/lib/radio"
import { memo, useEffect } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { IPublishedLink } from "../PublishWizardForm"
import { StepButtons } from "../controls/StepButtons"
import { useAutoFocus, validationFeedback } from "../utils"
import { StepTitle } from "../controls/StepTitle"

export const AuthStep = memo(() => {
    const { control, watch, setValue } = useFormContext<IPublishedLink>()

    const autoFocus = useAutoFocus()

    const allowAnonymous = watch('allowAnonymous')    

    const accessType = useWatch({ control, name: 'accessType' })    
    const roles = ['Анонимный пользователь отчетов']

    useEffect(() => {
        if(allowAnonymous || accessType === 'Scheduled')
            setValue('anonymousRole', roles?.[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowAnonymous])

    return <>
        <StepTitle>Укажите права доступа</StepTitle>
        <Controller
            control={control}
            name='allowAnonymous'
            rules={{
                validate: (v) => {
                    if (typeof v !== 'boolean')
                        return 'Необходимо выбрать права доступа'
                }
            }}
            render={({ field, fieldState }) =>
                <Form.Item
                    label='Доступ'
                    required
                    {...validationFeedback(fieldState)}>
                    <Radio.Group                        
                        size='large'
                        buttonStyle="solid" {...field}>
                        <Radio.Button ref={autoFocus} value={true}>Анонимный</Radio.Button>
                        <Radio.Button value={false}>Требующий аутентификации</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            } />
        {/* {allowAnonymous && <Controller
            control={control}
            name='anonymousRole'
            rules={{ required: 'Необходимо выбрать роль для анонимного доступа' }}
            render={({ field, fieldState }) =>
                <Form.Item
                    label='Роль для анонимного доступа'
                    required
                    {...validationFeedback(fieldState)}>
                    <Select defaultValue={null} {...field}>
                        {roles.map(r => <Select.Option key={r} value={r}>{r}</Select.Option>)}
                    </Select>
                </Form.Item>
            } />} */}
        <StepButtons />
    </>
})