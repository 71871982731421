import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { RcFile } from "antd/lib/upload/interface";
import { useImportProcessMutation } from "app/store/colibri/workflows.api";
import { showError } from "helpers/messageHelpers";


export const useImportProcess = () => {
    const [importProcess, { isLoading }] = useImportProcessMutation();

    const func = async (file: RcFile): Promise<boolean> => {
        const formData = new FormData();
        formData.append("file", file);

        const result = await importProcess(formData);
        if (result && Object.hasOwn(result, "error")) {
            const typedImportError = (result as any).error as FetchBaseQueryError;
            const msg = typedImportError.data as string;
            showError(typedImportError.status === 400 || ("originalStatus" in typedImportError && typedImportError.originalStatus === 400) ? msg : undefined);
            return false;
        } else {
            return true;
        }
    }
    return {
        isLoading,
        importProcess: func
    };
};