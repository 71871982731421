import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined'
import Alert from 'antd/lib/alert'
import Button from 'antd/lib/button'
import Descriptions from 'antd/lib/descriptions'
import List from 'antd/lib/list'
import Modal from 'antd/lib/modal'
import Space from 'antd/lib/space'
import Spin from 'antd/lib/spin'
import Typography from 'antd/lib/typography'
import { useActions } from 'app/hooks/hooks'
import { useCheckCanWriteResourceQuery } from 'app/store/colibri/permissions.api'
import { useDeletePublishedLinkMutation, useLazyGetPublishedLinkQuery } from 'app/store/colibri/publications.api'
import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useGetTemplateParametersQuery } from '../../app/store/colibri/reporting.api'
import { PublishedLinkExamples } from './PublishedLinkExamples'
import { IPublishedLink, PublishedLinkAccessTypes, PublishedLinkOutputTypes, ReportExportFileTypes } from './PublishWizardForm'
import { usePublishingObject } from './usePublishingObject'

export const PublicationPreview: React.FC<{
    onEdit: (publishedLink: IPublishedLink) => void;
    controlsTarget: HTMLElement | null;
}> = React.memo(({
    onEdit,
    controlsTarget
}) => {
    const publishingObject = usePublishingObject()

    const [getPublishingLink, { data: publishedLink, isFetching: isLoading, isError }] = useLazyGetPublishedLinkQuery()
    useEffect(() => {
        if (publishingObject && publishingObject.data.publishedLinkId) {
            if (publishingObject.objectType === 'reporting.template')
                getPublishingLink(publishingObject.data.publishedLinkId)
            if (publishingObject.objectType === 'analysis.dashboard')
                getPublishingLink(publishingObject.data.publishedLinkId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publishingObject?.objectId])

    const { data: isEditable } = useCheckCanWriteResourceQuery({ resourceType: publishingObject?.objectType!, resourceId: publishingObject?.objectId! }, { skip: !publishingObject?.objectId, refetchOnMountOrArgChange: true })

    const { data: parameters } = useGetTemplateParametersQuery(publishingObject?.objectId!, { skip: !publishingObject?.objectId || publishingObject.objectType !== 'reporting.template' })

    if (isLoading)
        return <Spin />

    if (isError)
        return <Alert description='Не удалось загрузить информацию о публикации'></Alert>

    if (publishedLink) {
        const hasParameters = Object.keys(publishedLink.parameterStates || {}).length || Object.keys(publishedLink.parameters || {}).length

        return <Space style={{ width: '100%' }} direction='vertical'>
            <Descriptions column={1} size='small' title="Информация о публикации" bordered colon
                labelStyle={{ width: 200 }}>
                <Descriptions.Item label="Режим доступа к данным">{PublishedLinkAccessTypes[publishedLink.accessType]}</Descriptions.Item>
                {hasParameters &&
                    <Descriptions.Item label="Параметры отчета">
                        <List
                            size='small'
                            dataSource={parameters}
                            loading={!parameters}
                            renderItem={p => {
                                return <List.Item
                                    key={p.name}
                                    extra={<i>{publishedLink.parameterStates[p.name] ? 'Опубликован' : ''}</i>}>
                                    <List.Item.Meta title={<>
                                        <Typography.Text>{p.name}</Typography.Text> <Typography.Text strong={false} type='secondary'>{p.caption}</Typography.Text>
                                    </>}></List.Item.Meta>
                                </List.Item>
                            }}
                        />
                    </Descriptions.Item>}
                <Descriptions.Item label="Права доступа">{publishedLink.allowAnonymous ? `Анонимно под ролью ${publishedLink.anonymousRole}` : 'С требованием аутентификации'}</Descriptions.Item>
                <Descriptions.Item label="Режим публикации">{PublishedLinkOutputTypes[publishedLink.outputType]}{publishedLink.outputType === 'Link' && ` (${ReportExportFileTypes[publishedLink.fileType!]})`}</Descriptions.Item>
                <Descriptions.Item label='Примеры'><PublishedLinkExamples publishedLink={publishedLink!} /></Descriptions.Item>
            </Descriptions>
            {controlsTarget && isEditable && createPortal(<>
                <DeletePublicationButton publishedLink={publishedLink} />
                <Button onClick={() => onEdit(publishedLink!)} type='primary'>Редактировать</Button>
            </>, controlsTarget)}
        </Space>
    }
    else
        return null
})

const DeletePublicationButton: React.FC<{
    publishedLink: IPublishedLink
}> = React.memo(({
    publishedLink
}) => {
    const [deletePublishedLink, { isLoading }] = useDeletePublishedLinkMutation()
    const { hidePublisher } = useActions()

    return <Button onClick={() => {
        Modal.confirm({
            title: "Необходимо подтверждение",
            icon: <DeleteOutlined />,
            content: 'Вы уверены, что хотите снять объект с публикации?',
            okText: "Подтвердить",
            onOk: async () => {
                await deletePublishedLink(publishedLink)
                hidePublisher()
            },
            cancelText: 'Отмена',
            okButtonProps: {
                loading: isLoading
            },            
        });
    }} danger>Снять с публикации</Button>
})