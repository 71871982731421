import React from "react";
import { useDrag, useDrop } from 'react-dnd'
import { ICatalogedTreeItem } from "../../models/treeModels";
import { useMoveTreeItem } from "app/hooks/useMoveTreeItem";
import { RowProps } from "antd/lib/row";

type ItemProps = Pick<ICatalogedTreeItem, "id"> & Pick<ICatalogedTreeItem, "sourceType"> & Pick<ICatalogedTreeItem, "isRoot">;

export const ItemListDraggableRow: React.FC<RowProps> = React.memo((props) => {
    const itemProps: ItemProps = props.itemProp ? JSON.parse(props.itemProp) : null;
    const id = itemProps?.id;
    const sourceType = itemProps?.sourceType;

    const [collected, drag] = useDrag(() => ({
        type: sourceType || "",
        item: {
            id,
            sourceType,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }))

    const moveTreeItem = useMoveTreeItem();

    const [collectedProps, drop] = useDrop(() => ({
        accept: ["reporting.folder", "reporting.template"],
        drop: async (source: ItemProps) => moveTreeItem(source, itemProps),
        canDrop: (sourceItem) => sourceItem.id !== id,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));

    const isActive = collectedProps.canDrop && collectedProps.isOver;
    const isDragging = collected?.isDragging;

    const attachFolderRef = (el: HTMLTableRowElement): void => {
        drag(el);
        drop(el);
    };
    const attachTemplateRef = (el: HTMLTableRowElement): void => {
        drag(el);
    };

    return <tr
        ref={sourceType === "reporting.folder" ? attachFolderRef : sourceType === "reporting.template" ? attachTemplateRef : undefined}
        {...props}
        className={`${isActive ? "bg-yellow-50" : ""}${isDragging ? " opacity-30" : ""}`} />
});
