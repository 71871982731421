import Form from "antd/lib/form"
import Radio from "antd/lib/radio"
import { memo } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { IPublishedLink, PublishedLinkAccessTypes } from "../PublishWizardForm"
import { StepButtons } from "../controls/StepButtons"
import { useAutoFocus, validationFeedback } from "../utils"
import { StepTitle } from "../controls/StepTitle"

export const AccessTypeStep = memo(() => {
    const { control } = useFormContext<IPublishedLink>()
    const autoFocus = useAutoFocus()

    return <>
        <StepTitle>Выберите режим доступа к данным</StepTitle>
        <Controller control={control} name='accessType'
            rules={{ required: 'Необходимо выбрать режим доступа' }}
            render={({ field, fieldState }) =>
                <Form.Item
                    label='Режим доступа'
                    required
                    {...validationFeedback(fieldState)}>
                    <Radio.Group
                        size='large'
                        buttonStyle="solid" {...field}>
                        {Object.entries(PublishedLinkAccessTypes).map(([key, displayName], i) =>
                            <Radio.Button ref={i === 0 ? autoFocus : undefined} key={key} value={key}>{displayName}</Radio.Button>
                        )}
                    </Radio.Group>
                </Form.Item>
            } />
        <StepButtons />
    </>
})