import './styles/tailwindPreflight.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ConfigProvider from 'antd/lib/config-provider';
import ruRU from "antd/lib/locale/ru_RU";
import 'antd/dist/reset.css';
import './styles/index.css';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    //<React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            <ConfigProvider
                locale={ruRU}
                theme={{
                    token: {
                        colorPrimary: '#051937', // Основной цвет (фон кнопок, чекбоксов)
                        colorPrimaryBg: "#EFEBFF", // Фон активного элемента в дереве катологов
                        colorPrimaryBgHover: "#EFEBFF", // Фон активного элемента в боковом меню
                        colorLink: "#7a5ac5", // Цвет ссылок
                        colorLinkHover: "#c0a8f7", // Цвет ссылок при наведении
                    },
                }}
            >
                <App />
            </ConfigProvider>
        </BrowserRouter>
    </Provider>
    //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
