import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    checkSessionEnabled: true
}

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        enableCheckSession(state) {
            state.checkSessionEnabled = true
        },
        disableCheckSession(state) {
            state.checkSessionEnabled = false
        }
    }
})

export const sessionActions = sessionSlice.actions
export const sessionReducer = sessionSlice.reducer