import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "../../app/store/baseQueryWithReauth";

export const antiforgeryApi = createApi({
    reducerPath: 'api/antiforgery',
    baseQuery: baseQueryWithReauth({
        baseUrl: '/api/v1',
    }),
    endpoints: (build) => ({
        getCsrfToken: build.query<{ token: string }, void>({
            query: () => ({
                url: 'antiforgery/token'
            })                                     
        })
    }),
});

export const {
    useGetCsrfTokenQuery
} = antiforgeryApi;