export interface IWorkflow {
  id: string,
  name: string,
  isDraft: boolean,
  tags: [],
  modified: string,
  editor: string,
  isWorkflow: boolean;
}

export function isWorkflow(obj: any): obj is IWorkflow {
  return obj && obj.isWorkflow === true;
}