import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { isDataSourceSchema } from "models/dataSourcesModels";
import { ColibriSourceItemType, ICatalogedTreeItem } from "models/treeModels";

interface IPermissionState {
    itemId: string | null;
    itemType: ColibriSourceItemType | null;
    itemName: string | null;
};

const initialState: IPermissionState = {
    itemId: null,
    itemType: null,
    itemName: null,
};

export const permissionSlice = createSlice({
    name: 'permissionStore',
    initialState,
    reducers: {
        showPermissionsWindow: (state, action: PayloadAction<ICatalogedTreeItem>) => {
            state.itemId = isDataSourceSchema(action.payload.sourceItem) ? null : action.payload.id;
            state.itemType = action.payload.sourceType;
            state.itemName = action.payload.name;
        },
        hidePermissionsWindow: (state) => {
            state.itemId = null;
            state.itemType = null;
            state.itemName = null;
        },
    },
});

const selectOptions = (state: RootState) => state.permissionStore;

export const selectPermissionItemOptions = createSelector(
    selectOptions,
    result => result.itemType ? result : null
);

export const permissionActions = permissionSlice.actions;
export const permissionReducer = permissionSlice.reducer;