import { FormInstance } from 'antd/lib/form'
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { IPublishedLink, PublishWizardFormInstance } from './PublishWizardForm'

const PublishWizardContext = createContext<PublishWizardFormInstance>(null as any)

export const usePublishWizard = () => useContext(PublishWizardContext)

export const PublishWizardProvider: React.FC<PropsWithChildren<{
    defaultStepDefinitions: StepDefinitions;
    defaultCalculateSteps: (data: IPublishedLink) => string[];
    defaultStep: string;
    onStepsChange?: (sortedStepDefinitions: StepDefinition[]) => void;
    onStepChange?: (step: string) => void;
    onComplete?: (result: IPublishedLink) => void;
    publishedLink: Partial<IPublishedLink>;
    controlsTarget: HTMLElement | null;
}>> = React.memo(({
    children,
    defaultStepDefinitions,
    defaultStep,
    onStepsChange,
    onStepChange,
    defaultCalculateSteps,
    onComplete,
    publishedLink,
    controlsTarget
}) => {
    const form = useForm<IPublishedLink>({
        defaultValues: publishedLink
    })
    const [formEl, setFormEl] = useState<FormInstance | null>(null)
    const stepDefinitionsRef = useRef(defaultStepDefinitions)

    const data = useWatch({ control: form.control })
    const oldStepsRef = useRef<string[]>()

    const steps = useMemo<string[]>(() => {
        const newSteps = defaultCalculateSteps(data as any)
        if (oldStepsRef.current?.join('_') === newSteps?.join('_')) {
            return oldStepsRef.current
        }
        oldStepsRef.current = newSteps
        return newSteps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const [stack, setStack] = useState<string[]>([defaultStep].filter(Boolean))
    const activeStep = useMemo(() => stack?.[stack.length - 1], [stack])

    const sortedStepDefinitions = useMemo(() => steps.map(s => ({ key: s, ...stepDefinitionsRef.current[s] })), [steps])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onStepsChange?.(sortedStepDefinitions), [sortedStepDefinitions])

    useEffect(() => {
        onStepChange?.(activeStep)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep])

    const setActiveStep = useCallback((step: string) => {
        setStack(s => [...s, step])
    }, [])
    const goBack = useCallback(() => {
        setStack(s => {
            const newStack = [...s]
            newStack.splice(s.length - 1, 1)
            return newStack
        })
    }, [])

    const goNext = useCallback(() => {
        const currentIndex = steps.indexOf(activeStep)
        const nextStep = steps[currentIndex + 1]
        if (nextStep)
            setActiveStep(nextStep)
        else
            onComplete?.(data as any)
    }, [setActiveStep, steps, activeStep, data, onComplete])

    const instance = useMemo<PublishWizardFormInstance>(() => ({
        allowBack: stack.length > 1,
        goBack,
        goNext,
        stepDefinitions: sortedStepDefinitions,
        form,
        steps,
        activeStep,
        formEl,
        setFormEl,
        controlsTarget
    }), [stack, goBack, goNext, form, activeStep, formEl, controlsTarget, steps, sortedStepDefinitions])

    return <PublishWizardContext.Provider value={instance}>
        <FormProvider {...form}>
            {children}
        </FormProvider>
    </PublishWizardContext.Provider>
})

export type StepDefinition<TKey = string> = {
    key: TKey;
    displayName: string;
    element: React.ReactNode;
}

export type StepDefinitions = Record<string, Omit<StepDefinition, 'key'>>