import React, { useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useAppSelector } from "../app/hooks/hooks";
import { selectAllMenuItems, selectAllPages, selectCurrentPage } from "../app/store/pages/interfaceSlice";
import { IMenuItem } from "../models/interfaceModels";
import { showError } from "helpers/messageHelpers";
import { useOpenColibriTab } from "app/hooks/useOpenColibriTab";
import Menu from "antd/lib/menu";
import Tooltip from "antd/lib/tooltip";
import Button from "antd/lib/button";
import Layout from "antd/lib/layout";
import { useGetColibriEnvironmentQuery } from "app/store/colibri/environment.api";
import { useShowAboutModal } from "app/hooks/useShowAboutModal";

const { Sider } = Layout;

export const MainMenuComponent: React.FC = React.memo(() => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { data: appEnv } = useGetColibriEnvironmentQuery();
    const openColibriTab = useOpenColibriTab();
    const showAboutModal = useShowAboutModal();

    const [collapsed, setCollapsed] = useState(false);

    const pages = useAppSelector(selectAllPages);
    const menuItems = useAppSelector(selectAllMenuItems);
    const menuTree = useMemo(() => getMenuTree(menuItems), [menuItems]);

    const currentPage = useAppSelector((state) => selectCurrentPage(state, pathname));

    const onMenuItemClick = (key: string) => {
        const menuItem = menuItems?.find((item) => item.key === key);
        if (!menuItem) {
            return showError();
        }
        if (menuItem.pageKey) {
            const linkedPage = pages?.find((page) => page.key === menuItem.pageKey);
            const pagePath = linkedPage?.path?.replaceAll("/*", "");
            if (!pagePath) {
                return showError();
            }
            return navigate(pagePath);
        } else if (menuItem.key === "etl") {
            openColibriTab("/workflow");
        } else if (menuItem.key === "security") {
            openColibriTab("/security");
        } else if (menuItem.key === "docs") {
            if (!appEnv?.helpUrl) {
                return showError("Ссылка на справку не задана в конфигурации приложения. Пожалуйста, обратитесь к системному администратору...");
            }
            window.open(appEnv?.helpUrl, "_blank", 'noopener');
        } else if (menuItem.key === "about") {
            showAboutModal();
        }
    };

    return (
        <Sider
            width={250}
            breakpoint={"xl"}
            onBreakpoint={(broken) => setCollapsed(broken)}
            collapsible
            collapsed={collapsed}
            trigger={null}
            className="site-layout-background shadow"
        >
            <Menu
                mode="inline"
                selectedKeys={[currentPage?.key as string]}
                defaultOpenKeys={getDefaultOpenSubmenuKeys(menuItems, currentPage?.key as string)}
                style={{ height: '100%', borderRight: 0 }}
                items={menuTree}
                onClick={({key}) => onMenuItemClick(key)}
            />
            <div className={`fixed bottom-0 pb-3`}>
                <Tooltip title="Свернуть" placement="right">
                    <Button
                        className="pl-3"
                        type="link"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                    />
                </Tooltip>
            </div>
        </Sider>
    )
});

const getDefaultOpenSubmenuKeys = (items: IMenuItem[], selectedItemKey: string): string[] => {
    const openSubmenuKeys: string[] = [];
    if (selectedItemKey) {
        const selectedItem = items.find((i) => i.key === selectedItemKey);
        if (selectedItem && selectedItem.parentKey) {
            openSubmenuKeys.push(selectedItem.parentKey);
        }
    }
    return openSubmenuKeys;
};

const getMenuItemElement = (menuItem: IMenuItem) => {
    return {
        key: menuItem.key,
        icon: menuItem.icon,
        label: menuItem.label,
        parent: menuItem.parentKey,
        children: null,
    } as any;
};

const getMenuTree = (items: IMenuItem[]): any[] => {
    const list = items?.map((item) => getMenuItemElement(item));
    const itemsHashTable = new Map(list.map(item => [item.key, item]));
    const tree: IMenuItem[] = [];
    for (let i = 0; i < list.length; i++) {
        let currentItem = list[i];
        if (currentItem.parent) {
            const currentItemParent = itemsHashTable.get(currentItem.parent);
            if (currentItemParent) {
                currentItemParent.children = currentItemParent.children ? [...currentItemParent.children, currentItem] : [currentItem];
            }
        } else {
            tree.push(currentItem);
        }
    }
    return tree;
}