import React from "react";
import { FolderAddOutlined, PlusOutlined } from "@ant-design/icons";
import { IHeaderButton } from "../../models/interfaceModels";
import Button from "antd/lib/button";
import Dropdown from "antd/lib/dropdown/dropdown";

type ComponentProps = IHeaderButton

export const HeaderButton: React.FC<ComponentProps> = React.memo(({ buttonText, clickEvent, dropdownButtons, disabled, icon }) => {
  let buttonElement = <Button disabled={disabled} icon={icon || <PlusOutlined />} onClick={clickEvent}>{buttonText}</Button>
  if (dropdownButtons && dropdownButtons?.length > 0) {
      const menuProps = {
        items: dropdownButtons.map((button) => ({
          onClick: button.clickEvent,
          key: button.key,
          label: button.buttonText,
          icon: button.icon || <FolderAddOutlined />,
        }))
    };
      buttonElement = <Dropdown.Button disabled={disabled} menu={menuProps} onClick={clickEvent}><PlusOutlined /> {buttonText}</Dropdown.Button>
  }
  return buttonElement;
});