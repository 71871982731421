import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { IRelatedResource } from "models/relatedResourceModels";
import { ColibriSourceItemType } from "models/treeModels";
import { baseQueryWithReauth } from "../baseQueryWithReauth";

export const relatedResourcesApi = createApi({
    reducerPath: 'colibri/api/relatedResources',
    baseQuery: baseQueryWithReauth({
        baseUrl: '/api/v1/',
    }),
    endpoints: (build) => ({
        getRelatedObjects: build.query<IRelatedResource[], { resourceType: ColibriSourceItemType; resourceId: string; relatedType: ColibriSourceItemType }>({
            query: ({ resourceType, resourceId, relatedType }) => `resourcetypes/${resourceType}/resources/${resourceId}/related/${relatedType}`,
        }),
    }),
});

export const {
    useGetRelatedObjectsQuery
} = relatedResourcesApi;