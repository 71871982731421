import Button from 'antd/es/button'
import Result from 'antd/es/result'
import Skeleton from 'antd/es/skeleton'
import { AntiforgeryForm } from 'features/antiforgery/AntiforgeryForm'
import { useGetUserTeamsQuery } from 'features/user.api'
import React from 'react'

export const ChooseTeam: React.FC = React.memo(() => {
    const { data: userTeams, isFetching } = useGetUserTeamsQuery()

    return <Result
        title='Выбор команды'                
        icon={null}
        style={{ maxWidth: 400, margin: '0 auto' }}
        extra={<>
            {isFetching && <Skeleton active={true} paragraph={{rows: 2, width: '100%'}} />}
            {!isFetching && userTeams?.map(t=>(
                <AntiforgeryForm style={{width: '100%'}} action={`/teams/${t.id}/choose`} method='POST'>
                    <Button key={t.id} style={{width: '100%'}} type='text' htmlType='submit'>{t.name}</Button>
                </AntiforgeryForm>
            ))}
        </>} />
})