import { useGetColibriEnvironmentQuery } from 'app/store/colibri/environment.api';
import { showError } from 'helpers/messageHelpers';


export const useOpenColibriTab = (): (path: string) => void => {
    const { data: colibriEnv } = useGetColibriEnvironmentQuery();

    return (path: string) => {
        if (colibriEnv?.colibriHost) {
            const url = new URL(colibriEnv.colibriHost + path);
            window.open(url, "_blank", 'noopener');
        } else {
            showError();
            console.error("Адрес сервера приложения Колибри не определен!");
        }
    };
};