import Modal from "antd/lib/modal";
import React, { useState, useEffect } from "react";
import Button from "antd/lib/button";
import Space from "antd/lib/space";
import { useActions, useAppSelector } from "app/hooks/hooks";
import { selectPermissionItemOptions } from "./permissionSlice";
import { PermissionsTable } from "./PermissionsTable";
import { IPermission, PermissionChange } from "models/appModels";
import { useLazyGetResourcePermissionsQuery, useSetResourcePermissionsMutation } from "app/store/colibri/permissions.api";
import { showError, showSuccess } from "helpers/messageHelpers";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

export const PermissionsControl: React.FC = React.memo(() => {

    const permissionItemOptions = useAppSelector(selectPermissionItemOptions);
    const [fetchPermissions, { isFetching, isError }] = useLazyGetResourcePermissionsQuery();
    const [savePermissionsRequest, { isLoading: isPermissionSaving }] = useSetResourcePermissionsMutation();
    const { hidePermissionsWindow } = useActions();

    const [changes, setChanges] = useState<PermissionChange[]>([]);
    const [sourceData, setSourceData] = useState<IPermission[]>([]);
    const [permissionRows, setPermissionRows] = useState<IPermission[]>([]);

    useEffect(() => {
        (async () => {
            if (permissionItemOptions && permissionItemOptions.itemType) {
                await fetchPermissions({
                    resourceId: permissionItemOptions.itemId || null,
                    resourceType: permissionItemOptions.itemType,
                })
                .unwrap()
                .then((result) => setSourceData(result))
                .catch((error: FetchBaseQueryError) => {
                    setSourceData([]);
                    hidePermissionsWindow();
                    showError(error.data as string || undefined);
                });
            } else {
                setSourceData([]);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissionItemOptions, fetchPermissions]);
        
    useEffect(() => {
        const roles = sourceData?.filter((row) => row.type === "role")
            .sort((a, b) => a.identity.toLocaleLowerCase() > b.identity.toLocaleLowerCase() ? 1 : -1) || [];
        const users = sourceData?.filter((row) => row.type === "user")
            .sort((a, b) => a.identity.toLocaleLowerCase() > b.identity.toLocaleLowerCase() ? 1 : -1) || [];
        setPermissionRows([...roles, ...users]);
    }, [sourceData]);

    const onPermissionChanged = (tableRow: IPermission, prop: "r" | "w", value: boolean): void => {
        const sourceRow = sourceData.find((row) => row.identity === tableRow.identity);
        if (!sourceRow) {
            return showError();
        }

        const newTableRowState = { ...tableRow, [prop]: value };
        if (prop === "r" && !value && newTableRowState.w) { // Если пользователь снимает разрешение на чтение - необходимо убрать разрешение на изменение
            newTableRowState.w = false;
        }
        if (prop === "w" && value && !newTableRowState.r) { // Если пользователь ставит разрешение на изменения - необходимо поставить разрешение на чтение
            newTableRowState.r = true;
        }
        setPermissionRows(permissionRows.map((row) => row.identity === newTableRowState.identity ? newTableRowState : row));

        const hasChanges = newTableRowState.r !== sourceRow.r || newTableRowState.w !== sourceRow.w; // Если пользователь своими манипуляциями привёл строку в исходный вид - true

        const existingChangeIndex = changes.findIndex(c => c.identity === newTableRowState.identity);
        const change: PermissionChange = {
            identity: newTableRowState.identity,
            ir: sourceRow.ir,
            iw: sourceRow.iw,
            r: newTableRowState.r,
            w: newTableRowState.w,
        };

        if (existingChangeIndex === -1) { //Если информации об изменениях в строке нет - добавим объект изменений
            setChanges([...changes, change]);
        } else if (existingChangeIndex !== -1 && hasChanges) { // Если информация об изменениях есть - будем обновлять существующий объект
            setChanges(changes.map((obj) => obj.identity === change.identity ? { ...change } : obj));
        } else if (existingChangeIndex !== -1 && !hasChanges) {
            setChanges(changes.filter((obj) => obj.identity !== change.identity));
        }
    };

    const savePermissions = async (changes: PermissionChange[]) => {
        if (permissionItemOptions && changes && changes.length > 0) {
            savePermissionsRequest({ 
                resourceType: permissionItemOptions.itemType!,
                resourceId: permissionItemOptions.itemId || null,
                premissions: changes,
            })
            .unwrap()
            .then(() => {
                hidePermissionsWindow();
                showSuccess("Разрешения успешно изменены!");
            })
            .catch((error: FetchBaseQueryError) => {
                showError(error?.data ? error.data as string : undefined);
            });
        } else {
            showError();
        }
    };

    const onModalClosed = (): void => {
        setChanges([]);
        setPermissionRows([]);
    };

    return <Modal
        open={!!permissionItemOptions}
        width={800}
        title={`Разрешения для "${permissionItemOptions?.itemName}"`}
        footer={[<Space key='controls'>
            <Button 
                disabled={!changes || changes?.length === 0}
                onClick={() => savePermissions(changes)} type="primary"
                loading={isPermissionSaving}
            >
                Сохранить
            </Button>
            <Button onClick={hidePermissionsWindow} type='default'>Закрыть</Button>
        </Space>]}
        closable
        destroyOnClose
        afterClose={onModalClosed}
        onCancel={hidePermissionsWindow}
        bodyStyle={{ minHeight: 300, display: 'flex', flexDirection: 'column', maxHeight: 520, overflowY: "auto" }}
    >
        {!isError && <PermissionsTable
            permissionRows={permissionRows}
            resourceId={permissionItemOptions?.itemId || null}
            isLoading={isFetching}
            permissionChangeEvent={onPermissionChanged} />
        }
    </Modal>
});