import Button from "antd/lib/button";
import Result from "antd/lib/result";
import Typography from "antd/lib/typography";
import { useAppSelector } from "app/hooks/hooks";
import { selectAllPages } from "app/store/pages/interfaceSlice";
import React from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const { Text, Title } = Typography;

type ComponentProps = {
    title?: string;
    text?: string;
    buttonText?: string;
    onButtonClick?: () => void;
}

export const NotFoundContent: React.FC<ComponentProps> = React.memo(({
    title,
    text,
    buttonText,
    onButtonClick
}) => {
    const pages = useAppSelector(selectAllPages);
    const mainPage = useMemo(() => pages.find((p) => p.key === "home"), [pages]);
    const navigate = useNavigate();
    const message = (<>
        <Title level={5}>{title || "Такой страницы не существует."}</Title>
        <Text>{text || "Пожалуйста, вернитесь на главную страницу или обратитесь к системному администратору, если считаете, что страница должна быть здесь..."}</Text>
    </>);
    return (
        <Result
            status="404"
            title="404"
            subTitle={message}
            extra={
                <Button 
                    type="primary"
                    onClick={onButtonClick ? onButtonClick : () => navigate(mainPage ? mainPage.path : "/")}
                >
                    {buttonText || "На главную"}
                </Button>
            }
        />
    )
});