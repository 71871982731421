import Button from 'antd/lib/button'
import Result from 'antd/lib/result'
import Spin from 'antd/lib/spin'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { PublishedLinkExamples } from '../PublishedLinkExamples'
import { IPublishedLink } from '../PublishWizardForm'
import { StepButtons } from '../controls/StepButtons'
import { usePublishMutation } from 'app/store/colibri/publications.api'
import Paragraph from 'antd/lib/typography/Paragraph'

export const PublishResultStep: React.FC = React.memo(() => {
    const { getValues } = useFormContext<IPublishedLink>()

    // не самое лучшее решение, нужно по клику на кнопке с предыдущего шага запускать публикацию
    const [publish, { isLoading, isSuccess, isError, error, data: publishedLink }] = usePublishMutation()
    let errorMessage: string | undefined;
    if (error && 'data' in error)
        errorMessage = (error.data as any)?.toString()

    useEffect(() => {
        const publishedLink = getValues();
        if (publishedLink?.objectType === "analysis.dashboard" && !publishedLink.accessType) {
            publishedLink.accessType = "NoCache";
        }
        publish(publishedLink)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const successMessage = publishedLink?.objectType === 'analysis.dashboard' 
        ? 'Дэшборд успешно опубликован'
        : publishedLink?.objectType === 'reporting.template'
        ? 'Отчет успешно опубликован'
        : null

    return <>
        {isLoading && <Result
            style={{padding: 4}}
            status="info"
            title="Выполняется публикация..."
            subTitle={<Spin size='large' />}
        />}
        {isError && <Result            
            style={{padding: 4}}
            status="error"
            title="При попытке публикации произошла ошибка"
            subTitle={<Paragraph style={{maxHeight: 400, overflowY: 'auto'}} ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
                {errorMessage}
            </Paragraph>}
            extra={<>
                <Button onClick={() => publish(getValues())} loading={isLoading}>Повторить</Button>
            </>} />}
        {isSuccess && <Result
            style={{padding: 4}}
            status="success"
            title={successMessage}
        >
            <PublishedLinkExamples publishedLink={publishedLink!} />
        </Result>}
        <StepButtons showBack={isError} nextProps={{ type: 'primary' }} nextText='Закрыть' />
    </>
})