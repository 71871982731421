import message from "antd/lib/message";

export const showError = (text?: string, duration?: number): void => {
  const msg = text || "Произошла непредвиденная ошибка! Пожалуйста, повторите попытку или обратитесь к системному администратору.";
  message.error(msg, duration || 15);  
};

export const showSuccess = (text?: string, duration?: number): void => {
  const msg = text || "Изменения успешно сохранены!";
  message.success(msg, duration || 5);
};

export const showInfo = (text: string, duration?: number): void => {
  if (!text) {
    return;
  }
  message.info(text, duration || 10);
};