import React, { useEffect, useState } from "react";
import { useSearchParams, createSearchParams } from "react-router-dom";
import { ItemList } from "../components/controls/ItemList";
import { ItemListHeader } from "../components/controls/ItemListHeader";
import { ICatalogedTreeItem } from "../models/treeModels";
import { findItemsInTreeByName } from "../helpers/treeHelpers";
import { useWorkflowsCatalogTree } from "../app/hooks/useWorkflowsCatalogTree";
import { useDeleteWorkflowMutation, useExportProcessMutation, useGetWorkflowsQuery } from "../app/store/colibri/workflows.api";
import { ApiTwoTone, DeleteOutlined, FileAddOutlined, FileZipOutlined, PlusOutlined, SafetyOutlined, ToolOutlined } from "@ant-design/icons";
import { useActions } from "app/hooks/hooks";
import { useOpenColibriTab } from "app/hooks/useOpenColibriTab";
import { IListAction } from "models/appModels";
import Modal from "antd/lib/modal"

export const WorkflowsPage: React.FC = React.memo(() => {
    const tree = useWorkflowsCatalogTree();
    const { isLoading: isWorkflowsLoading, isFetching: isWorkflowsFetching } = useGetWorkflowsQuery();

    const [currentItems, setCurrentItems] = useState<ICatalogedTreeItem[]>();

    const [searchParams, setSearchParams] = useSearchParams();
    const searchText = searchParams.get("s");

    const [deleteWorkflow, { isLoading: isWorkflowDeleting }] = useDeleteWorkflowMutation();
    const [exportWorkflow, { isLoading: isWorkflowExporting }] = useExportProcessMutation();

    const { showPermissionsWindow, showImportResourceWindow } = useActions();
    const openColibriTab = useOpenColibriTab();

    const isLoading = isWorkflowsLoading || isWorkflowsFetching || isWorkflowDeleting || isWorkflowExporting;


    useEffect(() => {
        if (searchText && tree) {
            setCurrentItems(findItemsInTreeByName(tree, searchText));
        } else {
            setCurrentItems(tree?.items);
        }
    }, [tree, searchText]);

    const onSearch = (value: string): void => {
        if (value) {
            setSearchParams(createSearchParams({ s: value }));
        } else {
            setSearchParams();
        }
    };

    const showDeleteConfirm = (item: ICatalogedTreeItem) => {
        Modal.confirm({
            title: "Необходимо подтверждение",
            icon: <DeleteOutlined />,
            content: <span>Вы уверены, что хотите удалить <b>{item.name}</b>?</span>,
            okText: "Подтвердить",
            onOk: () => deleteWorkflow(item.id),
            cancelText: 'Отмена',
        });
    };


    const listActions: IListAction[] = [
        {
            key: "editor",
            tooltipText: "Открыть в конструкторе",
            icon: <ToolOutlined />,
            onClick: (item) => openColibriTab(`/workflow/edit/${item.id}`),
            specificSourceType: ["workflow.process"],
        }, {
            key: "permissions",
            tooltipText: "Настройка разрешений",
            icon: <SafetyOutlined />,
            accessRoles: ["security-admin"],
            onClick: (item) => showPermissionsWindow(item),
            specificSourceType: ["workflow.process"],
        }, {
            key: "export",
            tooltipText: "Выгрузить в файл",
            icon: <FileZipOutlined />,
            onClick: (item) => exportWorkflow({ id: item.id, name: item.name }),
            specificSourceType: ["workflow.process"],
        }, {
            key: "delete",
            tooltipText: "Удалить",
            icon: <DeleteOutlined />,
            onClick: showDeleteConfirm,
            danger: true,
            specificSourceType: ["workflow.process"],
        }
    ];

    return (
        <div className="bg-white h-full w-full shadow p-8 rounded-lg">
            <ItemListHeader
                currentFolder={tree}
                isSerchPanelEnabled={true}
                onSearch={onSearch}
                buttons={[{
                    key: "open-editor",
                    icon: <PlusOutlined />,
                    buttonText: "Создать",
                    clickEvent: () => openColibriTab("/workflow/new"),
                    disabled: isLoading,
                    accessRoles: ['etl-writer'],
                    dropdownButtons: [{
                        key: "load-file",
                        buttonText: "Загрузить из файла",
                        icon: <FileAddOutlined />,
                        clickEvent: () => showImportResourceWindow({ resourceType: "workflow.process" }),
                    }],
                }]}
            />
            <ItemList
                isLoading={isLoading}
                itemClickEvent={() => true}
                itemActions={listActions}
                listItems={currentItems || []}
                iconRender={(_: ICatalogedTreeItem) => {
                    return <ApiTwoTone className="text-2xl" />
                }}
            />
        </div>
    )
});