import React from "react";
import Drawer from "antd/lib/drawer";
import Typography from "antd/lib/typography";
import Divider from "antd/lib/divider";
import { useActions, useAppSelector } from "app/hooks/hooks";
import { selectRelativesViewerOptions } from "./relativesViewerSlice";
import { RelativesViewerList } from "./RelativesViewerList";

const { Title } = Typography;

export const RelativesViewerControl: React.FC = React.memo(() => {
    const relativesViewerOptions = useAppSelector(selectRelativesViewerOptions);
    const { hideRelativesViewer } = useActions();
    const onDrawerClosed = () => {
        hideRelativesViewer();
    };

    return (<Drawer open={!!relativesViewerOptions} width={640} placement="right" closable={false} onClose={onDrawerClosed} destroyOnClose>
        <div className="drawer-content-wrapper">
            <div className="drawer-header-wrapper">
                <Title level={5}>{relativesViewerOptions?.itemName}</Title>
                <Title style={{ marginTop: 12 }} level={4}>Зависимые объекты</Title>
                <Divider className="mt-3 mb-4" />
            </div>
            {(relativesViewerOptions?.itemId && relativesViewerOptions?.itemType)  && <RelativesViewerList resourceId={relativesViewerOptions.itemId} resourceType={relativesViewerOptions.itemType} />}
        </div>
    </Drawer>);
});