
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { matchPath } from "react-router-dom";
import { RootState } from "..";
import { IPage, IInterfaceState, IMenuItem } from "../../../models/interfaceModels";

const initialState: IInterfaceState = {
    pages: [],
    menuItems: [],
};

export const interfaceSlice = createSlice({
    name: "interfaceStore",
    initialState,
    reducers: {
        setPages: (state, action: PayloadAction<IPage[]>) => {
            state.pages = action.payload;
        },
        addPage: (state, action: PayloadAction<IPage>) => {
            if (state.pages?.findIndex((p) => p.key === action.payload?.key) === -1) { // add only if there is no page with the same key
                state.pages.push(action.payload);
            }
        },
        setMenuItems: (state, action: PayloadAction<IMenuItem[]>) => {
            state.menuItems = action.payload;
        },
        addMenuItem: (state, action: PayloadAction<IMenuItem>) => {
            if (state.menuItems?.findIndex((i) => i.key === action.payload?.key) === -1) { // add only if there is no menu item with the same key
                state.menuItems.push(action.payload);
            }
        },
    }
});

const selectPages = (state: RootState) => state.interfaceStore.pages;
const selectMenuItems = (state: RootState) => state.interfaceStore.menuItems;

export const selectAllPages = createSelector(
    selectPages,
    result => result || []
);

export const selectAllMenuItems = createSelector(
    selectMenuItems,
    result => result || []
);

export const selectCurrentPage = createSelector(
    selectPages,
    (state: RootState, pathname: string) => pathname,
    (pages, pathname) => pages?.find(p => matchPath(p.path, pathname)) || null
);


export const interfaceActions = interfaceSlice.actions;
export const interfaceReducer = interfaceSlice.reducer;