import React, { PropsWithChildren } from 'react'
import { IPublishedLink } from './PublishWizardForm'
import { PublishWizardProvider, StepDefinition } from './PublishWizardProvider';
import { AuthStep } from './steps/AuthStep';
import { PublishResultStep } from './steps/PublishResultStep';

export const DashboardPublishWizardProvider: React.FC<PropsWithChildren<{
    publishedLink: Partial<IPublishedLink> & { objectId: string; };
    onStepsChange: (sortedStepDefinitions: StepDefinition[]) => void;
    onStepChange: (step: string) => void;
    onComplete: (link: IPublishedLink) => void;
    controlsTarget: HTMLElement | null;
}>> = React.memo(({
    publishedLink,
    onStepsChange,
    onStepChange,
    onComplete,
    controlsTarget,
    children
}) => {
    return <PublishWizardProvider
        publishedLink={publishedLink}
        defaultStepDefinitions={{            
            auth: {
                displayName: 'Права доступа',
                element: <AuthStep />
            },            
            result: {
                displayName: 'Результат',
                element: <PublishResultStep />
            }
        }}
        defaultCalculateSteps={data => [           
            'auth',            
            'result'
        ].filter(Boolean) as string[]}
        defaultStep='auth'
        onStepsChange={onStepsChange}
        onStepChange={onStepChange}
        onComplete={onComplete}
        controlsTarget={controlsTarget}>
        {children}
    </PublishWizardProvider>
})