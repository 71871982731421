import { bindActionCreators } from '@reduxjs/toolkit';
import { importResourceActions } from 'features/importResource/importResourceSlice';
import { permissionActions } from 'features/permissions/permissionSlice';
import { relativesViewerActions } from 'features/relativesViewer/relativesViewerSlice';
import { sessionActions } from 'features/session/sessionSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { publishingActions } from 'features/publishing/publishingSlice';
import type { AppDispatch, RootState } from '../store';
import { interfaceActions } from '../store/pages/interfaceSlice';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const actions = {
  ...interfaceActions,
  ...publishingActions,
  ...permissionActions,
  ...relativesViewerActions,
  ...importResourceActions,
  ...sessionActions
};

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
}