import React, { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import { LayoutComponent } from './components/LayoutComponent';
import { useGetIsAuthenticatedQuery, useGetUserInfoQuery } from './features/user.api';
import { SignInPage } from './pages/SignInPage';
import { setTwoToneColor } from '@ant-design/icons';
import { LoadingScreen } from 'components/LoadingScreen';
import { useGetCsrfTokenQuery } from 'features/antiforgery/antiforgery.api';
import { ChooseTeam } from 'features/teams/ChooseTeam';
import { HeaderComponent } from 'components/HeaderComponent';
import Layout from 'antd/es/layout';

setTwoToneColor("#8d6ed6");
const App: React.FC = React.memo(() => {
    useEffect(() => {
        const version = process.env.REACT_APP_VERSION // Можно отобразить где-нибудь и контролировать версию через package.json
        console.debug('Версия клиента: ' + version)
    }, [])

    const { data: isAuthenticated, isLoading: isAuthenticatedLoading } = useGetIsAuthenticatedQuery()
    const { isLoading: isUserLoading, data: user } = useGetUserInfoQuery(undefined, { skip: !isAuthenticated })
    const { isLoading: isCsrfTokenLoading } = useGetCsrfTokenQuery(undefined, { skip: !isAuthenticated })
    const isLoading = isAuthenticatedLoading || isUserLoading || isCsrfTokenLoading

    if (isLoading)
        return (<LoadingScreen />)
    else if (!isAuthenticated)
        return (<SignInPage />)
    else if (user && user.teamIds.length && !user.team)
        return <Layout style={{ height: '100%' }}>
            <HeaderComponent />
            <ChooseTeam />  
        </Layout>
    
    return <Routes>
        <Route path='/*' element={<LayoutComponent />} />
    </Routes>    
});

export default App;
