import Typography from "antd/lib/typography";
import { useOpenColibriTab } from "app/hooks/useOpenColibriTab";
import { useGetUserInfoQuery } from "features/user.api";
import { showError } from "helpers/messageHelpers";
import { IMenuItem } from "models/interfaceModels";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks/hooks";
import { selectAllMenuItems, selectAllPages } from "../app/store/pages/interfaceSlice";

const { Title } = Typography;

export const MainPage: React.FC = React.memo(() => {
    const { data: userInfo } = useGetUserInfoQuery(undefined, { refetchOnFocus: true });
    const pages = useAppSelector(selectAllPages);
    const menuItems = useAppSelector(selectAllMenuItems);

    const openColibriTab = useOpenColibriTab();

    const navigate = useNavigate();

    const onMenuItemClick = (menuItem: IMenuItem) => {
        if (menuItem.pageKey) {
            const linkedPage = pages?.find((page) => page.key === menuItem.pageKey);
            const pagePath = linkedPage?.path?.replaceAll("/*", "");
            if (!pagePath) {
                return showError();
            }
            return navigate(pagePath);
        } else if (menuItem.key === "etl") {
            openColibriTab("/workflow");
        } else if (menuItem.key === "security") {
            openColibriTab("/security");
        }
    };

    return (
        <div className="container">
            <div className="pt-8 pb-4">
                <Title level={1} className="font-gilroy-bold">Добро пожаловать, {userInfo?.userName}!</Title>
            </div>
            <div className="grid xl:grid-cols-5 lg:grid-cols-3 sm:grid-cols-2 gap-8 mx-auto mt-8">
                {menuItems.map(item => item.showOnMain && <div
                    key={item.key}
                    className="shadow-md hover:shadow-lg aspect-square cursor-pointer w-full bg-white hover:bg-gray-200 hover:shadow-xl rounded-lg"
                    onClick={() => onMenuItemClick(item)}
                >
                    <div className="grid grid-rows-[max-content_max-content_auto] gap-3 p-6 h-full text-gray-800">
                        <div className="self-end text-6xl text-colibri-purple">{item.icon}</div>
                        <div className="text-xl mt-2 font-gilroy-bold">{item.label}</div>
                        <div className="font-gilroy-regular">{item.description}</div>
                    </div>
                </div>)}
            </div>
        </div>
    )
});