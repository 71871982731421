import Modal from "antd/lib/modal";

export const useShowAboutModal = (): () => void => {
    const version = process.env.REACT_APP_VERSION;
    return (): void => {
        Modal.info({
            title: "О программе",
            width: 590,
            content: (<div>
                <h4>Модуль отчётности Единой Цифровой Платформы Республики Татарстан</h4>
                <div className="text-sm">Версия приложения: <strong>{version}</strong></div>
                <div className="text-sm">Разработано на основе семейства программных продуктов Колибри</div>
                <div className="text-sm mt-1">
                    <a href="https://octonica.ru/" target="_blank" rel="noreferrer">Группа Компаний "Октоника"</a>
                </div>
            </div>),
        });
    }
};