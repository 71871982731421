import Alert from 'antd/lib/alert';
import Button from 'antd/lib/button';
import Spin from 'antd/lib/spin';
import { useGetTemplateParametersQuery } from 'app/store/colibri/reporting.api';
import React, { PropsWithChildren } from 'react'
import { IPublishedLink } from './PublishWizardForm'
import { PublishWizardProvider, StepDefinition } from './PublishWizardProvider';
import { AccessTypeStep } from './steps/AccessTypeStep';
import { AuthStep } from './steps/AuthStep';
import { OutputStep } from './steps/OutputStep';
import { ParametersStep } from './steps/ParametersStep';
import { PublishResultStep } from './steps/PublishResultStep';
import { ScheduleStep } from './steps/ScheduleStep';

export const ReportTemplatePublishWizardProvider: React.FC<PropsWithChildren<{
    publishedLink: Partial<IPublishedLink> & { objectId: string; };
    onStepsChange: (sortedStepDefinitions: StepDefinition[]) => void;
    onStepChange: (step: string) => void;
    onComplete: (link: IPublishedLink) => void;
    controlsTarget: HTMLElement | null;
}>> = React.memo(({
    publishedLink,
    onStepsChange,
    onStepChange,
    onComplete,
    controlsTarget,
    children
}) => {
    const { data: parameters, error, refetch, isFetching } = useGetTemplateParametersQuery(publishedLink.objectId)

    if (!parameters)
        return <Spin />

    if (error)
        return <Alert
            message='Ошибка получения параметров шаблона'
            description={'data' in error ? (error.data as any)?.toString() : 'message' in error ? (error.message?.toString()) : undefined}
            action={<Button onClick={refetch} loading={isFetching}>Повторить загрузку</Button>} />

    return <PublishWizardProvider
        publishedLink={publishedLink}
        defaultStepDefinitions={{
            accessType: {
                displayName: 'Режим доступа',
                element: <AccessTypeStep />
            },
            schedule: {
                displayName: 'Расписание',
                element: <ScheduleStep />
            },
            parameters: {
                displayName: 'Параметры отчета',
                element: <ParametersStep />
            },
            auth: {
                displayName: 'Права доступа',
                element: <AuthStep />
            },
            outputType: {
                displayName: 'Режим публикации',
                element: <OutputStep />
            },
            result: {
                displayName: 'Результат',
                element: <PublishResultStep />
            }
        }}
        defaultCalculateSteps={data => [
            'accessType',
            data.accessType === 'Scheduled' && 'schedule',
            data.accessType === 'NoCache' && parameters?.length > 0 && 'parameters',            
            'auth',
            'outputType',
            'result'
        ].filter(Boolean) as string[]}
        defaultStep='accessType'
        onStepsChange={onStepsChange}
        onStepChange={onStepChange}
        onComplete={onComplete}
        controlsTarget={controlsTarget}>
        {children}
    </PublishWizardProvider>
})