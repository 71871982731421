import { FormItemProps } from "antd/lib/form/FormItem";
import { useEffect, useRef } from "react";
import { ControllerFieldState } from "react-hook-form";

export const validationFeedback = (fieldState: ControllerFieldState): Partial<FormItemProps> => {
    return {
        status: fieldState.error ? 'error' : undefined,
        validateStatus: fieldState.error ? 'error' : undefined,
        help: fieldState.error?.message
    }
}

export const useAutoFocus = () => {
    const inputRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return inputRef;
};