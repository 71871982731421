import { useAppSelector } from "../../app/hooks/hooks"
import { ITemplate } from "../../models/reportingModels"
import { selectTemplateById } from "../../app/store/colibri/reporting.api"
import { selectDashboardById } from "../../app/store/colibri/analysis.api"
import { IDashboard } from "models/analysisModels"

export const usePublishingObject = () => {
    const publishingObject = useAppSelector(s => {
        const { objectId, objectType } = s.publishing
        let data: ITemplate | IDashboard | undefined
        if (objectType === 'reporting.template' && objectId)
            data = selectTemplateById(s, objectId)
        if (objectType === 'analysis.dashboard' && objectId)
            data = selectDashboardById(s, objectId)

        if (data && objectId && objectType)
            return {
                objectId, objectType, data
            }
        return null
    })

    return publishingObject
}