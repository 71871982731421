import { UnlockFilled } from "@ant-design/icons";
import { Background } from "components/Background";
import React from "react";
import colibriLogo from "../images/logo.svg";

export const SignInPage: React.FC = React.memo(() => {
    return (
        <div className="grid grid-cols-2 h-full">
            <Background>
                <div className="flex items-center justify-center h-full">
                    <div className="text-center">
                        <img alt="logo" width={220} src={colibriLogo} />
                        <div className="text-white text-6xl font-gilroy-bold mt-7">
                            КОЛИБРИ
                        </div>
                    </div>
                </div>
            </Background>
            <div className="bg-gradient-to-r from-gray-100 to-gray-300 flex flex-col justify-center gap-y-10 rounded-l-xl">
                <div className="text-center text-4xl text-gray-800">
                    <div className="font-gilroy-bold">Модуль отчётности</div>
                    <div className="my-4 font-gilroy-regular">Единой Цифровой Платформы</div>
                    <div className="font-gilroy-regular">Республики Татарстан</div>
                </div>
                <div className="flex flex-col content-center gap-y-5">
                    <p className="text-center font-gilroy-regular text-xl text-gray-800">Для получения доступа необходимо пройти процедуру аутентификации</p>
                    <div className="text-center">
                        <div
                            onClick={() => window.location.href = '/auth/login'}
                            className="text-white bg-colibri-purple hover:bg-colibri-purple-dark font-gilroy-regular rounded-lg text-xl px-5 py-2.5 text-center inline-flex items-center cursor-pointer"
                        >
                            <UnlockFilled />
                            <span className="pl-2">Аутентификация</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});