import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { IPermission, PermissionChange } from "models/appModels";
import { ColibriSourceItemType } from "models/treeModels";
import { baseQueryWithReauth } from "../baseQueryWithReauth";

export const permissionsApi = createApi({
    reducerPath: 'colibri/api/permissions',
    baseQuery: baseQueryWithReauth({
        baseUrl: '/api/v1/',
    }),
    tagTypes: ["Permissions"],
    endpoints: (build) => ({
        checkCanWriteResource: build.query<boolean, { resourceType: ColibriSourceItemType, resourceId: string }>({
            providesTags: ["Permissions"],
            query: ({ resourceType, resourceId }) => `resourcetypes/${resourceType}/resources/${resourceId}/canwrite`,
        }),
        getResourcePermissions: build.query <IPermission[], { resourceType: ColibriSourceItemType; resourceId: string | null }>({
            query: ({ resourceType, resourceId }) => resourceId ? `resourcetypes/${resourceType}/resources/${resourceId}/permissions` : `resourcetypes/${resourceType}/permissions`,
            providesTags: ["Permissions"],
            transformResponse: (response: IPermission[]) => {
                return response.map((p) => ({ 
                    ...p,
                    type: p.user ? "user" : "role",
                    identity: p.user ? p.user.login : p.role!.name,
                }));
            },
        }),
        setResourcePermissions: build.mutation<void, { resourceType: string; resourceId: string | null; premissions: PermissionChange[] }>({
            query: ({ resourceType, resourceId, ...params }) => ({
                url: resourceId ? `resourcetypes/${resourceType}/resources/${resourceId}/permissions` : `resourcetypes/${resourceType}/permissions`,
                method: "POST",
                body: params.premissions,
            }),
        }),
    }),
});

export const {
    useGetResourcePermissionsQuery,
    useLazyGetResourcePermissionsQuery,
    useSetResourcePermissionsMutation,
    useCheckCanWriteResourceQuery
} = permissionsApi;