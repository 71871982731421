import React, { useRef, useState, useEffect } from "react";
import { useGetTemplateVersionQuery } from "../app/store/colibri/reporting.api";
import { ITemplateVersion } from "../models/reportingModels";
import { ICatalogedTreeItem } from "../models/treeModels";
import InfiniteScroll from 'react-infinite-scroll-component';
import { parseAndFormatDateFromString } from "../helpers/dateHelpers";
import useResizeObserver from "@react-hook/resize-observer";
import "../styles/customScrollbar.css";
import { ToolOutlined } from "@ant-design/icons";
import Drawer from "antd/lib/drawer";
import Divider from "antd/lib/divider";
import List from "antd/lib/list";
import Spin from "antd/lib/spin";
import Tooltip from "antd/lib/tooltip";
import Button from "antd/lib/button";
import Typography from "antd/lib/typography";

const { Title } = Typography;

type ComponentProps = {
    isOpen: boolean;
    item: ICatalogedTreeItem;
    onEditClick: (verison: ITemplateVersion) => void;
    onClose: () => void;
}

export const ReportingTemplatesVersionsDrawerComponent: React.FC<ComponentProps> = React.memo(({
    isOpen,
    item,
    onEditClick,
    onClose,
}) => {
    const limit = useRef<number>(20);
    const [offset, setOffset] = useState<number>(0);

    const [noMoreVersions, setNoMoreVersions] = useState(false);
    const [versions, setVersions] = useState<ITemplateVersion[]>([]);

    const { data: currentScopeVersions, isLoading, isFetching, isError, refetch } = useGetTemplateVersionQuery({
        templateId: item.id,
        offset,
        limit: limit.current
    }, {
        skip: noMoreVersions
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => refetch(), []);
    //--- Versions list height calculation
    const [contentSize, setContentSize] = useState<DOMRectReadOnly>();
    const [headerSize, setHeaderSize] = useState<DOMRectReadOnly>();
    const drawerContentRef = useRef<HTMLDivElement>();
    const drawerHeaderRef = useRef<HTMLDivElement>();

    const [listHeight, setListHeight] = useState<number | undefined>(undefined);

    useResizeObserver(drawerContentRef.current || null, (entry) => setContentSize(entry.contentRect));
    useResizeObserver(drawerHeaderRef.current || null, (entry) => setHeaderSize(entry.contentRect));

    useEffect(() => {
        if (contentSize && headerSize) {
            setListHeight(contentSize.height - headerSize.height - 24); //24 is a padding
        }
    }, [contentSize, headerSize])

    // ---



    useEffect(() => {
        if (currentScopeVersions?.length) {
            const existingVersions = [...versions];
            currentScopeVersions.forEach((v) => { // exclude duplicates if they are for any reason...
                if (existingVersions.findIndex((ev) => ev.id === v.id) === -1) {
                    existingVersions.push(v);
                }
            });
            setVersions(existingVersions);
            currentScopeVersions.length < limit.current && setNoMoreVersions(true);
        } else if (offset > 0) {
            setNoMoreVersions(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentScopeVersions]);

    useEffect(() => {
        loadMoreData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadMoreData = () => {
        if (!noMoreVersions && !isLoading && !isFetching) {
            setOffset(offset + limit.current);
        }
    };
    return (<Drawer width={500} placement="right" closable={false} onClose={onClose} open={isOpen}>
        <div className="drawer-content-wrapper h-full" ref={drawerContentRef as any}>
            <div className="drawer-header-wrapper" ref={drawerHeaderRef as any}>
                <Title level={5}>{item.name}</Title>
                <Title style={{ marginTop: 12 }} level={4}>История версий</Title>
                <Divider className="mt-3 mb-4" />
            </div>
            {listHeight && <InfiniteScroll
                className="use-custom-scrollbar"
                dataLength={versions?.length || 0}
                next={loadMoreData}
                height={listHeight}
                hasMore={!noMoreVersions}
                loader={!isError && <div className="flex items-center justify-center"><Spin /></div>}
                endMessage={<Divider plain>Версий больше нет</Divider>}
            >
                <List
                    className="px-3"
                    dataSource={versions || []}
                    renderItem={item => (
                        <List.Item key={item.id}>
                            <List.Item.Meta
                                title={`Автор: ${item.editor || "Нет данных"} | Дата: ${parseAndFormatDateFromString(item.modified)}`}
                                description={`Уникальный номер версии: ${item.version}`}
                            />
                            <Tooltip title="Открыть в конструкторе" placement="left">
                                <Button icon={<ToolOutlined />} size="small" onClick={() => onEditClick(item)} />
                            </Tooltip>
                        </List.Item>
                    )}
                />
            </InfiniteScroll>}
        </div>
    </Drawer>);
});