import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { ColibriSourceItemType } from "models/treeModels";

interface IResourceImportState {
    resourceType: ColibriSourceItemType | null;
    folderId?: string | null;
};

const initialState: IResourceImportState = {
    resourceType: null,
    folderId: null,
};

export const importResourceSlice = createSlice({
    name: 'importResourceStore',
    initialState,
    reducers: {
        showImportResourceWindow: (state, action: PayloadAction<IResourceImportState>) => {
            state.resourceType = action.payload.resourceType || null;
            state.folderId = action.payload.folderId || null;
        },
        hideImportResourceWindow: (state) => {
            state.resourceType = null;
            state.folderId = null;
        },
    },
});

const selectOptions = (state: RootState) => state.importResourceStore;

export const selectImportResourceOptions = createSelector(
    selectOptions,
    result => result.resourceType ? result : null
);

export const importResourceActions = importResourceSlice.actions;
export const importResourceReducer = importResourceSlice.reducer;