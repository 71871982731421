import React, { useEffect, useState } from "react";
import { useSearchParams, createSearchParams, useNavigate } from "react-router-dom";
import { ItemList } from "../components/controls/ItemList";
import { ItemListHeader } from "../components/controls/ItemListHeader";
import { ICatalogedTreeItem } from "../models/treeModels";
import { findItemsInTreeByName } from "../helpers/treeHelpers";
import { DashboardTwoTone, DeleteOutlined, EyeOutlined, FileAddOutlined, FileZipOutlined, GlobalOutlined, PlusOutlined, SafetyOutlined, ToolOutlined } from "@ant-design/icons";
import { useDeleteDashboardMutation, useExportDashboardMutation, useGetDashboardsQuery, useUpdateDashboardMutation } from "../app/store/colibri/analysis.api";
import { useDashboardsCatalogTree } from "../app/hooks/useDashboardsCatalogTree";
import { IListAction } from "models/appModels";
import { useOpenColibriTab } from "app/hooks/useOpenColibriTab";
import { useActions } from "app/hooks/hooks";
import { IDashboard } from "models/analysisModels";
import Modal from "antd/lib/modal";
import Tooltip from "antd/lib/tooltip";
import Badge from "antd/lib/badge";

export const DashboardsPage: React.FC = React.memo(() => {

    const tree = useDashboardsCatalogTree();

    const { isLoading: isDashboardsLoading, isFetching: isDashboardsFetching } = useGetDashboardsQuery();
    const [updateDashboard, { isLoading: isDashboardUpdating }] = useUpdateDashboardMutation();
    const [deleteDashboard, { isLoading: isDashboardDeleting }] = useDeleteDashboardMutation();
    const [exportDashboard, { isLoading: isDashboardExporting }] = useExportDashboardMutation();
    
    const isLoading = isDashboardsLoading || isDashboardsFetching || isDashboardUpdating || isDashboardDeleting || isDashboardExporting;

    const [currentItems, setCurrentItems] = useState<ICatalogedTreeItem[]>();

    const [searchParams, setSearchParams] = useSearchParams();
    const searchText = searchParams.get("s");

    const openColibriTab = useOpenColibriTab();


    useEffect(() => {
        if (searchText && tree) {
            setCurrentItems(findItemsInTreeByName(tree, searchText));
        } else {
            setCurrentItems(tree?.items);
        }
    }, [tree, searchText]);

    const onSearch = (value: string): void => {
        if (value) {
            setSearchParams(createSearchParams({ s: value }));
        } else {
            setSearchParams();
        }
    };

    const { showPublisher, showPermissionsWindow, showImportResourceWindow } = useActions();

    const showDeleteConfirm = (item: ICatalogedTreeItem) => {
        Modal.confirm({
            title: "Необходимо подтверждение",
            icon: <DeleteOutlined />,
            content: <span>Вы уверены, что хотите удалить <b>{item.name}</b>?</span>,
            okText: "Подтвердить",
            onOk: () => deleteDashboard(item.id),
            cancelText: 'Отмена',
        });
    };

    const listActions: IListAction[] = [
        {
            key: "preview",
            tooltipText: "Предпросмотр",
            icon: <EyeOutlined />,
            onClick: (item) => openColibriTab(`/dashboard/${item.id}`),
            specificSourceType: ["analysis.dashboard"],
        }, {
            key: "editor",
            tooltipText: "Открыть в конструкторе",
            icon: <ToolOutlined />,
            onClick: (item) => openColibriTab(`/dashboard/edit/${item.id}`),
            specificSourceType: ["analysis.dashboard"],
        }, {
            key: "publish",
            tooltipText: "Настройка публикации",
            icon: <GlobalOutlined />,
            onClick: (dashboard) => showPublisher({ objectId: dashboard.id, objectType: 'analysis.dashboard' }),
            specificSourceType: ["analysis.dashboard"],
        }, {
            key: "permissions",
            tooltipText: "Настройка разрешений",
            icon: <SafetyOutlined />,
            onClick: (item) => showPermissionsWindow(item),
            accessRoles: ["security-admin"],
            specificSourceType: ["analysis.dashboard"],
        }, {
            key: "export",
            tooltipText: "Выгрузить в файл",
            icon: <FileZipOutlined />,
            onClick: (item) => exportDashboard({ id: item.id, name: item.name }),
            specificSourceType: ["analysis.dashboard"],
        }, {
            key: "delete",
            tooltipText: "Удалить",
            icon: <DeleteOutlined />,
            onClick: showDeleteConfirm,
            danger: true,
            specificSourceType: ["analysis.dashboard"],
        },
    ];

    const navigate = useNavigate();
    const onItemClicked = (item: ICatalogedTreeItem): void => {
        if (item?.isCatalog) {
            navigate(item.id, {
                replace: false,
                relative: 'path',
            });
        } else {
            openColibriTab(`/dashboard/${item.id}`);
        }
    };

    const onItemChanged = (item: ICatalogedTreeItem, changes: Partial<ICatalogedTreeItem>): void => {
        const params = { id: item.id, ...changes };
        updateDashboard(params);
    };

    const onItemRenamed = (newName: string, item: ICatalogedTreeItem) => {
        if (item.name !== newName) {
            onItemChanged(item, {
                name: newName,
            });
        }
    };

    return (
        <div className="bg-white h-full w-full shadow p-8 rounded-lg">
            <ItemListHeader
                currentFolder={tree}
                isSerchPanelEnabled={true}
                onSearch={onSearch}
                buttons={[{
                    key: "open-editor",
                    icon: <PlusOutlined />,
                    buttonText: "Создать",
                    clickEvent: () => openColibriTab("/dashboard/new"),
                    disabled: isLoading,
                    accessRoles: ['analysis-writer','admin'],
                    dropdownButtons: [{
                        key: "load-file",
                        buttonText: "Загрузить из файла",
                        icon: <FileAddOutlined />,
                        clickEvent: () => showImportResourceWindow({resourceType: "analysis.dashboard"}),
                    }],
                }]}
            />
            <ItemList
                isLoading={isLoading}
                listItems={currentItems || []}
                itemClickEvent={onItemClicked}
                itemActions={listActions}
                itemRenamedEvent={onItemRenamed}
                iconRender={(item: ICatalogedTreeItem) => {
                    if (isPublished(item)) {
                        return (<Tooltip title="Дэшборд опубликован" placement="right">
                            <Badge offset={[-3, 3]} count={<div className="w-[10px] h-[10px] bg-green-500 rounded-full shadow-[0_0_0_2px_rgba(255,255,255,1)]" />}>
                                <DashboardTwoTone className="text-2xl cursor-pointer" onClick={() => showPublisher({ objectId: item.id, objectType: 'analysis.dashboard' })} />
                            </Badge>
                        </Tooltip>)
                    } else {
                        return <DashboardTwoTone className="text-2xl" />
                    }
                }}
            />
        </div>
    )
});

const isPublished = (item: ICatalogedTreeItem): boolean => {
    if (item.sourceType !== "analysis.dashboard") {
        return false;
    }
    const sourceItem = item.sourceItem as IDashboard;
    return !!sourceItem.publishedLinkId;
};