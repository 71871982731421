import Button, { ButtonProps } from 'antd/lib/button'
import Space from 'antd/lib/space';
import React from 'react'
import { createPortal } from 'react-dom';
import { usePublishWizard } from '../PublishWizardProvider';

export const StepButtons: React.FC<{    
    showBack?: boolean;
    showNext?: boolean;
    nextProps?: ButtonProps;
    nextText?: React.ReactNode;
}> = React.memo(({    
    showBack = true,
    showNext = true,
    nextText,
    nextProps,
}) => {
    const { allowBack, goBack, controlsTarget, formEl, activeStep, steps, stepDefinitions } = usePublishWizard()

    const index = steps.indexOf(activeStep)
    const isBeforePublishStep = index === steps.length - 2
    let defaultNextText =  isBeforePublishStep ? 'Опубликовать' : 'Далее >'

    const controls = <Space
        style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end', flex: 1 }}>
        {showBack && <Button
            size='middle'
            style={{ width: 120 }}
            key='back'
            disabled={!allowBack}
            onClick={goBack}
        >{'< Назад'}</Button>}
        {showNext && <Button
            size='middle'
            style={{ width: 120 }}
            key='next'
            type={isBeforePublishStep ? 'primary' : undefined}            
            {...nextProps}
            htmlType='submit'            
            onClick={controlsTarget ? ()=>{ formEl?.submit() } : undefined}
        >{nextText || defaultNextText}</Button>}
    </Space>    

    return controlsTarget ? createPortal(controls, controlsTarget) : controls
})