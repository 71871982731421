import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { RcFile } from "antd/lib/upload/interface";
import { useImportDashboardMutation } from "app/store/colibri/analysis.api";
import { showError } from "helpers/messageHelpers";
import { IDashboardImportResult } from "models/importModels";


export const useImportDashboard = () => {
    const [importDashboard, { isLoading }] = useImportDashboardMutation();

    const func = async (file: RcFile): Promise<IDashboardImportResult | null> => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("force", "true");
        formData.append("link", "true");

        const result = await importDashboard(formData);
        if (result && Object.hasOwn(result, "error")) {
            const typedImportError = (result as any).error as FetchBaseQueryError;
            const msg = typedImportError.data as string;
            showError(typedImportError.status === 400 || ("originalStatus" in typedImportError && typedImportError.originalStatus === 400) ? msg : undefined);
            return null;
        } else {
            const data: IDashboardImportResult = (result as any).data;
            return data;
        }
    }
    return {
        isLoading,
        importDashboard: func
    };
};