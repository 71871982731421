import Form from "antd/lib/form"
import Radio from "antd/lib/radio"
import Select from "antd/lib/select"
import { memo, useEffect } from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { IPublishedLink, PublishedLinkOutputTypes, ReportExportFileTypes } from "../PublishWizardForm"
import { StepButtons } from "../controls/StepButtons"
import { useAutoFocus, validationFeedback } from "../utils"
import { StepTitle } from "../controls/StepTitle"

export const OutputStep = memo(() => {
    const { control, setValue } = useFormContext<IPublishedLink>()
    const autoFocus = useAutoFocus()

    const outputType = useWatch({ control, name: 'outputType'})

    useEffect(() => {
        if(!outputType)
            setValue('fileType', null)
        else
            setValue('fileType', 'Pdf')
    }, [outputType, setValue])

    return <>
        <StepTitle>Выберите режим публикации</StepTitle>
        <Controller control={control} name='outputType'
            rules={{ required: 'Необходимо выбрать режим публикации' }}
            render={({ field, fieldState }) =>
                <Form.Item                    
                    label='Режим публикации'
                    required
                    {...validationFeedback(fieldState)}>
                    <Radio.Group
                        size='large'
                        buttonStyle="solid" {...field}>
                        {Object.entries(PublishedLinkOutputTypes).map(([key, displayName], i) =>
                            <Radio.Button ref={i === 0 ? autoFocus : undefined} key={key} value={key}>{displayName}</Radio.Button>
                        )}
                    </Radio.Group>
                </Form.Item>
            } />
        {outputType === 'Link' && <Controller
            control={control}
            name='fileType'
            rules={{ required: 'Необходимо выбрать формат результата' }}
            render={({ field, fieldState }) =>
                <Form.Item
                    label='Формат результата'
                    required
                    {...validationFeedback(fieldState)}>
                    <Select allowClear {...field} defaultValue={'Pdf'} style={{width: 200}}>
                        {Object.entries(ReportExportFileTypes).map(([key, value]) => <Select.Option key={key} value={key}>{value}</Select.Option>)}
                    </Select>
                </Form.Item>
            } />}
        <StepButtons />
    </>
})