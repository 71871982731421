import React, { useMemo } from "react";
import { useGetRelatedObjectsQuery } from "app/store/colibri/relatedResources.api";
import { ColibriSourceItemType } from "models/treeModels";
import { IRelatedResourcesGroup } from "models/relatedResourceModels";
import Collapse from "antd/lib/collapse";
import { LoadingSpinnerOverlayComponent } from "components/LoadingSpinnerOverlayComponent";
import List from "antd/lib/list";
import { ApiTwoTone, ProfileTwoTone, SnippetsTwoTone } from "@ant-design/icons";

const { Panel } = Collapse;

type ComponentProps = {
    resourceType: ColibriSourceItemType;
    resourceId: string;
};
export const RelativesViewerList: React.FC<ComponentProps> = React.memo(({ resourceType, resourceId}) => {
    const { data: relatedWidgets, isFetching: isWidgetsFetching, isLoading: isWidgetsLoading } = useGetRelatedObjectsQuery({
        resourceId,
        resourceType,
        relatedType: "analysis.widget",
    }, {
        skip: !resourceId || !resourceType,
    });

    const { data: relatedPocesses, isLoading: isProcessesLoading, isFetching: isProcessesFetching } = useGetRelatedObjectsQuery({
        resourceId,
        resourceType,
        relatedType: "workflow.process",
    }, {
        skip: !resourceId || !resourceType,
    });

    const { data: relatedTemplates, isLoading: isTemplatesLoading, isFetching: isTemplatesFetching } = useGetRelatedObjectsQuery({
        resourceId,
        resourceType,
        relatedType: "reporting.template",
    }, {
        skip: !resourceId || !resourceType,
    });

    const isLoading = isWidgetsFetching || isProcessesFetching || isProcessesLoading || isWidgetsLoading || isTemplatesLoading || isTemplatesFetching;

    const resourcesGroups = useMemo<IRelatedResourcesGroup[]>(() => {
        const groups: IRelatedResourcesGroup[] = [];
        if (relatedTemplates?.length) {
            groups.push({
                key: "templates",
                name: "Шаблоны отчётов",
                resources: relatedTemplates,
                rowIcon: <SnippetsTwoTone />,
            });
        }
        if (relatedWidgets?.length) {
            groups.push({
                key: "widgets",
                name: "Виджеты",
                resources: relatedWidgets,
                rowIcon: <ProfileTwoTone />,
            });
        }
        if (relatedPocesses?.length) {
            groups.push({
                key: "processes",
                name: "Процессы",
                resources: relatedPocesses,
                rowIcon: <ApiTwoTone />,
            });
        }
        return groups;
    }, [relatedWidgets, relatedPocesses, relatedTemplates]);

    return (<LoadingSpinnerOverlayComponent 
        isVisible={isLoading} 
        body={
            <Collapse>
                {resourcesGroups.map((group) => <Panel header={`${group.name}`} key={group.key}>
                    <List
                        size="small"
                        bordered={false}
                        dataSource={group.resources}
                        renderItem={item => <List.Item>
                            <List.Item.Meta
                                avatar={group.rowIcon}
                                title={item.name}
                            />
                        </List.Item>}
                    />
                </Panel>)}
            </Collapse>
        } 
    />);
});