import { ICatalogedTreeItem } from '../../models/treeModels';
import { useMemo, useEffect } from 'react';
import { useAppSelector } from './hooks';
import { selectAllFolders, selectAllTemplates, useGetFoldersQuery, useGetTemplatesQuery } from '../store/colibri/reporting.api';
import { buildTree } from '../../helpers/treeHelpers';
import { showError } from 'helpers/messageHelpers';

export const useReportingCatalogTree = (): ICatalogedTreeItem => {
    const {isError: isFoldersError} = useGetFoldersQuery();
    const { isError: isTemplatesError } = useGetTemplatesQuery();
    const folders = useAppSelector(selectAllFolders);
    const templates = useAppSelector(selectAllTemplates);

    useEffect(() => {
        if (isFoldersError) {
            showError("Не удалось обновить список папок", 5);
        }
        if (isTemplatesError) {
            showError("Не удалось обновить список шаблонов", 5);
        }
    }, [isFoldersError, isTemplatesError]);

    const result = useMemo<ICatalogedTreeItem>(() => buildTree("reporting", "Шаблоны", folders, templates), [folders, templates]);
    return result;
};

export const useReportingCatalogFoldersTree = (): ICatalogedTreeItem => {
    useGetFoldersQuery();
    const folders = useAppSelector(selectAllFolders);
    const result = useMemo<ICatalogedTreeItem>(() => buildTree("reporting", "Шаблоны", folders), [folders]);
    return result;
};