import { createSelector } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { downloadFileByBlob } from "helpers/filesHelper";
import { showError, showSuccess } from "helpers/messageHelpers";
import { IWorkflow } from "../../../models/workflowModels";
import { baseQueryWithReauth } from "../baseQueryWithReauth";

export const workflowsApi = createApi({
    reducerPath: 'colibri/api/workflows',
    baseQuery: baseQueryWithReauth({
        baseUrl: '/api/v1',
    }),
    tagTypes: ["Workflows"],
    endpoints: (build) => ({
        getWorkflows: build.query<IWorkflow[], void>({
            query: () => 'workflows',
            transformResponse: (response: IWorkflow[]) => {
                return response.map((w) => ({ ...w, isWorkflow: true }))
            },
            providesTags: ["Workflows"],
        }),
        deleteWorkflow: build.mutation<void, string>({
            query: (id) => ({
                url: `workflows/${id}`,
                method: "DELETE",
            }),
            async onQueryStarted(workflowId, { dispatch, queryFulfilled }) {
                let result: { meta: any; data: any; } | null = null;
                try {
                    result = await queryFulfilled;
                } catch (e: any) {
                    return showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                }
                if (result && result.meta?.response?.status === 200) {
                    dispatch(workflowsApi.util.updateQueryData('getWorkflows', undefined, (draft) => {
                        const removedIndex = draft.findIndex((w) => w.id === workflowId);
                        if (removedIndex !== -1) {
                            const workflowName = draft[removedIndex]?.name;
                            draft.splice(removedIndex, 1);
                            showSuccess(`Процесс "${workflowName}" был удален!`);
                        }
                    }));
                }
            },
        }),
        exportProcess: build.mutation<any, { id: string, name: string }>({
            queryFn: async ({ id, name }, _, __, baseQuery) => {
                const result = await baseQuery({
                    url: `workflows/${id}/export`,
                    responseHandler: ((response) => response.blob()),
                });
                const resultMeta = result.meta as any;
                if (!resultMeta.response.ok) {
                    showError();
                } else {
                    downloadFileByBlob(result.data, name, "colibriprocess");
                }
                return { data: null }
            },
        }),
        importProcess: build.mutation<string, FormData>({
            query: (formData) => ({
                url: `workflows/import`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Workflows"],
        }),
    }),
});

export const {
    useGetWorkflowsQuery,
    useDeleteWorkflowMutation,
    useExportProcessMutation,
    useImportProcessMutation,
} = workflowsApi;

const selectWorkflowsResult = workflowsApi.endpoints.getWorkflows.select(undefined);

export const selectAllWorkflows = createSelector(
    selectWorkflowsResult,
    workflowsResult => workflowsResult?.data ?? []
);