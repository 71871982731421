import { ICatalogedTreeItem } from '../../models/treeModels';
import { useMemo, useEffect } from 'react';
import { useAppSelector } from './hooks';
import { buildTree } from '../../helpers/treeHelpers';
import { selectAllWidgets, useGetWidgetsQuery } from '../store/colibri/analysis.api';
import { showError } from 'helpers/messageHelpers';

export const useWidgetsCatalogTree = (): ICatalogedTreeItem => {
    const { isError } = useGetWidgetsQuery(undefined, { refetchOnFocus: true });

    useEffect(() => {
        if (isError) {
            showError("Не удалось обновить список виджетов", 5);
        }
    }, [isError]);

    const widgets = useAppSelector(selectAllWidgets);

    const result = useMemo<ICatalogedTreeItem>(() => buildTree("widgets", "Виджеты", widgets), [widgets]);
    return result;
};