import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "../baseQueryWithReauth";
import { IPublishedLink } from "features/publishing/PublishWizardForm";
import { showError, showSuccess } from "helpers/messageHelpers";
import { reportingApi } from "./reporting.api";
import { analysisApi } from "./analysis.api";

export const publicationsApi = createApi({
    reducerPath: 'colibri/api/publications',
    baseQuery: baseQueryWithReauth({
        baseUrl: '/api/v1/publications',
    }),
    tagTypes: ["Publications"],
    endpoints: (build) => ({
        publish: build.mutation<IPublishedLink, IPublishedLink>({
            query: (link) => ({
                url: ``,
                method: 'POST',
                body: link
            }),
            async onQueryStarted(publishedLink, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled;
                    if (result.data.objectType === 'reporting.template') {
                        dispatch(reportingApi.util.updateQueryData('getTemplates', undefined, (draft) => {
                            const template = draft.find((t) => t.id === publishedLink.objectId);
                            if (template) {
                                template.publishedLinkId = result.data.id
                                showSuccess(`Шаблон "${template?.name}" успешно опубликован!`);
                            }
                        }))
                    }
                    else if (result.data.objectType === 'analysis.dashboard') {
                        dispatch(analysisApi.util.updateQueryData('getDashboards', undefined, (draft) => {
                            const dashboard = draft.find((t) => t.id === publishedLink.objectId);
                            if (dashboard) {
                                dashboard.publishedLinkId = result.data.id
                                showSuccess(`Дэшборд "${dashboard?.name}" успешно опубликован!`);
                            }
                        }))
                    }
                } catch (e: any) {
                    showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                    console.error(e);
                    return;
                }
            },
        }),
        getPublishedLink: build.query<IPublishedLink, string>({
            query: (id) => `${id}`,
        }),
        deletePublishedLink: build.mutation<void, IPublishedLink>({
            query: (publishedLink) => ({
                url: `${publishedLink.id}`,
                method: 'DELETE',
            }),
            async onQueryStarted(publishedLink, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    if (publishedLink.objectType === 'reporting.template') {
                        dispatch(reportingApi.util.updateQueryData('getTemplates', undefined, (draft) => {
                            const template = draft.find((t) => t.id === publishedLink.objectId);
                            if (template) {
                                template.publishedLinkId = null
                                showSuccess(`Публикация отчёта "${template?.name}" удалена!`);
                            }
                        }))
                    }
                    else if (publishedLink.objectType === 'analysis.dashboard') {
                        dispatch(analysisApi.util.updateQueryData('getDashboards', undefined, (draft) => {
                            const dashboard = draft.find((t) => t.id === publishedLink.objectId);
                            if (dashboard) {
                                dashboard.publishedLinkId = null
                                showSuccess(`Публикация дэшборда "${dashboard?.name}" удалена!`);
                            }
                        }))
                    }
                } catch (e: any) {
                    showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                    console.error(e);
                    return;
                }
            },
        }),
        getPublishedLinks: build.query<IPublishedLink[], IPublishedLink['objectType']>({
            query: (objectType) => ({
                url: '',
                params: {
                    objectType
                }
            }),
            async onQueryStarted(objectType, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled;
                    if (objectType === 'analysis.dashboard') {
                        const dashboardPublicationLinks = Object.fromEntries(result.data.map(l => [l.objectId, l.id]))
                        dispatch(analysisApi.util.updateQueryData('getDashboards', undefined, (draft) => {
                            draft.forEach(d => d.publishedLinkId = dashboardPublicationLinks[d.id])
                        }))
                    }
                } catch (e: any) {
                    showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                    console.error(e);
                    return;
                }
            },
        }),
    }),
});

export const {
    usePublishMutation,
    useGetPublishedLinkQuery,
    useLazyGetPublishedLinkQuery,
    useDeletePublishedLinkMutation,
    useGetPublishedLinksQuery
} = publicationsApi;