import TeamOutlined from '@ant-design/icons/lib/icons/TeamOutlined'
import UsergroupDeleteOutlined from '@ant-design/icons/lib/icons/UsergroupDeleteOutlined'
import Avatar from 'antd/es/avatar'
import Button from 'antd/es/button'
import Dropdown from 'antd/es/dropdown'
import { AntiforgeryForm } from 'features/antiforgery/AntiforgeryForm'
import { useGetUserInfoQuery } from 'features/user.api'
import React from 'react'

export const ChooseTeamDropdown: React.FC = React.memo(() => {
    const { data: userInfo } = useGetUserInfoQuery()    

    if (!userInfo?.teamIds?.length)
        return <></>
    if(!userInfo?.team?.id)
        return <></>

    return <Dropdown
        menu={{
            items: [{
                key: 'exit',
                style: { padding: 0 },
                label: <AntiforgeryForm action="/auth/logout" method="POST">
                    <input hidden name='resetTeam' value='true'></input>
                    <Button type='text' htmlType="submit" danger block icon={<UsergroupDeleteOutlined />}>Сменить команду</Button>
                </AntiforgeryForm>                
            }],
            //getPopupContainer: trigger => trigger.parentElement!
        }}
        arrow={{ pointAtCenter: true }}
        trigger={['click']}
    >
        <Button
            type='text'
            style={{ color: 'white' }}
            icon={
                <Avatar
                    className='mr-3 mb-1'
                    style={{ color: '#531dab', backgroundColor: '#efdbff' }}
                    icon={<TeamOutlined />} />
            }>
            {userInfo?.team?.name}
        </Button>
    </Dropdown>
})