import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    objectId: null as string | null,
    objectType: null as 'reporting.template' | 'analysis.dashboard' | null
};

export const publishingSlice = createSlice({
    name: 'publishing',
    initialState,    
    reducers: {
        showPublisher(state, { payload: { objectId, objectType: type } }: PayloadAction<typeof initialState>) {
            state.objectId = objectId;
            state.objectType = type
        },
        hidePublisher(state) {
            state.objectId = null
            state.objectType = null
        }
    }
})

export const publishingActions = publishingSlice.actions