import { DashboardsPage } from "pages/DashboardsPage";
import { DataSourcesPage } from "pages/DataSourcesPage";
import { MainPage } from "pages/MainPage";
import { ReportingPage } from "pages/ReportingPage";
import { WidgetsPage } from "pages/WidgetsPage";
import { IPage } from "models/interfaceModels";
import { WorkflowsPage } from "pages/WorkflowsPage";

export const pagesList: IPage[] = [
    {
        key: 'home',
        title: 'Главная страница',
        path: '/',
        component: <MainPage />,
    }, {
        key: 'reporting',
        title: 'Шаблоны отчётов и печатных форм',
        path: '/reporting/*',
        component: <ReportingPage />,
        allowAddFolders: true,
        accessRoles: ["report-user", "admin"],
    }, {
        key: 'datasources',
        title: 'Источники данных',
        path: '/datasources/*',
        component: <DataSourcesPage />,
        accessRoles: ["analysis-user", "etl-user", "data-admin", "admin"],

    }, {
        key: 'widgets',
        title: 'Виджеты',
        path: '/analysis/widgets',
        component: <WidgetsPage />,
        accessRoles: ["analysis-user", "admin"],
    }, {
        key: 'dashboards',
        title: 'Дэшборды',
        path: '/analysis/dashboards',
        component: <DashboardsPage />,
        accessRoles: ["analysis-user", "admin"],
    }, {
        key: 'workflows',
        title: 'Процессы',
        path: '/workflows',
        component: <WorkflowsPage />,
        accessRoles: ["etl-user", "admin"],
    },
];