import { createAsyncThunk } from '@reduxjs/toolkit'
import Typography from "antd/lib/typography"
import Modal from 'antd/lib/modal'
import { userApi } from 'features/user.api'
import { sessionActions } from './sessionSlice'

export const reloginChallengeAction = createAsyncThunk('user/relogin', async (_, { dispatch }) => {
    try {
        await dispatch(sessionActions.disableCheckSession())
        const sessionRestored = await reloginModal()
        await dispatch(userApi.util.invalidateTags(['User']))        
        return sessionRestored
    }    
    finally {
        await dispatch(sessionActions.enableCheckSession())
        return false
    }
})

export async function reloginModal() {
    return await new Promise(reloginResolved => {
        const handeOkButton = async () => {
            return await new Promise(resolved => {
                const bc = new BroadcastChannel('relogin_bus')
                const onMessage = async (e: MessageEvent) => {
                    if (e.origin !== window.location.origin)
                        return;
                    if (e.data === "signedin") {
                        bc.removeEventListener('message', onMessage)
                        bc.close()
                        resolved(true)
                        reloginResolved(true);
                    }
                }
                bc.addEventListener('message', onMessage)
            })
        };
        Modal.warning({
            mask: true,
            maskClosable: false,
            keyboard: false,
            closable: false,
            title: 'Время жизни сессии истекло',
            okText: 'Продлить сессию',
            centered: true,
            okButtonProps: {
                href: '/auth/restoresession',
                target: '_blank',
                rel: 'noopener',
                disabled: false,
                loading: false,
            },
            content: <>
                <Typography.Paragraph>Чтобы продлить сессию нажмите на кнопку ниже.</Typography.Paragraph>
                <Typography.Paragraph>Для продления сессии будет открыто новое окно или вкладка браузера, где может потребоваться повторить вход в систему, введя учетные данные.</Typography.Paragraph>
            </>,
            onOk: handeOkButton,
            onCancel: () => {
                reloginResolved(false);
            }
        })
    })
}