import { ApiOutlined, BuildOutlined, DashboardOutlined, DatabaseOutlined, DeploymentUnitOutlined,
    HomeOutlined, InfoCircleOutlined, LockOutlined, ProjectOutlined, SettingOutlined,
    ShareAltOutlined, SnippetsOutlined, QuestionCircleOutlined, FileUnknownOutlined } from "@ant-design/icons";
import { IMenuItem } from "models/interfaceModels";

export const menuItemsList: IMenuItem[] = [
    {
        key: "home",
        icon: <HomeOutlined />,
        label: "Главная страница",
        pageKey: "home",
    }, {
        key: "reporting",
        label: 'Шаблоны отчётов',
        description: "Управление и публикация шаблонов отчётов и печатных форм",
        icon: <SnippetsOutlined />,
        accessRoles: ["report-user", "admin"],
        pageKey: "reporting",
        showOnMain: true,
    }, {
        key: 'analysis',
        label: 'Аналитика',
        icon: <ShareAltOutlined />,
        accessRoles: ["analysis-user", "admin"],
    }, {
        key: 'widgets',
        label: 'Виджеты',
        description: "Управление виджетами",
        icon: <BuildOutlined />,
        accessRoles: ["analysis-user", "admin"],
        pageKey: "widgets",
        showOnMain: true,
        parentKey: "analysis",
    }, {
        key: 'dashboards',
        label: 'Дэшборды',
        description: "Управление и публикация информационных панелей",
        icon: <DashboardOutlined />,
        accessRoles: ["analysis-user", "admin"],
        pageKey: "dashboards",
        showOnMain: true,
        parentKey: "analysis",
    }, {
        key: 'datasources-menu',
        label: 'Хранилище данных',
        icon: <DatabaseOutlined />,
        accessRoles: ["analysis-user", "etl-user", "data-admin", "admin"],
    }, {
        key: 'datasources',
        label: 'Источники данных',
        description: "Перечень исходных данных для виджетов",
        icon: <ProjectOutlined />,
        showOnMain: true,
        pageKey: "datasources",
        parentKey: "datasources-menu",
        accessRoles: ["analysis-user", "etl-user", "data-admin", "admin"],
    }, {
        key: 'workflows',
        label: 'Процессы',
        description: "Список процессов для интеграции данных",
        icon: <ApiOutlined />,
        accessRoles: ["etl-user", "admin"],
        pageKey: "workflows",
        showOnMain: true,
        parentKey: "datasources-menu",
    }, {
        key: 'etl',
        label: 'Конструктор процессов',
        description: "Конструктор процессов интеграции данных",
        icon: <DeploymentUnitOutlined />,
        accessRoles: ["etl-writer", "admin"],
        parentKey: "datasources-menu",
        showOnMain: true,
    }, {
        key: 'admin',
        label: 'Администрирование',
        icon: <SettingOutlined />,
        accessRoles: ["admin", "security-admin"],
    }, {
        key: 'security',
        label: 'Безопасность',
        description: "Просмотр и управление пользователями и ролями",
        icon: <LockOutlined />,
        accessRoles: ["security-admin"],
        showOnMain: true,
        parentKey: "admin",
    }, {
        key: "info",
        icon: <QuestionCircleOutlined />,
        label: "Справка",
    },
    {
        key: "docs",
        icon: <FileUnknownOutlined />,
        label: "Документация",
        parentKey: "info",
    },
    {
        key: "about",
        icon: <InfoCircleOutlined />,
        label: "О программе",
        parentKey: "info",
    },
];