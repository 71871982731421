import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { isDataSourceTable } from "models/dataSourcesModels";
import { ColibriSourceItemType, ICatalogedTreeItem } from "models/treeModels";

interface IRelatedViewerState {
    itemId: string | null;
    itemType: ColibriSourceItemType | null;
    itemName: string | null;
};

const initialState: IRelatedViewerState = {
    itemId: null,
    itemType: null,
    itemName: null,
};

export const relativesViewerSlice = createSlice({
    name: "relativesViewerStore",
    initialState,
    reducers: {
        showRelativesViewer: (state, action: PayloadAction<ICatalogedTreeItem>) => {
            state.itemId = isDataSourceTable(action.payload.sourceItem) ? action.payload.name : action.payload.id;
            state.itemType = action.payload.sourceType;
            state.itemName = action.payload.name;
        },
        hideRelativesViewer: (state) => {
            state.itemId = null;
            state.itemType = null;
            state.itemName = null;
        },
    },
});

const selectOptions = (state: RootState) => state.relativesViewerStore;

export const selectRelativesViewerOptions = createSelector(
    selectOptions,
    result => result.itemId && result.itemType ? result : null
);

export const relativesViewerActions = relativesViewerSlice.actions;
export const relativesViewerReducer = relativesViewerSlice.reducer;