import { Mutex } from 'async-mutex'
import { FetchArgs, fetchBaseQuery, FetchBaseQueryError, type BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { reloginChallengeAction } from '../../features/session/Relogin';
import { showSuccess } from '../../helpers/messageHelpers';
import message from 'antd/lib/message';
import { RootState } from '.';

const mutex = new Mutex();

export const baseQueryWithReauth: (args: FetchBaseQueryArgs) => BaseQueryFn<
    string | FetchArgs,
    any,
    FetchBaseQueryError
> = (baseArgs) => async (args, api, extraOptions) => {            
    const baseQuery = fetchBaseQuery({...baseArgs,
        async prepareHeaders(headers, api) {
            if(api.type === 'query')
                return headers
            const s = api.getState() as RootState
            const csrfToken = (s['api/antiforgery']?.queries?.["getCsrfToken(undefined)"]?.data as any)?.token
            if(csrfToken)
                headers.set('RequestVerificationToken', csrfToken)
            return headers
        },
    })    
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();

            try {
                const sessionRestored = await api.dispatch(reloginChallengeAction())
                
                if (sessionRestored) {                                        
                    showSuccess("Сессия успешно продлена.");
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    message.warning({ content: 'Сессия истекла.' })
                }
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};