import { IDashboard, IWidget } from "./analysisModels";
import { IDataSourceSchema, IDataSourceTable } from "./dataSourcesModels";
import { ITemplate } from "./reportingModels";
import { ITag } from "./tagModels";
import { IWorkflow } from "./workflowModels";

export interface IFolder {
    id: string;
    name: string;
    pid: string | null;
    isFolder: boolean;
}

export function isReportingFolder(obj: any): obj is IFolder {
    return obj && obj.isFolder === true;
}

export type ColibriSourceItemType = "reporting.folder" | "reporting.template" | "analysis.widget" | "analysis.dashboard" | "workflow.process" | `cubes.${string}`;
export type ColibriSourceItem = IFolder | ITemplate | IWidget | IDashboard | IWorkflow | IDataSourceSchema | IDataSourceTable;
export type ColibriSourceItemsSet = Array<IFolder[] | ITemplate[] | IWorkflow[] | IWidget[] | IDashboard[] | IDataSourceSchema[] | IDataSourceTable[]>;

export interface ICatalogedTreeItem {
    id: string;
    pid: string | null;
    parentItem: ICatalogedTreeItem | null;
    name: string;
    isCatalog: boolean;
    sourceType: ColibriSourceItemType | null;
    sourceItem: ColibriSourceItem | null;
    isRoot?: boolean;
    items?: ICatalogedTreeItem[];
    tags?: ITag[];
};