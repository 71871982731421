import { ICatalogedTreeItem } from '../../models/treeModels';
import { useMemo, useEffect } from 'react';
import { useAppSelector } from './hooks';
import { buildTree } from '../../helpers/treeHelpers';
import { selectAllDashboards, useGetDashboardsQuery } from '../store/colibri/analysis.api';
import { showError } from 'helpers/messageHelpers';

export const useDashboardsCatalogTree = (): ICatalogedTreeItem => {
    const { isError } = useGetDashboardsQuery(undefined, { refetchOnFocus: true });

    useEffect(() => {
        if (isError) {
            showError("Не удалось обновить список дэшбордов", 5);
        }
    }, [isError]);
    
    const dashboards = useAppSelector(selectAllDashboards);

    const result = useMemo<ICatalogedTreeItem>(() => buildTree("dashboards", "Дэшборды", dashboards), [dashboards]);
    return result;
};