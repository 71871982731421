import { useAppSelector } from 'app/hooks/hooks'
import { useGetColibriEnvironmentQuery } from 'app/store/colibri/environment.api'
import { useGetUserInfoQuery } from 'features/user.api'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

export const CheckSessionIframe: React.FC = React.memo(() => {
    const { data: env } = useGetColibriEnvironmentQuery()
    const iframeRef = useRef<HTMLIFrameElement | null>(null)
    const { data: user } = useGetUserInfoQuery()

    var targetOP = env?.openIdProvider as string;

    const [sessionChanged, setSessionChanged] = useState(false)
    const [iframeLoaded, setIframeLoaded] = useState(false)
    const checkSessionEnabledGlobal = useAppSelector(s => s.sessionStore.checkSessionEnabled)
    const sessionMonitoringEnabled = useMemo(() =>
        Boolean(checkSessionEnabledGlobal && user && env?.checkSessionIframe && env?.checkSessionIntervalMs && !sessionChanged)
        , [checkSessionEnabledGlobal, user, env?.checkSessionIframe, env?.checkSessionIntervalMs, sessionChanged])

    useEffect(() => {
        if(sessionMonitoringEnabled)
            console.debug(`Сессия пользователя: ${user?.sessionState}`)
    }, [user?.sessionState, sessionMonitoringEnabled])

    useEffect(() => {
        if (!sessionMonitoringEnabled)
            return

        window.addEventListener("message", receiveMessage, false);
        async function receiveMessage(event: any) {
            if (event.origin !== targetOP) {
                // Origin did not come from the OP; this message must be rejected.
                return;
            }
            if (event.data === "unchanged") {
                // User is still logged in to the OP

            } else {
                // User has logged out of the OP                                
                setSessionChanged(true)
            }
        }

        return () => {
            window.removeEventListener("message", receiveMessage, false);
        }
    }, [sessionMonitoringEnabled, targetOP])

    useEffect(() => {
        if (!sessionMonitoringEnabled || !iframeLoaded)
            return

        const checkStatus = () => {
            var client = env?.clientId;
            const sessionState = user?.sessionState
            var text = client + " " + sessionState;
            iframeRef.current?.contentWindow?.postMessage(text, targetOP);
        }

        checkStatus()
        intervalRef.current = setInterval(checkStatus, Math.max(env?.checkSessionIntervalMs || 0, 1000))
        return () => {
            if (intervalRef.current)
                clearInterval(intervalRef.current)
            intervalRef.current = null
        }
    }, [sessionMonitoringEnabled, iframeLoaded, env?.checkSessionIntervalMs, env?.clientId, user?.sessionState, targetOP])

    const intervalRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        if (!sessionChanged)
            return

        window.location.href = '/auth/logout'
    }, [sessionChanged])

    return <>
        {sessionMonitoringEnabled && <iframe
            style={{ display: "none" }}
            ref={iframeRef}
            src={env?.checkSessionIframe}
            title='Проверка наличия сессии'
            onLoad={() => setIframeLoaded(true)} />}
    </>
})