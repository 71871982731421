import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSearchParams, createSearchParams, useNavigate } from "react-router-dom";
import { ItemList } from "../components/controls/ItemList";
import { ItemListHeader } from "../components/controls/ItemListHeader";
import { ICatalogedTreeItem } from "../models/treeModels";
import { findItemsInTreeByName, findNodeById } from "../helpers/treeHelpers";
import { BranchesOutlined, FolderTwoTone, PlusOutlined, ProjectTwoTone, SafetyOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDataSourcesCatalogTree } from "../app/hooks/useDataSourcesCatalogTree";
import { useGetDataSourcesTablesQuery, useGetDataSourcesSchemasQuery, useAddSchemaMutation, useDeleteSchemaMutation } from "../app/store/colibri/dataSources.api";
import { NotFoundContent } from "components/NotFoundContent";
import { useFolderIdFromPath } from "app/hooks/useFolderIdFromPath";
import { useActions } from "app/hooks/hooks";
import { IListAction } from "models/appModels";
import { IDataSourceSchema, isDataSourceSchema } from "models/dataSourcesModels";
import Modal from "antd/lib/modal";
import Input from "antd/lib/input/Input";
import Form, { FormInstance } from "antd/lib/form";
import Typography from "antd/lib/typography";

const { Text } = Typography;

export const DataSourcesPage: React.FC = React.memo(() => {
    const tree = useDataSourcesCatalogTree();
    const { isLoading: isSchemasLoading, isFetching: isSchemasFetching } = useGetDataSourcesSchemasQuery();
    const { isLoading: isTablesLoading, isFetching: isTablesFetching } = useGetDataSourcesTablesQuery();
    const [deleteSchema, { isLoading: isShemaRemoving }] = useDeleteSchemaMutation();
    const [addSchema, { isLoading: isShemaAdding }] = useAddSchemaMutation();

    const formRef = useRef<FormInstance>();

    const [isNewSchemaModalOpen, setNewSchemaModalOpen] = useState<boolean>(false);

    const isLoading = isSchemasLoading || isSchemasFetching || isTablesLoading || isTablesFetching || isShemaRemoving;

    const [currentItems, setCurrentItems] = useState<ICatalogedTreeItem[]>();

    const [searchParams, setSearchParams] = useSearchParams();
    const searchText = searchParams.get("s");

    const folderIdFromPath = useFolderIdFromPath();

    const currentFolder = useMemo(() => {
        if (!folderIdFromPath) {
            return tree;
        }
        return findNodeById(tree, folderIdFromPath);
    }, [tree, folderIdFromPath]);


    useEffect(() => {
        if (searchText && currentFolder) {
            setCurrentItems(findItemsInTreeByName(currentFolder, searchText));
        } else {
            setCurrentItems(currentFolder?.items);
        }
    }, [currentFolder, searchText]);

    const onSearch = (value: string): void => {
        if (value) {
            setSearchParams(createSearchParams({ s: value }));
        } else {
            setSearchParams();
        }
    };

    const { showPermissionsWindow, showRelativesViewer } = useActions();

    const showDeleteConfirm = (item: ICatalogedTreeItem) => {
        Modal.confirm({
            title: "Необходимо подтверждение",
            icon: <DeleteOutlined />,
            content: <span>Вы уверены, что хотите удалить <b>{item.name}</b>?</span>,
            okText: "Подтвердить",
            onOk: () => deleteSchema(item.name),
            cancelText: 'Отмена',
        });
    };

    const listActions: IListAction[] = [{
        key: "relatives",
        tooltipText: "Зависимые объекты",
        icon: <BranchesOutlined />,
        onClick: (item) => showRelativesViewer(item),
        forceHidden: (item) => isDataSourceSchema(item.sourceItem),
    }, {
        key: "permissions",
        tooltipText: "Настройка разрешений",
        icon: <SafetyOutlined />,
        accessRoles: ["security-admin"],
        onClick: (item) => showPermissionsWindow(item),
    }, {
        key: "delete",
        tooltipText: "Удалить",
        icon: <DeleteOutlined />,
        onClick: showDeleteConfirm,
        danger: true,
        accessRoles: ["data-admin"],
        forceHidden: (item) => !isDataSourceSchema(item.sourceItem),
    }];

    const navigate = useNavigate();
    const onItemClicked = (item: ICatalogedTreeItem): void => {
        if (item?.isCatalog) {
            navigate(item.id, {
                replace: false,
                relative: 'path',
            });
        }
    };

    const handleOk = async () => {
        const values = formRef.current?.getFieldsValue() as Exclude<IDataSourceSchema, "isDataSourceSchema">;
        if (!values.title || !values.name) {
            return;
        }
        const result = await addSchema(values);
        if (!Object.keys(result).includes("error")) {
            setNewSchemaModalOpen(false);
        }
    };

    const handleCancel = () => {
        setNewSchemaModalOpen(false);
    };

    return (
        <div className="bg-white h-full w-full shadow p-8 rounded-lg">
            {currentFolder || isLoading ? <>
                <ItemListHeader
                    currentFolder={currentFolder}
                    isSerchPanelEnabled={true}
                    onSearch={onSearch}
                    buttons={currentFolder?.isRoot ? [{
                        key: "open-editor",
                        icon: <PlusOutlined />,
                        buttonText: "Создать каталог",
                        clickEvent: () => setNewSchemaModalOpen(true),
                        disabled: isLoading,
                        accessRoles: ["data-admin"],
                    }] : undefined}
                />
                <ItemList
                    isLoading={isLoading}
                    listItems={currentItems || []}
                    itemActions={listActions}
                    itemClickEvent={onItemClicked}
                    iconRender={(item: ICatalogedTreeItem) => {
                        if (item.isCatalog) {
                            return <FolderTwoTone className="text-2xl" />
                        } else {
                            return <ProjectTwoTone className="text-2xl" />
                        }
                    }}
                    titleRender={(record :ICatalogedTreeItem) => <>
                        <Text strong>{record.name}</Text>
                        {record.isCatalog && <Text className="block text-xs" type="secondary">{(record.sourceItem as IDataSourceSchema).title}</Text>}
                    </>}
                />
            </> : <NotFoundContent
                title="Каталог не найден"
                text={`Пожалуйста, вернитесь в ${tree.name} или обратитесь к системному администратору, если считаете, что каталог должен быть здесь...`}
                buttonText={tree.name}
                onButtonClick={() => navigate(`/${tree.id}`)}
            />}
            <Modal
                title="Новый каталог"
                width={350}
                open={isNewSchemaModalOpen}
                onOk={handleOk}
                confirmLoading={isShemaAdding}
                onCancel={handleCancel}
                destroyOnClose
                afterClose={() => setNewSchemaModalOpen(false)}
            >
                <Form
                    ref={formRef as any}
                    name="basic"
                    layout="vertical"
                >
                    <Form.Item
                        label="Наименование"
                        name="name"
                        rules={[{ required: true, message: 'Пожалуйста, укажите Наименование' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Описание"
                        name="title"
                        rules={[{ required: true, message: 'Пожалуйста, укажите Описание' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
});