import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { importResourceReducer } from 'features/importResource/importResourceSlice';
import { permissionReducer } from 'features/permissions/permissionSlice';
import { relativesViewerReducer } from 'features/relativesViewer/relativesViewerSlice';
import { sessionReducer } from 'features/session/sessionSlice';
import { publishingSlice } from '../../features/publishing/publishingSlice';
import { userApi } from '../../features/user.api';
import { antiforgeryApi } from 'features/antiforgery/antiforgery.api';
import { analysisApi } from './colibri/analysis.api';
import { dataSourcesApi } from './colibri/dataSources.api';
import { environmentApi } from './colibri/environment.api';
import { permissionsApi } from './colibri/permissions.api';
import { publicationsApi } from './colibri/publications.api';
import { relatedResourcesApi } from './colibri/relatedResources.api';
import { reportingApi } from './colibri/reporting.api';
import { workflowsApi } from './colibri/workflows.api';
import { interfaceReducer } from './pages/interfaceSlice';

export const store = configureStore({
    reducer: {
        [userApi.reducerPath]: userApi.reducer,
        [antiforgeryApi.reducerPath]: antiforgeryApi.reducer,
        [reportingApi.reducerPath]: reportingApi.reducer,
        [workflowsApi.reducerPath]: workflowsApi.reducer,
        [environmentApi.reducerPath]: environmentApi.reducer,
        [analysisApi.reducerPath]: analysisApi.reducer,
        [dataSourcesApi.reducerPath]: dataSourcesApi.reducer,
        [publicationsApi.reducerPath]: publicationsApi.reducer,
        [permissionsApi.reducerPath]: permissionsApi.reducer,
        [publishingSlice.name]: publishingSlice.reducer,
        [relatedResourcesApi.reducerPath]: relatedResourcesApi.reducer,
        interfaceStore: interfaceReducer,
        permissionStore: permissionReducer,
        relativesViewerStore: relativesViewerReducer,
        importResourceStore: importResourceReducer,
        sessionStore: sessionReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: true,
    })
        .concat(userApi.middleware)
        .concat(antiforgeryApi.middleware)
        .concat(reportingApi.middleware)
        .concat(workflowsApi.middleware)
        .concat(environmentApi.middleware)
        .concat(analysisApi.middleware)
        .concat(dataSourcesApi.middleware)
        .concat(publicationsApi.middleware)
        .concat(permissionsApi.middleware)
        .concat(relatedResourcesApi.middleware)
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
