import { createSelector } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { RootState } from "..";
import { IFolder } from "../../../models/treeModels";
import { ITemplate, ITemplateVersion } from "../../../models/reportingModels";
import { baseQueryWithReauth } from "../baseQueryWithReauth";
import { showError, showSuccess } from "../../../helpers/messageHelpers";
import { ReportTemplateParameter } from "features/publishing/ReportTemplateParameter";
import { downloadFileByBlob } from "helpers/filesHelper";

export const reportingApi = createApi({
    reducerPath: 'colibri/api/reports',
    baseQuery: baseQueryWithReauth({
        baseUrl: '/api/v1/reports',
    }),
    tagTypes: ["Templates", "Folders"],
    endpoints: (build) => ({
        getFolders: build.query<IFolder[], string | null | void>({
            query: (folderId) => ({
                url: 'folders',
                params: folderId !== undefined ? {
                    'filters[pid]': folderId
                } : undefined,
            }),
            providesTags: ["Folders"],
            transformResponse: (response: IFolder[]) => {
                return response.map((f) => ({ ...f, isFolder: true }))
            },
            async onQueryStarted(folderId, { dispatch, queryFulfilled, updateCachedData, getState }) {
                let result: { meta: any; data: any; } | null = null;
                try {
                    result = await queryFulfilled
                } catch (e) {
                    console.error(e);
                    return;
                }
                if (folderId !== undefined && result && result.meta?.response?.status === 200) {
                    dispatch(reportingApi.util.updateQueryData('getFolders', undefined, (draft) => {
                        // удаление
                        const toRemoveIndices = draft.map((d, i) => ({ index: i, data: d, delete: d.pid === folderId && result?.data?.every((f: IFolder) => f.id !== d.id) }))
                            .filter(t => t.delete)
                            .map(t => t.index)

                        toRemoveIndices.reverse().forEach(i => draft.splice(i, 1))

                        // обновление и добавление
                        result?.data?.forEach((d: IFolder) => {
                            const replaceIndex = draft.findIndex(f => f.id === d.id)
                            if (replaceIndex >= 0) {
                                draft[replaceIndex] = d
                            }
                            else {
                                draft.push(d)
                            }
                        })
                    }))
                }
            },
        }),
        getTemplates: build.query<ITemplate[], string | null | void>({
            query: (folderId) => ({
                url: 'templates',
                params: folderId !== undefined ? {
                    'filters[folders.*.id]': folderId
                } : undefined
            }),
            providesTags: ["Templates"],
            transformResponse: (response: ITemplate[]) => {
                return response.map((t) => ({ ...t, isTemplate: true }))
            },
            async onQueryStarted(folderId, { dispatch, queryFulfilled, updateCachedData, getState }) {
                let result: { meta: any; data: any; } | null = null;
                try {
                    result = await queryFulfilled
                } catch (e) {
                    console.error(e);
                    return;
                }
                if (folderId !== undefined && result && result.meta?.response?.status === 200) {
                    dispatch(reportingApi.util.updateQueryData('getTemplates', undefined, (draft) => {
                        // удаление
                        const toRemoveIndices = draft.map((d, i) => ({ index: i, data: d, delete: d.folders.some(f => f.id === folderId) && result?.data?.every((f: ITemplate) => f.id !== d.id) }))
                            .filter(t => t.delete)
                            .map(t => t.index)

                        toRemoveIndices.reverse().forEach(i => draft.splice(i, 1))

                        // обновление и добавление
                        result?.data?.forEach((d: ITemplate) => {
                            const replaceIndex = draft.findIndex(f => f.id === d.id)
                            if (replaceIndex >= 0) {
                                draft[replaceIndex] = d
                            }
                            else {
                                draft.push(d)
                            }
                        })
                    }))
                }
            },
        }),
        addTemplate: build.mutation<ITemplate, { name: string | null, folderId: string | null }>({
            query: (body) => ({
                url: `templates`,
                method: 'POST',
                body,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                let result: { meta: any; data: ITemplate; } | null = null;
                try {
                    result = await queryFulfilled;
                } catch (e: any) {
                    showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                    console.error(e);
                    return;
                }
                if (result && result.meta?.response?.status === 200) {
                    dispatch(reportingApi.util.updateQueryData('getTemplates', undefined, (draft) => {
                        const newTemplate = result?.data;
                        if (newTemplate) {
                            draft.push({ ...newTemplate, isTemplate: true });
                            showSuccess(`Добавлен новый шаблон "${newTemplate.name}"`);
                        }
                    }));
                }
            },
        }),
        getTemplateVersion: build.query<ITemplateVersion[], { templateId: string; offset: number; limit: number }>({
            query: (params) => ({
                url: `templates/${params.templateId}/versions`,
                params: {
                    "start": params.offset,
                    "limit": params.limit,
                },
                method: "GET",
                headers: {
                    'content-type': 'application/json'
                }
            }),
        }),
        addFolder: build.mutation<IFolder, Partial<IFolder>>({
            query: (body) => ({
                url: `folders`,
                method: 'POST',
                body,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                let result: { meta: any; data: IFolder; } | null = null;
                try {
                    result = await queryFulfilled;
                } catch (e: any) {
                    showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                    console.error(e);
                    return;
                }
                if (result && result.meta?.response?.status === 200) {
                    dispatch(reportingApi.util.updateQueryData('getFolders', undefined, (draft) => {
                        const newFolder = result?.data;
                        if (newFolder) {
                            draft.push({
                                ...newFolder,
                                isFolder: true,
                            });
                            showSuccess(`Добавлена новая папка "${newFolder.name}"`);
                        }
                    }))
                }
            },
        }),
        updateFolder: build.mutation<IFolder, Pick<IFolder, "id"> & Partial<IFolder>>({
            query: ({ id, ...patch }) => ({
                url: `folders/${id}`,
                method: "PATCH",
                body: patch,
            }),
            invalidatesTags: ["Templates", "Folders"],
            async onQueryStarted(folder, { dispatch, queryFulfilled }) {
                let result: { meta: any; data: IFolder; } | null = null;
                try {
                    result = await queryFulfilled;
                } catch (e: any) {
                    return showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                }
                if (result && result.meta?.response?.status === 200) {
                    dispatch(reportingApi.util.updateQueryData('getFolders', undefined, (draft) => {
                        const updatedFolder = result?.data;
                        if (folder.id === result?.data.id) {
                            const changedIndex = draft.findIndex((f) => f.id === folder.id);
                            if (changedIndex !== -1 && updatedFolder) {
                                draft[changedIndex] = {
                                    ...draft[changedIndex],
                                    ...updatedFolder
                                };
                                showSuccess();
                            }
                        } else {
                            showError();
                            console.error("ID изменяемой папки и ID папки в ответе не совпадают!");
                            return;
                        }
                    }))
                }
            },
        }),
        deleteFolder: build.mutation<void, string>({
            query: (id) => ({
                url: `folders/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Templates", "Folders"],
            async onQueryStarted(folderId, { queryFulfilled, getState }) {
                let result: { meta: any; data: any; } | null = null;
                try {
                    result = await queryFulfilled;
                } catch (e: any) {
                    showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                    return;
                }
                if (result && result.meta?.response?.status === 200) {
                    const folders = selectAllFolders(getState());
                    const removedFolder = folders.find((f) => f.id === folderId);
                    if (removedFolder) {
                        const folderName = removedFolder.name;
                        showSuccess(`Папка "${folderName}" была удалена!`);
                    }
                }
            },
        }),
        updateTemplate: build.mutation<ITemplate, Pick<ITemplate, "id"> & Partial<ITemplate>>({
            query: ({ id, ...patch }) => ({
                url: `templates/${id}`,
                method: "PATCH",
                body: patch,
            }),
            async onQueryStarted(template, { dispatch, queryFulfilled }) {
                let result: { meta: any; data: ITemplate; } | null = null;
                try {
                    result = await queryFulfilled;
                } catch (e: any) {
                    showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                    return;
                }
                if (result && result.meta?.response?.status === 200) {
                    dispatch(reportingApi.util.updateQueryData('getTemplates', undefined, (draft) => {
                        const updatedTemplate = result?.data;
                        if (template.id === result?.data.id) {
                            const changedIndex = draft.findIndex((t) => t.id === template.id);
                            if (changedIndex !== -1 && updatedTemplate) {
                                draft[changedIndex] = {
                                    ...draft[changedIndex],
                                    ...updatedTemplate
                                };
                                showSuccess();
                            }
                        } else {
                            showError();
                            console.error("ID изменяемого шаблона и ID шаблона в ответе не совпадают!");
                            return;
                        }
                    }))
                }
            },
        }),
        deleteTemplate: build.mutation<void, string>({
            query: (id) => ({
                url: `templates/${id}`,
                method: "DELETE",
            }),
            async onQueryStarted(templateId, { dispatch, queryFulfilled }) {
                let result: { meta: any; data: any; } | null = null;
                try {
                    result = await queryFulfilled;
                } catch (e: any) {
                    showError(typeof e?.error?.data === "string" ? e?.error?.data : undefined);
                    return;
                }
                if (result && result.meta?.response?.status === 200) {
                    dispatch(reportingApi.util.updateQueryData('getTemplates', undefined, (draft) => {
                        const removedIndex = draft.findIndex((t) => t.id === templateId);
                        if (removedIndex !== -1) {
                            const templateName = draft[removedIndex]?.name;
                            draft.splice(removedIndex, 1);
                            showSuccess(`Шаблон "${templateName}" был удален!`);
                        }
                    }))
                }
            },
        }),
        getTemplateParameters: build.query<ReportTemplateParameter[], string>({
            query: (id) => `templates/${id}/parameters`,
        }),
        exportTemplate: build.mutation<any, { id: string, name: string }>({
            queryFn: async ({ id, name }, _, __, baseQuery) => {
                const result = await baseQuery({
                    url: `templates/${id}/export`,
                    responseHandler: ((response) => response.blob()),
                });
                const resultMeta = result.meta as any;
                if (!resultMeta.response.ok) {
                    showError();
                } else {
                    downloadFileByBlob(result.data, name, "colibritemplate");
                }
                return { data: null }
            },
        }),
        importTemplate: build.mutation<string, FormData>({
            query: (formData) => ({
                url: `templates/import`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["Templates"],
        }),
    }),
});

export const {
    useGetFoldersQuery,
    useGetTemplatesQuery,
    useGetTemplateVersionQuery,
    useUpdateTemplateMutation,
    useUpdateFolderMutation,
    useAddFolderMutation,
    useAddTemplateMutation,
    useDeleteFolderMutation,
    useDeleteTemplateMutation,
    useGetTemplateParametersQuery,
    useExportTemplateMutation,
    useImportTemplateMutation,
} = reportingApi;

export const selectFoldersResult = reportingApi.endpoints.getFolders.select(undefined);

export const selectTemplatesResult = reportingApi.endpoints.getTemplates.select(undefined);


export const selectAllFolders = createSelector(
    selectFoldersResult,
    foldersResult => foldersResult?.data ?? []
);

export const selectAllTemplates = createSelector(
    selectTemplatesResult,
    templatesResult => templatesResult?.data ?? []
);

export const selectFolderById = createSelector(
    selectAllFolders,
    (state: RootState, folderId: string) => folderId,
    (folders, folderId) => folders.find(folder => folder.id === folderId)
);

export const selectTemplateById = createSelector(
    selectAllTemplates,
    (state: RootState, templateId: string) => templateId,
    (templates, templateId) => templates.find(template => template.id === templateId)
);