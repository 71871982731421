import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Tree, { DataNode } from 'antd/lib/tree'
import { useReportingCatalogFoldersTree } from "../app/hooks/useReportingCatalogTree";
import { useNavigate } from "react-router-dom";
import RcTree from 'rc-tree';
import { FolderTwoTone } from '@ant-design/icons';
import useResizeObserver from "@react-hook/resize-observer";
import { ICatalogedTreeItem } from "../models/treeModels";
import { useDrag, useDrop } from "react-dnd";
import { findNodeById, getParentsChain, isItemDirectParentOfItem, isItemParentOfItem } from "../helpers/treeHelpers";
import { useMoveTreeItem } from "app/hooks/useMoveTreeItem";
import { useFolderIdFromPath } from "app/hooks/useFolderIdFromPath";
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import Layout from "antd/lib/layout";
import Divider from "antd/lib/divider";
import Typography from "antd/lib/typography";

const { Sider } = Layout;
const { Title } = Typography;

type ComponentProps = {
    rootPath: string;
};

export const ReportingExplorerTreeComponent: React.FC<ComponentProps> = React.memo(({ rootPath }) => {

    const tree = useReportingCatalogFoldersTree();
    const treeData = useMemo(() => [tree], [tree]);
    const folderIdFromPath = useFolderIdFromPath();
    const treeRef = useRef<RcTree>();

    const currentFolder = useMemo(() => {
        if (!folderIdFromPath) {
            return tree;
        }
        return findNodeById(tree, folderIdFromPath);
    }, [tree, folderIdFromPath]);

    const activeKey = currentFolder?.id || tree.id;
    
    useEffect(() => {
        if (!treeRef.current) {
            return;
        }
        const parents = currentFolder ? getParentsChain(currentFolder) : [];
        const parentKeys = parents.map((k) => k.id);
        const newKeys = [...treeRef.current.state.expandedKeys, ...parentKeys, activeKey]
            .filter((value, index, self) => self.indexOf(value) === index)
            .filter(Boolean);
        
        treeRef.current?.setExpandedKeys(newKeys);
        treeRef.current?.scrollTo({ key: activeKey })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeKey]); // Можно не отслеживать currentFolder, так как activeKey напрямую зависит от currentFolder

    const navigate = useNavigate();
    const onClick = useCallback((_: any, node: DataNode) => {
        const entity = treeRef.current!.state.keyEntities[node.key];
        const path = entity.key === rootPath ? `/${rootPath}` : `/${rootPath}/${entity.key}`;
        navigate(path, {
            replace: true,
        });
    }, [navigate, rootPath]);

    const [size, setSize] = useState<DOMRectReadOnly>();
    const [treeContainer, setTreeContainer] = useState<HTMLDivElement>();
    useResizeObserver(treeContainer || null, (entry) => setSize(entry.contentRect));

    return (
        <Sider
            width={300}
            className="site-layout-background shadow ml-6 rounded-lg overflow-hidden"
            style={{backgroundColor: "#fff"}}
        >
            <div className="bg-white h-full p-4" ref={setTreeContainer as any}>
                <Title className="ml-2" level={4}>Проводник</Title>
                <Divider className="mt-1 mb-2" />
                <ErrorBoundary>
                    {size && <Tree
                        height={size.height}
                        ref={treeRef as any}
                        defaultExpandAll
                        fieldNames={{ children: 'items', key: 'id', title: 'name' }}
                        onClick={onClick}
                        selectedKeys={[activeKey]}
                        treeData={treeData as any}
                        blockNode
                        virtual
                        titleRender={(item) => <DraggableNode icon={<FolderTwoTone />} item={item as unknown as ICatalogedTreeItem} />}
                    />}
                </ErrorBoundary>
            </div>
        </Sider>
    )
});

const DraggableNode: React.FC<{ item: ICatalogedTreeItem; icon: React.ReactNode }> = React.memo(({item, icon}) => {
    const [collected, drag] = useDrag(() => ({
        type: item.sourceType || "",
        item: item,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [item]);
    const moveTreeItem = useMoveTreeItem();

    const [collectedProps, drop] = useDrop(() => ({
        accept: ["reporting.folder", "reporting.template"],
        drop: async (source: ICatalogedTreeItem) => moveTreeItem(source, item),
        canDrop: (sourceItem) => !isItemParentOfItem(sourceItem, item) && sourceItem.id !== item.id && !isItemDirectParentOfItem(item, sourceItem),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }), [item]);

    const isActive = collectedProps.canDrop && collectedProps.isOver;
    const isDragging = collected?.isDragging;

    const attachFolderRef = (el: HTMLTableRowElement): void => {
        drag(el);
        drop(el);
    };
    const attachTemplateRef = (el: HTMLTableRowElement): void => {
        drag(el);
    };
    const attachRootRef = (el: HTMLTableRowElement): void => {
        drop(el);
    };

    return <div 
        ref={item.isRoot ? attachRootRef : item.sourceType === "reporting.folder" ? attachFolderRef : attachTemplateRef}
        className={`${isActive ? "bg-yellow-100" : ""}${isDragging ? " opacity-30" : ""}`}
    >
        <span className="mr-2 px-1">{icon}</span>
        <span>{item.name}</span>
    </div>
});