import React, { useMemo } from "react";
import { EditOutlined, FileTextTwoTone } from "@ant-design/icons";
import { ICatalogedTreeItem } from "../../models/treeModels";
import Table, { ColumnsType, TableProps } from "antd/lib/table";
import "./itemList.css";
import Tooltip from "antd/lib/tooltip";
import { IListAction } from "../../models/appModels";
import { ItemListDraggableRow } from "./ItemListDraggableRow";
import { getTreeItemMetadataString, getTreeItemPathNames } from "helpers/treeHelpers";
import Typography from "antd/lib/typography";
import Button from "antd/lib/button";
import Space from "antd/lib/space";
import { useGetUserInfoQuery } from "features/user.api";
import { isUserHasAccessToSecuredElement } from "features/security/accessChecker";

const { Text } = Typography;

type ComponentProps = {
    isLoading?: boolean;
    listItems: ICatalogedTreeItem[];
    itemActions?: IListAction[];
    isSearchMode?: boolean;
    itemClickEvent: (item: ICatalogedTreeItem) => void;
    itemRenamedEvent?: (newName: string, item: ICatalogedTreeItem) => void;
    titleRender?: (item: ICatalogedTreeItem) => React.ReactNode;
    iconRender?: (item: ICatalogedTreeItem) => React.ReactNode;
};

export const ItemList: React.FC<ComponentProps> = React.memo(({
    isLoading,
    listItems,
    isSearchMode,
    itemClickEvent,
    itemRenamedEvent,
    itemActions,
    iconRender,
    titleRender,
}) => {
    const { data: userInfo } = useGetUserInfoQuery();

    const allowedActionsButtons = useMemo(() => { //Получение перечня кнопок, которые доступны согласно ролям
        if (userInfo) {
            return itemActions?.filter((action) => isUserHasAccessToSecuredElement(action, userInfo.roles))
        } else {
            return [];
        }
    }, [itemActions, userInfo]);

    const sortedListItems = useMemo(() => {
        const catalogs = listItems
            .filter((item) => item.sourceType === "reporting.folder")
            .sort((a, b) => a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1);
        const files = listItems
            .filter((item) => item.sourceType !== "reporting.folder")
            .sort((a, b) => a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1);
        return [...catalogs, ...files];
    }, [listItems]);
    const columns: ColumnsType<ICatalogedTreeItem> = [
        {
            title: 'icon',
            dataIndex: 'icon',
            key: 'icon',
            width: 64,
            ellipsis: true,
            render: (_, record) => iconRender ? iconRender(record) : (<FileTextTwoTone className="text-2xl" />),
        },
        {
            title: 'title',
            dataIndex: 'title',
            key: 'title',
            ellipsis: true,
            render: (_, record) => {
                if (titleRender) {
                    return titleRender(record);
                } else {
                    const metaData = getTreeItemMetadataString(record);
                    let itemPath = null;
                    if (isSearchMode) {
                        const itemPathNames = getTreeItemPathNames(record);
                        itemPath = `Путь: ${itemPathNames.join("/")}`;
                    }
                    return (<>
                        <Text
                            strong
                            editable={
                                itemRenamedEvent
                                ? {
                                    icon: <EditOutlined className="ml-2" />,
                                    tooltip: "Переименовать",
                                    onChange: (newTitle: string) => itemRenamedEvent(newTitle, record),
                                }
                                : false
                            }
                        >
                            {record.name}
                        </Text>
                        {(itemPath && isSearchMode) && <Text className="block text-xs" type="secondary">{itemPath}</Text>}
                        {(metaData && !isSearchMode) && <Text className="block text-xs" type="secondary">{metaData}</Text>}
                    </>);
                }
            },
        },
    ];

    if (allowedActionsButtons && allowedActionsButtons.length > 0) {
        columns.push({
            title: 'buttons',
            key: 'buttons',
            width: 48 * allowedActionsButtons.length,
            align: "right",
            render: (_, record) => {
                const actions: React.ReactNode[] = [];
                allowedActionsButtons.forEach((action) => {
                    if (!(action.forceHidden && action.forceHidden(record)) //Не показывать кнопку, если она скрыта согласно кастомной логике
                        && (!action.specificSourceType|| (action.specificSourceType && record.sourceType && action.specificSourceType.includes(record.sourceType)))) {
                        actions.push(<Tooltip title={action.tooltipText} key={action.key} arrowPointAtCenter placement="topRight">
                            <Button icon={action.icon} className="item-action" danger={action.danger} size="small" onClick={(event) =>{ event.stopPropagation(); action.onClick(record) }} />
                        </Tooltip>);
                    }
                });
                return (<Space size="middle">
                    {actions}
                </Space>);
            },
        });
    }

    const tableProps: TableProps<ICatalogedTreeItem> = {
        bordered: false,
        loading: isLoading,
        size: "large",
        showHeader: false,
        rowKey: (record) => record.parentItem ? `${record.id}-${record.parentItem.id}` : record.id,
        components: {
            body: {
                row: ItemListDraggableRow
            },
        },
        onRow: (data) => ({
            onDoubleClick: () => itemClickEvent(data),
            itemProp: JSON.stringify({ id: data?.id, sourceType: data?.sourceType, isRoot: data?.isRoot }),
        }),
    };

    return (<>
        <Table
            className="h-[calc(100%-128px)] xl:h-[calc(100%-96px)] overflow-auto items-table"
            {...tableProps}
            pagination={false}
            columns={columns}
            dataSource={sortedListItems || []}
        />
    </>
    )
});