export const rolesMap = new Map([
    ["report-user", "Пользователь конструктора отчётов"],
    ["analysis-user", "Пользователь аналитики"],
    ["etl-user", "Пользователь интеграции данных"],
    ["report-writer", "Пользователь конструктора отчётов (изменение)"],
    ["analysis-writer", "Пользователь аналитики (изменение)"],
    ["etl-writer", "Пользователь интеграции данных (изменение)"],
    ["admin", "Administrator"],
    ["security-admin", "SECURITYADMINISTRATOR"],
    ["data-admin", "DATAADMINISTRATOR"],
]);