import { memo, useEffect } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { IPublishedLink } from "../PublishWizardForm"
import { StepButtons } from "../controls/StepButtons"
import { StepTitle } from "../controls/StepTitle"
import { useGetTemplateParametersQuery } from "app/store/colibri/reporting.api"
import { ReportTemplateParameter } from "../ReportTemplateParameter"
import Table, { ColumnType } from "antd/lib/table"

export const ParametersStep = memo(() => {
    const { control, setValue, getValues } = useFormContext<IPublishedLink>()    
    const objectId = useWatch({ control, name: 'objectId' })
    const id = useWatch({ control, name: 'id' })

    const { data: parameters, isFetching } = useGetTemplateParametersQuery(objectId, { refetchOnMountOrArgChange: true })

    const columns: ColumnType<ReportTemplateParameter>[] = [{
        title: 'Описание параметра',
        dataIndex: 'caption',
    }, 
    // {
    //     title: 'Значение',
    //     dataIndex: 'value',
    // }
]

    const parameterStates = useWatch({ control, name: 'parameterStates' })

    useEffect(() => {
        if (!parameters)
            return        

        const { parameterStates = {}, parameters: existingParameters = {}} = getValues()
        parameters.forEach(p => {
            if(!(p.name in parameterStates))
                setValue(`parameterStates.${p.name}`, true)
            //if(!(p.name in existingParameters))
            let v = p.value;
            if(p.dateRangeSettings)
                v = [p.value.Start, p.value.End]
            
            if(p.selectAllValues)
                v = "__selectAllValues"
            setValue(`parameters.${p.name}`, v)
        })
    }, [parameters, setValue, getValues])

    return <>
        <StepTitle>Выберите параметры для публикации</StepTitle>
        <Table<ReportTemplateParameter>
            rowKey={r => r.name}
            dataSource={parameters}
            loading={isFetching}
            columns={columns}
            pagination={false}
            style={{ maxHeight: 800 }}
            rowSelection={{
                selectedRowKeys: Object.entries(parameterStates || {}).filter(([name, selected]) => selected).map(([name]) => name),
                onChange(selectedRowKeys) {
                    setValue('parameterStates', Object.fromEntries(parameters!.map(p => [p.name, selectedRowKeys.includes(p.name)])))
                },
            }}
        />
        <StepButtons />
    </>
})