import Spin from "antd/lib/spin";
import React from "react";

type ComponentProps = {
  isVisible: boolean;
  body: React.ReactNode;
};
export const LoadingSpinnerOverlayComponent: React.FC<ComponentProps> = React.memo(({isVisible, body}) => {

  return (
    <Spin spinning={isVisible} className="h-full" size="large">
      { body }
    </Spin>
  )
});