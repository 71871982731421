import { showError } from 'helpers/messageHelpers';
import {  ICatalogedTreeItem, IFolder } from '../../models/treeModels';
import { useUpdateFolderMutation, useUpdateTemplateMutation } from "../store/colibri/reporting.api";

type ItemProps = Pick<ICatalogedTreeItem, "id"> & Pick<ICatalogedTreeItem, "sourceType"> & Pick<ICatalogedTreeItem, "isRoot">;

export const useMoveTreeItem = (): (sourceItem: ItemProps, targetItem: ItemProps) => Promise<void> => {
    const [updateFolder] = useUpdateFolderMutation();
    const [updateTemplate] = useUpdateTemplateMutation();

    return async (sourceItem: ItemProps, targetItem: ItemProps) => {
        if (sourceItem && sourceItem.sourceType === "reporting.folder") {
            await updateFolder({
                id: sourceItem.id,
                pid: targetItem.isRoot ? null : targetItem.id,
            });
        } else if (sourceItem && sourceItem.sourceType === "reporting.template") {
            await updateTemplate({
                id: sourceItem.id,
                folders: targetItem.isRoot ? [] : [{
                    id: targetItem.id,
                } as IFolder],
            });
        } else {
            showError();
        }
    };
};