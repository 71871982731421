import React, { useState } from "react";
import { useActions, useAppSelector } from "app/hooks/hooks";
import Modal from "antd/lib/modal";
import Space from "antd/lib/space";
import Button from "antd/lib/button";
import { selectImportResourceOptions } from "./importResourceSlice";
import { ColibriSourceItemType } from "models/treeModels";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons/lib/icons";
import { UploadFile, UploadProps, RcFile } from "antd/lib/upload/interface";
import { showError, showSuccess } from "helpers/messageHelpers";
import { IDashboardImportResult, IResourceImportResult, isDashboardImportResult } from "models/importModels";
import Alert from "antd/lib/alert";
import Typography from "antd/lib/typography";
import { useImportDashboard } from "./useImportDashboard";
import { useImportWidget } from "./useImportWidget";
import { useImportTemplate } from "./useImportTemplate";
import { useImportProcess } from "./useImportProcess";

const { Text } = Typography;

export const ImportResourceControl: React.FC = React.memo(() => {
    const { isLoading: isImportDashboardProcessing, importDashboard } = useImportDashboard();
    const { isLoading: isImportWidgetProcessing, importWidget } = useImportWidget();
    const { isLoading: isImportTemplateProcessing, importTemplate } = useImportTemplate();
    const { isLoading: isImportProcessProcessing, importProcess } = useImportProcess();

    const isImportProcessing = isImportDashboardProcessing ||
        isImportWidgetProcessing || isImportTemplateProcessing || isImportProcessProcessing;
    
    const importResourceOptions = useAppSelector(selectImportResourceOptions);
    const { hideImportResourceWindow } = useActions();
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const [detailedError, setDetailedError] = useState<React.ReactNode>(null);

    const startImport = async (): Promise<void> => {
        if (fileList?.length && importResourceOptions?.resourceType) {
            const file = fileList[0] as RcFile;
            const resourceType = importResourceOptions.resourceType;
            if (resourceType === "analysis.dashboard") { // Импорт дэшборда
                const result = await importDashboard(file);
                if (result?.success) {
                    showSuccess("Новый дэшборд успешно загружен!");
                    hideImportResourceWindow();
                } else if (result?.success === false && result?.errcode) {
                    setFileList([]);
                    setDetailedError(getDetailedErrorContent(result));
                }
            } else if (resourceType === "analysis.widget") { // Импорт виджета
                const isImportSuccess = await importWidget(file);
                if (isImportSuccess) {
                    showSuccess("Новый виджет успешно загружен!");
                    hideImportResourceWindow();
                }
            } else if (resourceType === "reporting.template") { // Импорт шаблона
                const isImportSuccess = await importTemplate(file, importResourceOptions.folderId || null);
                if (isImportSuccess) {
                    showSuccess("Новый шаблон успешно загружен!");
                    hideImportResourceWindow();
                }
            } else if (resourceType === "workflow.process") { // Импорт ETL процесса
                const isImportSuccess = await importProcess(file);
                if (isImportSuccess) {
                    showSuccess("Новый процесс успешно загружен!");
                    hideImportResourceWindow();
                }
            } else {
                showError();
            }
        }
    };
    const onModalClosed = (): void => {
        setFileList([]);
        setDetailedError(null);
    };

    const onDetailedErrorClosed = (): void => {
        setDetailedError(null);
    };

    const uploaderProps: UploadProps = {
        name: "file",
        multiple: false,
        maxCount: 1,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
    };

    return (<Modal
        open={!!importResourceOptions}
        width={520}
        title={`Загрузка нового ${getResourceName(importResourceOptions?.resourceType!)} из файла`}
        footer={[<Space key='controls'>
            <Button
                disabled={!fileList?.length}
                onClick={() => startImport()} type="primary"
                loading={isImportProcessing}
            >
                Загрузить
            </Button>
            <Button onClick={hideImportResourceWindow} type='default'>Закрыть</Button>
        </Space>]}
        closable
        destroyOnClose
        afterClose={onModalClosed}
        onCancel={hideImportResourceWindow}
        bodyStyle={{ display: 'flex', flexDirection: 'column', minHeight: 280, height: "auto", maxHeight: 460, overflowY: "auto" }}
    >
        {detailedError
            ? (<Alert
                className="mt-2"
                message={"Процесс импорта не завершён"}
                description={detailedError}
                type="error"
                closable
                afterClose={onDetailedErrorClosed}
            />)
            : (<Dragger {...uploaderProps} className="mt-2">
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Нажмите или перенести файл в эту область для загрузки</p>
                <p className="ant-upload-hint">
                    {`Приложите ранее выгруженный файл и нажмите кнопку "Загрузить" для импорта ${getResourceName(importResourceOptions?.resourceType!)}`}
                </p>
            </Dragger>)
        }
    </Modal>);
});

const getResourceName = (resourceType: ColibriSourceItemType): string => {
    if (resourceType === "analysis.dashboard") {
        return "Дэшборда";
    } else if (resourceType === "analysis.widget") {
        return "Виджета";
    } else if (resourceType === "reporting.template") {
        return "Шаблона";
    } else if (resourceType === "workflow.process") {
        return "Процесса";
    } else {
        return "";
    }
};

const getDetailedErrorContent = (result: IResourceImportResult | IDashboardImportResult): React.ReactNode => {
    if (result.success) {
        return null;
    }
    if (isDashboardImportResult(result) && (result.errcode === "AN014" || result.errcode === "AN012")) {
        const missingWidgets = result.widgets.filter((w) => !w.conflict);
        const nameConflictedWidgets = result.widgets.filter((w) => w.name === w.conflict);
        return (<>
            {missingWidgets.length > 0 && <div className="mt-2">
                <Text>Невозможно импортировать дэшборд <b>{result.dashboard.name}</b>, так как отсутствуют необходимые для его работы виджеты:</Text>
                <ul className="mt-2">
                    {missingWidgets.map((w) => <li key={w.id}> {w.name} </li>)}
                </ul>
                <Text className="mt-2">Пожалуйста, загрузите недостающие виджеты и повторите попытку.</Text>
            </div>}
            {nameConflictedWidgets.length > 0 && <div className="mt-2">
                <Text>Невозможно импортировать дэшборд <b>{result.dashboard.name}</b>, так как существуют другие виджеты с таким же именем, как у зависимого виджета, загружаемого дэшборда:</Text>
                <ul className="mt-2">
                    {nameConflictedWidgets.map((w) => <li key={w.id}> {w.name} </li>)}
                </ul>
                <Text className="mt-2">Пожалуйста, загрузите недостающие виджеты и повторите попытку.</Text>
            </div>}
        </>)
    } else {
        return <Text>{result.error || "Произошла непредвиденная ошибка! Пожалуйста, попробуйте ещё раз или обратитесь к администратору..."}</Text>
    }
};